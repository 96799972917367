import { createSelector } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { AgreementsState } from '../state/agreements.state';
import { UploadStatus } from '../../core/models';

const PROCESSING_STATES = [
  'summizing: queued',
  'summizing: started',
  'summizing: locating parties',
  'summizing: preparing document',
  'summizing: reading document',
  'summizing: extracting clause information',
  'summizing: party confirmation received',
  'summizing: compressing document',
  'summizing: image enhancement',
  'summizing: parties identified'
];

const agreements = (state: AppState) => state.agreements;

export const selectPendingAgreements = createSelector(agreements, (state: AgreementsState) => (state ? state.pendingAgreements : []));

export const selectTableIsShared = createSelector(agreements, (state: AgreementsState) => (state ? state.tableIsShared : false));

export const selectPendingAgreementsReadyToBeConfirmed = createSelector(agreements, (state: AgreementsState) => {
  if (!state || !state.pendingAgreements) {
    return [];
  }
  return state.pendingAgreements.filter((a) => a.status === 'Confirm Parties');
});

export const selectAllProcessingAgreements = createSelector(agreements, (state: AgreementsState) => {

  if (state == undefined
    || state.pendingAgreements === undefined
    || state.pendingAgreements.length === 0) {

    return [];

  }

  return state.pendingAgreements.filter((a) => a.status !== 'Confirm Parties'
    && a.status.toString() !== '13'
  );

});

export const selectPendingAgreementsBeingProcessed = createSelector(agreements, (state: AgreementsState) => {
  if (!state || !state.pendingAgreements) {
    return [];
  }
  return state.pendingAgreements.filter((a) => PROCESSING_STATES.includes(a.status.toString().toLowerCase()));
});

export const selectFailedPendingAgreements = createSelector(agreements, (state: AgreementsState) => {
  if (!state || !state.pendingAgreements) {
    return [];
  }
  return state.pendingAgreements.filter((a) => a.error.toLowerCase() !== 'ok');
});

export const selectAllUploadFiles = createSelector(agreements, (state: AgreementsState) => {
  return state.uploadingAgreements;
});

export const selectCurrentUploadingFiles = createSelector(agreements, (state: AgreementsState) => {
  return state.uploadingAgreements.filter((f) => f.status === UploadStatus.PENDING || f.status === UploadStatus.UPLOADING);
});

export const selectFailedUploadingFiles = createSelector(agreements, (state: AgreementsState) => {
  return state.uploadingAgreements.filter((f) => f.status === UploadStatus.ERROR);
});

export const selectClauseTypes = createSelector(agreements, (state: AgreementsState) => state.clauseTypes);
export const selectCustomClauseTypes = createSelector(agreements, (state: AgreementsState) => state.clauseTypes.filter((c) => !c.standard));

export const selectNewTab = createSelector(agreements, (state: AgreementsState) => state.newTab);
export const selectTemplateTypes = createSelector(agreements, (state: AgreementsState) => state.newTab.templateTypes);
export const selectTotalAgreements = createSelector(agreements, (state: AgreementsState) => state.totalAgreements);
export const selectTotalFilteredCount = createSelector(agreements, (state: AgreementsState) => state.filteredCount);
export const selectAgreements = createSelector(agreements, (state: AgreementsState) => state.agreements);

export const setSearchTerm = createSelector(agreements, (state: AgreementsState) => state.searchTerm);
export const selectNewComment = createSelector(agreements, (state: AgreementsState) => state.newComment);

export const selectedDocumentAgreements = createSelector(agreements, (state: AgreementsState) => state.selectedDocumentIds);

export const selectFilter = createSelector(agreements, (state: AgreementsState) => state.filter);

export const selectFilterEnabled = createSelector(agreements, (state: AgreementsState) => state.filterEnabled);
export const selectCurrentPage = createSelector(agreements, (state: AgreementsState) => state.currentPage);

export const selectTableShare = createSelector(agreements, (state: AgreementsState) => state.shareInformation);
