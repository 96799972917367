import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
    BlockButtonModule,
    CalendarCardModule,
    AutocompleteChipsGroupsModule,
    AutocompleteChipsModule,
    LoadingSpinnerModule,
    CorePipesModule,
    CalendarEventNotificationTargetsModule
} from '@summize/shared/components-v2';

import { CalendarV2Component } from './calendar-v2.component';
import { CalendarV2Service } from './calendar-v2.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        BlockButtonModule,
        CalendarCardModule,
        AutocompleteChipsModule,
        AutocompleteChipsGroupsModule,
        LoadingSpinnerModule,
        CorePipesModule,
        MatTooltipModule,
        CalendarEventNotificationTargetsModule
    ],
    exports: [
        CalendarV2Component
    ],
    declarations: [
        CalendarV2Component
    ],
    providers: [
        CalendarV2Service
    ]
})
export class CalendarV2Module { }

export * from './calendar-v2.component';

export * from './calendar-v2.service';

export * from './components';
