<a href="/"><img class="logo"
  src="assets/images/logos/summize-logo.svg" 
  alt="Summize" 
  [class.logo--large]="large"
  (click)="onTabSelected(allDocumentType)"  />
</a>





