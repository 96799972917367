import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';

import { AppState } from '../../store/state/app.state';
import { CalendarEvent, ClauseEvent } from '../models/contractCalendar.model';
import { UserService } from './user.service';
import { LoadCalendarEvents, SetClauseEvents } from '../../store/actions/contract-calendar.actions';
import { ToastService } from './toast.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private http: HttpClient,
    private userService: UserService,
    private store: Store<AppState>,
    private toastService: ToastService) {
  }
  private static DATE_FORMAT = 'yyyy-MM-dd';

  private static TERMINATION = 1;
  private static EVENT = 2;
  private version = '1.0';

  // TODO - Remove once global calendar is refactored
  public updateCalendarEventStoreForDatePicker(events: any) {
    this.store.dispatch(new LoadCalendarEvents(events));
  }

  public async loadEventsForMonth(clientId: string, matterId: string, date: Date) {
    if (!date || clientId === '') {
      return;
    }

    const from = new Date(date.getFullYear(), date.getMonth(), 1);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return this.loadEvents(clientId, matterId, from, to);
  }

  public async loadTenantEventsForMonth(date: Date) {
    if (!date) {
      return;
    }

    const from = new Date(date.getFullYear(), date.getMonth(), 1);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return this.loadEventsForTenant(from, to);
  }

  public async loadEvents(clientId: string, matterId: string, from: Date, to: Date) {
    const baseUrl = await this.getBaseUrl();
    const fromQuery = formatDate(from, CalendarService.DATE_FORMAT, 'en');
    const toQuery = formatDate(to, CalendarService.DATE_FORMAT, 'en');

    if (clientId === '' || matterId === '') {
      return;
    }

    const events = await this.http.get<CalendarEvent[]>(`${baseUrl}/clients/${clientId}/Matters/${matterId}/Documents/CalendarEvents?startDate=${fromQuery}&endDate=${toQuery}`).toPromise();
    events.sort((a, b) => {
      return <any>new Date(a.eventDate) - <any>new Date(b.eventDate);
    });
    this.store.dispatch(new LoadCalendarEvents(events));
  }

  public async loadEventsForTenant(from: Date, to: Date) {

    const baseUrl = await this.getBaseUrl();
    const fromQuery = formatDate(from, CalendarService.DATE_FORMAT, 'en');
    const toQuery = formatDate(to, CalendarService.DATE_FORMAT, 'en');


    const events = await this.http.get<CalendarEvent[]>(`${baseUrl}/CalendarEvents?startDate=${fromQuery}&endDate=${toQuery}`).toPromise();

    events.sort((a, b) => {
      return <any>new Date(a.eventDate) - <any>new Date(b.eventDate);
    });

    this.store.dispatch(new LoadCalendarEvents(events));

    return events;
  }

  public async loadLinkToClauses(tenantTemplateId: string) {
    if (!tenantTemplateId) {
      return;
    }

    const baseUrl = await this.getBaseUrl();
    const clauseEvents = await this.http.get<ClauseEvent[]>(`${baseUrl}/documentTypes/${tenantTemplateId}/clauses`).toPromise();
    this.store.dispatch(new SetClauseEvents(clauseEvents));
  }

  public async createEvent(documentId: string, clientId: string, matterId: string, clauseId: number, date: Date) {
    const formattedDate = formatDate(date, CalendarService.DATE_FORMAT, 'en');

    const payload = {
      DocumentId: documentId,
      Event: {
        EventTypeId: CalendarService.EVENT,
        EventDate: formattedDate,
        ClauseId: clauseId,
        NoticePeriod: 0
      }
    };

    this.postEvent(clientId, matterId, documentId, payload, 'Event');
  }

  public async createTermination(documentId: string, clientId: string, matterId: string, noticePeriod: number, date: Date, clauseId: number) {
    const formattedDate = formatDate(date, CalendarService.DATE_FORMAT, 'en');
    const payload = {
      DocumentId: documentId,
      Event: {
        EventTypeId: CalendarService.TERMINATION,
        EventDate: formattedDate,
        ClauseId: clauseId,
        NoticePeriod: noticePeriod
      }
    };

    this.postEvent(clientId, matterId, documentId, payload, 'Termination');
  }

  private async postEvent(clientId: string, matterId: string, documentId: string, payload: { DocumentId: string; Event: { EventTypeId: number; EventDate: string; ClauseId: number; NoticePeriod: number; }; }, eventText: string) {
    const baseUrl = await this.getBaseUrl();

    this.http.post(`${baseUrl.href}/clients/${clientId}/matters/${matterId}/documents/${documentId}/calendarEvents`, payload.Event).toPromise()
      .then(() => this.toastService.show(`${eventText} added to calendar.`, 'icon-tick'))
      .catch(() => this.toastService.show(`Failed to add ${eventText.toLowerCase()}.`, 'icon-close'));
  }

  public async deleteEvent(clientId: string, matterId: string, documentId: string, eventId: string): Promise<any> {
    const baseUrl = await this.getBaseUrl();
    try {
      await this.http.delete(`${baseUrl}/clients/${clientId}/Matters/${matterId}/Documents/${documentId}/CalendarEvents/${eventId}`).toPromise();
      this.toastService.show('Event has been deleted.', 'icon-tick');
      await this.loadEventsForMonth(clientId, matterId, this.getCurrentSelectedDate());
    } catch (err) {
      this.toastService.show('There was an error deleting the calendar event.', 'icon-cross');
    }
  }

  public async downloadCalendarEvent(id: string): Promise<any> {

    const baseUrl = await this.getBaseUrl();

    const options = { responseType: 'blob' as 'json' };

    return this.http.get(`${baseUrl.href}/CalendarEvents/${id}?format=ics`, options).toPromise();

  }

  private getCurrentSelectedDate(): Date {
    let date: Date;
    const sub = this.store.pipe(take(1)).subscribe(s => date = s.contractCalendar.selectedDate);
    sub.unsubscribe();
    return date;
  }

  private async getBaseUrl(): Promise<URL> {
    const baseUrl = this.userService.getUserBaseUrl();
    const url = new URL(this.version, baseUrl);
    return url;
  }
}
