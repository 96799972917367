import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { StoreComponent } from '../../../../core/util/store-component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { eventDialogVisible, selectClauseEvents } from '../../../../store/selectors/contract-calendar.selectors';
import { CalendarService } from '../../../../core/services/calendar.service';
import { selectDocumentId, selectTenantTemplateId } from '../../../../store/selectors/contract-review.selectors';
import { IsEventDialogVisible } from '../../../../store/actions/contract-calendar.actions';
import { ClauseEvent } from '../../../../core/models/contractCalendar.model';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'cc-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.scss']
})
export class EventDialogComponent extends StoreComponent implements OnInit {

  visible = false;
  date: Date;
  documentId: string;
  selectedClauseEvent: ClauseEvent;
  clauseEvents: ClauseEvent[];
  matterId: string;
  clientId: string;
  constructor(protected store: Store<AppState>,
              private calendarService: CalendarService, private adapter: DateAdapter<any>,  ) {
    super(store);
  }



  ngOnInit() {
    super.ngOnInit();
    this.select(eventDialogVisible, visible => this.visible = visible);
    this.select(selectDocumentId, documentId => this.documentId = documentId);
    this.select(selectClauseEvents, clauseEvents => this.clauseEvents = clauseEvents);
    this.select(selectTenantTemplateId, tenantTemplateId => this.calendarService.loadLinkToClauses(tenantTemplateId));
  }

  close() {
    this.reset();
    this.store.dispatch(new IsEventDialogVisible(false));
  }

  onDateSelected(event) {
    this.date = event.value;
  }

  add() {


    if (!this.documentId || !this.selectedClauseEvent || !this.date) {
      console.error('documentId, clause to link to and date are all needed', this.documentId, this.selectedClauseEvent, this.date);
      return;
    }

    this.calendarService.createEvent(this.documentId, this.clientId, this.matterId, this.selectedClauseEvent.clauseId, this.date);
    this.close();
  }

  private reset() {
    this.date = null;
    this.selectedClauseEvent = null;
  }
}
