import { Directive, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[appIfCanViewMatters]'
})
export class IfCanViewMattersDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) { }

  ngOnInit() {
    const matterId = this.userService.getMatterId();

    if (!matterId) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}