import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';
import { RequestSubtype } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class ContractRedirectService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async get(contractId: string): Promise<any> {

        const url = this.getUserBaseUrl(true);

        const result = await this.http.get(`${url}review/meta/${contractId}?latest=true`).toPromise() as any;

        return this.getDocumentPage(result.clientId, result.matterId, result.id, RequestSubtype[result.requestSubType].toLowerCase());

    }


}