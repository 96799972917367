import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import lottie from 'lottie-web/build/player/lottie_svg.min.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('loadingSpinner') loadingSpinner: ElementRef;

  constructor(
    public router: Router,
  ) { }

  public async ngOnInit() {
    return this.router.navigateByUrl('/login-oidc');
  }

  ngAfterViewInit() {
    lottie.loadAnimation({
      container: this.loadingSpinner.nativeElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'assets/animations/summize-spinner.json'
    });
  }
}
