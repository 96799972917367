import { Store } from '@ngrx/store';

import { AppState } from '../../store/state/app.state';
import { take } from 'rxjs/operators';

type FunctionName = (id: any) => any;

export const updateObjectInArray = <T>(arr: Array<T>, predicate: FunctionName, partialObject: any): Array<T> => {
    const itemToUpdate = arr.find(predicate);
    if (!itemToUpdate) {
        console.error('Cannot find any object to update');
        return arr;
    }

    const index = arr.indexOf(itemToUpdate);
    const itemToReplace = {
        ...itemToUpdate,
        partialObject
    };

    return [
        ...arr.slice(0, index),
        itemToReplace,
        ...arr.slice(index + 1)
    ];
};

export const updateArray = <T>(arr: Array<T>, predicate: FunctionName, object: T): T[] => {
    const itemToRemove = arr.find(predicate);
    if (!itemToRemove) {
        return arr.concat([object]);
    }

    const index = arr.indexOf(itemToRemove);

    return [
        ...arr.slice(0, index),
        object,
        ...arr.slice(index + 1)
    ];
};

type StateFunction = (state: AppState) => any;
export const syncStoreGet = (store: Store, stateFunc: StateFunction): any => {
    let retVal;
    const sub = store.pipe(take(1)).subscribe((s: any) => retVal = stateFunc(s));
    sub.unsubscribe();
    return retVal;
};

export const getClientMatterIds = (store: Store, validate: boolean = false) => {

    return { clientId: undefined, matterId: undefined };
};
