import { Injectable } from '@angular/core';

import { BaseTenantService, TenantSettingService, TenantSettingTypes } from '@summize/shared/core';
import { SlidePanelService, SlidePanelSettings } from '@summize/shared/components-v2';

import { CalendarSyncComponent } from './calendar-sync.component';
import { CalendarSyncService } from './calendar-sync.service';

@Injectable({ providedIn: 'root' })
export class CalendarSyncHelper {

    public arguments: any = {
        hasApiManagement: false
    };

    constructor(
        private service: CalendarSyncService,
        private tenantSettingService: TenantSettingService,
        private slidePanel: SlidePanelService
    ) { }

    public async launch() {

        const settings = this.getBaseSettings();

        const tenantSettingHasApiManagement = await this.tenantSettingService.GetSetting(TenantSettingTypes.HasApiManagement);

        this.arguments.hasApiManagement = tenantSettingHasApiManagement?.settingValue === 'true' || BaseTenantService.environment.production !== true;

        if (this.arguments.hasApiManagement) {

            const calendarSyncSettings = await this.service.getCalendarSyncSettings();
            this.arguments.calendarSyncSettings = calendarSyncSettings;
            this.arguments.webcalUrl = calendarSyncSettings.webcalUrl;
            this.arguments.httpsUrl = calendarSyncSettings.httpsUrl;

        }

        Object.assign(settings, {
            arguments: this.arguments,
            commands: [{
                text: 'Save',
                isSaveCommand: true,
                closeAfterClick: true,
                onClick: async (component: CalendarSyncComponent) => {

                    await this.service.saveCalendarSyncSettings(component.calendarSyncSettings);

                },
                type: 'v2-primary'
            },
            {
                text: 'Cancel',
                isSaveCommand: false,
                closeAfterClick: true,
                type: 'v2-blue'
            }]
        });

        if (this.arguments.hasApiManagement !== true) {

            // Remove the 'Save' button.
            settings.commands?.shift();

        }

        this.slidePanel.show(settings);
    }

    private getBaseSettings(): SlidePanelSettings {
        return {
            component: CalendarSyncComponent,
            title: 'Calendar Sync',
            closeable: true,
            size: 'xxl',
            backdrop: true,
            managed: true
        };
    }

}