<div class="container">

  <p *appClaimsShow="[Claims.Calendar.CanCalendarEventsRead]" class="mb-40">
    Calendar sync allows you to add Summize calendar events and task due dates to your Outlook, iCloud, Google or other
    calendar apps.
  </p>
  <p *appClaimsHide="[Claims.Calendar.CanCalendarEventsRead]" class="mb-40">
    Calendar sync allows you to add Summize task due dates to your Outlook, iCloud, Google or other calendar apps.
  </p>

  <ng-container *ngIf="!hasApiManagement">
    <div class="enable-apimgmt">
      Please contact Customer Success at <a
        href="mailto:support@summize.com?subject=Enable Calendar Sync">support&#64;summize.com</a> to help enable
      Calendar Sync.
    </div>
  </ng-container>

  <ng-container *ngIf="hasApiManagement">
    <h3>Sync settings</h3>
    <p class="mb-24">Use the settings below to control what to sync with your calendar, then use the calendar setup URLs
      to activate the sync with your preferred calendar app.
    </p>

    <ng-container *appClaimsShow="[Claims.Calendar.CanCalendarEventsRead]">
      <h4>Calendar events</h4>
      <div class="mb-24">
        <mat-radio-group [(ngModel)]="calendarSyncSettings.calendarEvents" class="radio-group" required>
          <mat-radio-button [value]="1" class="radio-button">All calendar events for contracts visible to
            me</mat-radio-button>
          <mat-radio-button [value]="2" class="radio-button">Only calendar events for contracts where I am the
            owner</mat-radio-button>
          <mat-radio-button [value]="0" class="radio-button">Don't sync calendar events</mat-radio-button>
        </mat-radio-group>
      </div>
    </ng-container>

    <h4>Tasks</h4>
    <div class="mb-40">
      <mat-radio-group [(ngModel)]="calendarSyncSettings.tasks" class="radio-group" required>
        <mat-radio-button [value]="1" class="radio-button">All pending tasks assigned to me</mat-radio-button>
        <mat-radio-button [value]="0" class="radio-button">Don't sync tasks</mat-radio-button>
      </mat-radio-group>
    </div>

    <h3>URL for Outlook, iCloud or other ICS supported calendars</h3>
    <p class="mb-16">To sync with your default OS iCal app <a [href]="webcalUrl">click here</a>. Or copy and paste the
      URL
      below into your preferred calendar
      program to setup your sync.</p>

    <div class="url mb-40">
      <input readonly class="smz-standard-input url-input" [(ngModel)]="webcalUrl" placeholder="" />
      <app-icon (click)="copyClipboard(webcalUrl)" icon="copy-outline" class="url-action clickable"></app-icon>
    </div>

    <h3>URL for Google Calendar</h3>
    <p class="mb-16">Copy and paste this URL into your Google Calendar to setup your sync.</p>

    <div class="url">
      <input readonly class="smz-standard-input url-input" [(ngModel)]="httpsUrl" placeholder="" />
      <app-icon (click)="copyClipboard(httpsUrl)" icon="copy-outline" class="url-action clickable"></app-icon>
    </div>
  </ng-container>

</div>