export interface AgreementResponse {
  totalCount: number;
  results: Array<any>;
}

export enum Panel {
  Intro = 0,
  ExistingDocumentType = 1,
  NewDocumentType = 2,
  ClausePanel = 3,
  Done,
}

export interface AgreementsFilter {
  type: string;
  value: any;
  label: string;
}

export interface SearchQuery {
  type: 'fullSearch';
  query: string;
}
