import { Component, Input, OnInit } from '@angular/core';
import { Template } from '../../../core';
import { Store } from '@ngrx/store';
import { StoreComponent } from '../../../core/util/store-component';
import { AppState } from '../../../store/state/app.state';
import { SetSearchTerm } from '../../../store/actions/agreements.actions';
import { SetSelectedDocumentType } from '../../../store/actions/document-type.actions';

@Component({
  selector: 'app-summize-logo',
  templateUrl: './summize-logo.component.html',
  styleUrls: ['./summize-logo.component.scss'],
  host: {
    '[class.className]' : 'className',
  }
})
export class SummizeLogoComponent extends StoreComponent implements OnInit {
  @Input('large') large: boolean;
  @Input('light') light: boolean;
  @Input('dark') dark: boolean;
  activeTab: string;
  allDocumentType: Template = {
    id: '0',
    name: 'All',
    description: 'All'
  };

  constructor(protected store: Store<AppState>) {
    super(store);
  }

  ngOnInit() {
    this.large = this.large !== undefined;
  }

  onTabSelected(tab: Template) {
    this.store.dispatch(new SetSearchTerm(null));
    this.store.dispatch(new SetSelectedDocumentType(tab));
  }
}
