import { PendingAgreement, UploadFile, NewTab, ClauseType, AgreementsFilter, SearchQuery } from '../../core';

export interface AgreementsState {
  pendingAgreements: PendingAgreement[];
  uploadingAgreements: UploadFile[];
  newTab: NewTab;
  clauseTypes: Array<ClauseType>;
  agreements: any[];
  totalAgreements: number;
  filteredCount: number;
  searchTerm: SearchQuery;
  newComment: string;
  selectedDocumentIds: string[];
  filter: AgreementsFilter;
  filterEnabled: boolean;
  currentPage: number;
  pageSize: number;
  tableIsShared: boolean;
  shareInformation: any;
}

export const initialAgreementsState: AgreementsState = {
  pendingAgreements: [],
  uploadingAgreements: [],
  clauseTypes: [],
  newTab: {
    tabName: '',
    templateTypes: [],
  },
  agreements: [],
  totalAgreements: 0,
  filteredCount: 0,
  searchTerm: null,
  newComment: '',
  selectedDocumentIds: [],
  filter: undefined,
  filterEnabled: false,
  currentPage: 0,
  pageSize: undefined,
  tableIsShared: false,
  shareInformation: null,
};
