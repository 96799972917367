import { NgModuleRef } from "@angular/core";

import { GmailService } from "@summize/shared/components-v2";
import { IsValidString } from "@summize/shared/core";

import { AppModule } from "../app.module";

export class GmailBridge {

    constructor(app: NgModuleRef<AppModule>, onCompleteCallback: () => void) {

        const queryString = window.location.search;

        const params = new URLSearchParams(queryString);

        const attachments = params.get('attachments');

        if (IsValidString(attachments) === true) {

            const gmail = app.injector.get(GmailService);

            if (gmail !== undefined) {

                gmail.storeAttachmentFromBridge(JSON.parse(atob(attachments)));

            }

        }

        onCompleteCallback();

    }

}