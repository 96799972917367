<div class="container">
    <div class="region-selector">
        <div class="primary">
            <img src="assets/images/summize-logo.svg" />
            <div class="sign-in">
                Sign In
            </div>
        </div>
        <ng-container *ngIf="state === SelectorState.Initial">
            <div>
                <div class="form-label required">Email</div>
                <input [(ngModel)]="email" class="smz-standard-input" type="email" placeholder="user@company.com" autofocus/>
            </div>
            <div class="button-container">
                <app-block-button [disabled]="email === '' || email === undefined" type="block"
                width="280" display="Continue" (click)="getRegions()"></app-block-button>
            </div>
        </ng-container>
        <ng-container *ngIf="state === SelectorState.Searching">
            <div class="spinner">
                <app-loading-spinner visible="true"></app-loading-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="state === SelectorState.NoMatches">
            <div class="primary">
                We are unable to match that email address to any regions. If you think this is wrong, Please contact your
                Summize
                administrator or try again.
            </div>
            <div>
                <button (click)="state = SelectorState.Initial" class="button button__regular">Try again</button>
            </div>
        </ng-container>
        <ng-container *ngIf="state === SelectorState.Selection">
            <div class="primary">
                There are multiple regions linked to this account. Please select one.
            </div>
            <div>
                <app-select-box (changed)="setRegion($event)" [options]="options" placeholder="Select Region ...">
                </app-select-box>
            </div>
        </ng-container>
        <ng-container *ngIf="state === SelectorState.Confirm">
            <div class="primary">
                Click continue to sign in as <strong>{{info.username}}</strong>
                <ng-container *ngIf="info.singleRegion === false">
                    in region <strong>{{info.region.name}}</strong>.
                </ng-container>
            </div>
            <div class="button-container">
                <app-block-button [disabled]="isContinue" type="block" width="280" display="Continue" (click)="continue()"></app-block-button>
            </div>

            <div *ngIf="(availableRegions === undefined || availableRegions?.length > 0) && info.singleRegion === false" (click)="clearRegion()"
                class="change-region clickable">
                Change region
            </div>

            <div *ngIf="info.singleRegion === true" (click)="clearRegion()" class="change-region clickable">
                Start again
            </div>
        </ng-container>
    </div>
</div>