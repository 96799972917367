import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {
  @Input() isFlagged: boolean;
  @Input() isVisible: boolean;
  @Input() isDisabled: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

}
