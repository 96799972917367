import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent, AdobeSignService, RequestStatusesWithSendForSignature, RequestStatusesWithoutSendForSignature, EMPTY_GUID } from '@summize/shared/core';
import { EventService, LocalStorageService } from '@summize/shared/framework';

@Component({
    template: ``
})
export class AdobeSignRedirectComponent extends AppComponent implements OnInit, OnDestroy {

    private authCode: string;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private events: EventService,
        private service: AdobeSignService,
        private storage: LocalStorageService) {
        super();
    }

    public ngOnInit() {

        this.subscribe(this.route.queryParams, async (args) => {
            
            this.authCode = args['code'];

            const state = args['state'];

            const error = args['error'];

            const errorDescription = args['error_description'];

            let documentId = EMPTY_GUID;

            try {

                const decoded = decodeURIComponent(state).replace(/&quot;/g, '"');

                if (decoded === undefined) {

                    await this.redirectFailedAttempt();

                    return;
                }

                const jsonState = JSON.parse(decoded);

                documentId = jsonState.documentId;

                const storedState = this.storage.getItem('as-redirect');

                if (storedState === undefined || storedState === null) {

                    await this.redirectFailedAttempt();

                    return;

                }

                const storedStateJson = JSON.parse(storedState);

                if (storedStateJson?.documentId !== documentId) {

                    await this.redirectFailedAttempt();

                    return;
                }

                if (this.authCode == undefined) {

                    this.events.actionFailed('Failed to get auth code from Adobe Sign');
                    
                    if (error !== undefined) {

                        console.error(`${error}: ${errorDescription}`)
                        
                    }

                    this.router.navigate(['contract', documentId]);

                    return;
                }

                const adobeSignAccount = await this.service.addAdobeSignPersonalAccount(this.authCode);

                if (adobeSignAccount !== undefined) {

                    const queryParams = {

                        action: 'sign',
                        signatureMethod: encodeURIComponent(`Adobe Sign: ${adobeSignAccount.name}`)

                    }

                    this.router.navigate(['contract', jsonState.documentId], { queryParams: queryParams });

                } else {

                    this.router.navigate(['contract', jsonState.documentId]);
                }

            } catch (err) {

                console.error(err);

                if (err.status === 409) {

                    this.events.actionFailed(err.error);

                    this.router.navigate(['contract', documentId]);

                }
            }

        });
    }

    private async redirectFailedAttempt() {

        const requestStatuses = this.hasFeatureFlag('SendForSignature') === true ? RequestStatusesWithSendForSignature : RequestStatusesWithoutSendForSignature;

        await this.router.navigate(['quick-summary', 'repository'], { queryParams: { status: requestStatuses } });

        this.events.actionFailed('Failed to authenticate with Adobe Sign. Invalid state.');

        this.storage.removeItem('as-redirect');

    }

}