import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagEnvironmentService {
  getFeatureFlags(): Array<string> {
    const flags = environment.featureFlags;
    const result = new Array<string>();
    for (const key of Object.keys(flags)) {
      if (flags[key]) {
        result.push(flags[key]);
      }
    }
    return result;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  constructor(private userService: UserService, private environmentService: FeatureFlagEnvironmentService) {
  }

  get(feature: string): boolean {
    const features = this.userService.getFeatureFlags();
    const envFeatures = this.environmentService.getFeatureFlags();

    if (features === undefined) {
      return false;
    }

    return features.indexOf(feature) > -1 || envFeatures.indexOf(feature) > -1;
  }
}
