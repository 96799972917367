import { Injectable } from '@angular/core';
import { SummizeStorage } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    private window = (typeof window !== 'undefined');

    getItem(key: string): any {
        if (this.window) {
            return SummizeStorage.getLocalItem(key);
        }
        return null;
    }

    setItem(key: string, val: string) {
        if (this.window) {
            SummizeStorage.setLocalItem(key, val);
        }
    }

    removeItem(key: string) {
        if (this.window) {
            SummizeStorage.removeLocalItem(key);
        }
    }

    clear() {
        if (this.window) {
            SummizeStorage.clearLocal();
        }
    }
}
