import { Component, ChangeDetectionStrategy } from '@angular/core';

import { AppComponent, ClipboardService } from '@summize/shared/core';

import {
    UserCalendarSyncCalendarEvents,
    UserCalendarSyncModel,
    UserCalendarSyncTasks
} from '@summize/shared/framework';

@Component({
    selector: 'app-calendar-sync',
    templateUrl: 'calendar-sync.html',
    styleUrls: ['./calendar-sync.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalendarSyncComponent extends AppComponent {

    public calendarSyncSettings: UserCalendarSyncModel = {
        calendarEvents: UserCalendarSyncCalendarEvents.None,
        tasks: UserCalendarSyncTasks.None,
        webcalUrl: '',
        httpsUrl: ''
    } as UserCalendarSyncModel;

    public hasApiManagement: boolean = false;
    public webcalUrl: string = '';
    public httpsUrl: string = '';

    constructor(private clipboardService: ClipboardService) {

        super();

    }

    public async copyClipboard(text: string) {

        this.clipboardService.copy(text);

    }

}
