import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingSpinnerModule } from '@summize/shared/components-v2';

import { RemoteProxyComponent } from './remote-proxy.component';
import { RemoteProxyService } from './remote-proxy.service';

@NgModule({
    imports: [
        CommonModule,
        LoadingSpinnerModule
    ],
    declarations: [RemoteProxyComponent],
    providers: [RemoteProxyService],
})
export class RemoteProxyModule { }
