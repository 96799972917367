import { initialContractCalendarState, ContractCalendarState } from '../state/contract-calendar.state';
import { ContractCalendarActions, EContractCalendarActions } from '../actions/contract-calendar.actions';

export function contractCalendarReducers(
  state: ContractCalendarState = initialContractCalendarState,
  action: ContractCalendarActions
): ContractCalendarState {
  switch (action.type) {
    case EContractCalendarActions.OpenCalendar: {
      return {
        ...state,
        isCalendarOpen: action.isOpen,
      };
    }
    case EContractCalendarActions.SelectedDate: {
      return {
        ...state,
        selectedDate: action.date,
      };
    }
    case EContractCalendarActions.LoadCalendarEvents: {
      return {
        ...state,
        calendarEvents: action.events,
      };
    }
    case EContractCalendarActions.SelectedCalendarClause: {
      return {
        ...state,
        selectedCalendarClause: {
          documentDayId: action.documentDayId,
          clauseId: action.clauseId,
          position: action.position,
          clauseResult: action.fullClauseResult,
          documentUuid: action.documentId,
          resultId: action.resultId,
        },
      };
    }
    case EContractCalendarActions.ResetSelectedCalendarClause: {
      return {
        ...state,
        selectedCalendarClause: null,
      };
    }
    case EContractCalendarActions.ResetContractCalendar: {
      return initialContractCalendarState;
    }
    case EContractCalendarActions.SetAddEventControlVisibility: {
      return {
        ...state,
        showAddEventControls: action.visible,
      };
    }
    case EContractCalendarActions.IsEventDialogVisible: {
      return {
        ...state,
        isEventDialogVisible: action.visible,
      };
    }
    case EContractCalendarActions.IsTerminationDialogVisible: {
      return {
        ...state,
        isTerminationDialogVisible: action.visible,
      };
    }
    case EContractCalendarActions.SetClauseEvents: {
      return {
        ...state,
        clauseEvents: action.clauseEvents,
      };
    }
    default:
      return state;
  }
}
