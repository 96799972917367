import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationInsightsService } from '@summize/shared/core';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    private lastError: string = '';
    private runawayErrorCount: number = 0;

    constructor(
        private applicationInsightsService: ApplicationInsightsService,
        private router: Router
    ) { }

    public handleError(error) {

        // Unexpected token or Loading Chunk failed is when HTML was served but
        // javascript was expected. This happens when we update prod with active users

        if (error !== undefined && error.message !== undefined) {

            const chunkFailedMessage = /Loading chunk [\d]+ failed/;

            if (chunkFailedMessage.test(error.message)) {

                window.location.reload();

                return;

            }

        }

        if (error !== undefined && error.includes !== undefined) {

            if (error.includes(`Unexpected token '<'`) ||
                error.includes(`Error: Angular JIT compilation failed: '@angular/compiler' not loaded!`)) {

                window.location.reload();

                return;
            }

        }

        console.error(error);

        // Try to prevent runaway errors (by tracking and redirecting to a different page)
        if (error?.message?.length > 0 && error.message === this.lastError) {
            this.runawayErrorCount += 1;
        }
        else {
            this.lastError = error?.message ?? '';
            this.runawayErrorCount = 0;
        }

        if (this.runawayErrorCount > 20) {

            error.message = `Runaway Error Triggered (${this.runawayErrorCount}): ${error.message}`;

            console.error(error);

            // Silently fail the AppInsight tracking as we don't want a recursive exception loop.
            try {

                this.applicationInsightsService.trackError(error);

            }
            catch (appInsightErr) {

                console.error(appInsightErr);
    
            }

            this.lastError = '';
            this.runawayErrorCount = 0;

            this.router.navigateByUrl('/problem?display=error-retry&error=runaway-error');

            return;

        }

        // Silently fail the AppInsight tracking as we don't want a recursive exception loop.
        try {

            this.applicationInsightsService.trackError(error);

        } catch (appInsightErr) {

            console.error(appInsightErr);

        }

    }

}