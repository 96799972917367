import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent, TelemetryService } from '@summize/shared/core';
import { SummizeStorage } from '@summize/shared/framework';

import { RemoteProxyService } from './remote-proxy.service';

@Component({
    templateUrl: 'remote-proxy.html',
    styleUrls: ['./remote-proxy.scss']
})

export class RemoteProxyComponent extends AppComponent implements OnInit {

    private intentMap = {
        'new-request': 'quick-summary/external',
        'view-request': 'quick-summary/external-contract',
        'view-all-repo': 'contracts/repository',
        'view-all-requests': 'quick-summary/repository',
        'view-request-anal': 'quick-summary/dashboards'
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: RemoteProxyService,
        private telemetry: TelemetryService) {

        super();

    }

    public async ngOnInit(): Promise<void> {

        this.subscribe(this.route.queryParams, async (params: any) => {

            setTimeout(async () => {
                try {

                    SummizeStorage.isolate();

                    await this.service.setupEnvironment();

                    if (params.host !== undefined) {

                        SummizeStorage.setHost(params.host);

                    }

                    if (params.token !== undefined) {

                        SummizeStorage.setLocalItem('token', params.token);

                        await this.service.refreshSession();

                    } else {

                        await this.service.validate(
                            params.connectorId,
                            params.connector,
                            params.connectorUserId,
                            params.connectorUserName,
                            params.connectorKey
                        );

                    }

                    const matchingRoute = this.intentMap[params.intent.toLowerCase()];

                    if (matchingRoute !== undefined) {

                        const queryParams = [];

                        for (const key of Object.keys(params)) {

                            queryParams.push(`${key}=${params[key]}`)

                        }

                        SummizeStorage.setSessionItem(SummizeStorage.LaunchParams, JSON.stringify(params));

                        this.telemetry.track('Summize-eLFDOpened', { host: params.host });

                        this.router.navigateByUrl(`${matchingRoute}?${queryParams.join('&')}`);

                    }

                } catch (error) {

                    console.log(error);

                    this.telemetry.track('Summize-eLFDFailedToOpen');

                    this.router.navigate(['problem'], {
                        queryParams: {
                            display: 'elfd-error'
                        }
                    });

                }
            }, 0);

        });

    }

}
