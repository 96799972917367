<div class="horizontalScroll">
    <app-icon-button circle primary (click)="onLeftClick()" class="horizontalScroll__btn--left" icon="icon-back" #leftBtn
                     *ngIf="showLeftBtn"></app-icon-button>
    <div class="horizontalScroll__scroll">
        <div class="horizontalScroll__content" #tabContainer>
            <ng-content></ng-content>
        </div>
    </div>
    <app-icon-button circle primary (click)="onRightClick()" #rightBtn class="horizontalScroll__btn--right" icon="icon-next"
                     *ngIf="showRightBtn"></app-icon-button>
</div>
