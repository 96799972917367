import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent } from '@summize/shared/core';

@Component({
    templateUrl: 'login-problem.html',
    styleUrls: ['./login-problem.scss']
})

export class LoginProblemComponent extends AppComponent implements OnInit {

    public display: 'error' | 'permissions' | 'access' | 'error-retry' | 'elfd-error' | 'feature-flag-missing';

    constructor(private router: Router, private route: ActivatedRoute) {

        super();

    }

    public ngOnInit(): void {

        this.subscribe(this.route.queryParams, (params) => {

            this.display = params['display'] || 'error';

        });

    }

    public signout(): void {

        this.router.navigateByUrl('/logout');

    }
}