import { Directive, Input, ElementRef } from '@angular/core';

import { UserService } from '../services/user.service';

// HowTo:
// Add this to your element: [appClaimsDisble]="['CanDocumentTypesCreate']" 
// Note; Relies on the actual control suporting 'disabled' correctly. Which
// we mostly don't.

@Directive({
    selector: '[appClaimsDisable]'
})
export class ClaimsDisableDirective {

    claims: string[] = [];

    constructor(
        private el: ElementRef,
        private userService: UserService
    ) {
    }

    updateView() {
        const claims = this.userService.getClaims();

        if (this.claims) {
            if (this.userService.hasClaims(this.claims)) {
                this.el.nativeElement.disabled = true;

                // TODO: Remove this, it is just for demonstration
                // this.el.nativeElement.style.backgroundColor = 'red';
            } else {
                this.el.nativeElement.disabled = false;
            }
        }
    }

    @Input() set appClaimsDisable(claims: string[]) {
        this.claims = claims;
        this.updateView();
    }
}