import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
})
export class DropDownMenuComponent implements OnInit {
  @Input() right: boolean;
  @Input() left: boolean;
  @Input() selectedLabel: string;
  @Input() label = '';
  @Input() icon = '';
  @Input() size: 'small' | 'regular' | 'large' = 'small';

  ngOnInit(): void {
    if (this.right === undefined) {
      this.left = true;
    }
    if (this.right && this.left) {
      this.right = false;
      this.left = true;
      console.warn('Must be left or right');
    }
  }
}
