import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { EventService, ExpandUserPanel, ShowFeedback, SummizeClaims } from '@summize/shared/framework';

import { AppState } from '../../../store/state/app.state';
import { CurrentPage } from '../../../store/state/page.state';
import { selectCurrentPage } from '../../../store/selectors/current-page.selectors';
import { selectDocumentId, selectDocumentIsShared } from '../../../store/selectors/contract-review.selectors';
import { selectIsTrial } from './../../../store/selectors/account.selectors';
import { selectTableIsShared } from '../../../store/selectors/agreements.selectors';
import { selectUser } from '../../../store/selectors/common.selectors';
import { StoreComponent } from '../../util/store-component';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'app-private-header',
  templateUrl: './private-header.component.html',
  styleUrls: ['./private-header.component.scss'],
  host: {
    '[class.className]': 'className',
  }
})
export class PrivateHeaderComponent extends StoreComponent implements OnInit {

  @Input() shadow: boolean;
  @Input() transparent: boolean;
  @Input() full = false;

  public showBreadcrumb = true;
  public showSearch = true;
  public showTrial = false;
  public showTableShared = false;
  public showDocumentShared = false;
  public showWordAddInLink = false;
  public documentIsShared = false;
  public tableIsShared = false;
  public isTrial = false;
  public IsInManagementRoute = false;
  public customerHubEnabled = false;
  public Claims = SummizeClaims;

  private fullName = 'Not Set';
  private isClientMatterEnabled = false;
  private currentPage: CurrentPage = CurrentPage.HomePage;
  private activeClientId: string;
  private activeClientMatterName: string;
  private activeClientName: string;
  private activeMatterId: string;
  private documentId: string;

  constructor(
    protected store: Store<AppState>,
    private router: Router,
    private userService: UserService,
    private eventService: EventService
  ) {
    super(store);

    this.select(selectDocumentId, documentId => this.documentId = documentId);
    this.select(selectIsTrial, isTrial => this.isTrial = isTrial);

    this.select(selectCurrentPage, cp => {

      this.currentPage = cp;

      const isOnSettingsPage = this.router.url.includes('admin/user');
      const isClientMatterEnabled = this.userService.getIsClientMatterEnabled();

      this.showBreadcrumb = isClientMatterEnabled && !(this.router.url.startsWith('/dashboard') || this.router.url.startsWith('/s/'));

      this.showTableShared = [CurrentPage.AgreementsPage].includes(this.currentPage);
      this.showDocumentShared = [CurrentPage.ContractReview].includes(this.currentPage);

      this.showTrial = [
        CurrentPage.AgreementsPage,
        CurrentPage.ContractReview
      ].includes(this.currentPage) && isOnSettingsPage === false;

      this.showWordAddInLink = [
        CurrentPage.AgreementsPage,
        CurrentPage.ContractReview,
        CurrentPage.ClientsPage,
        CurrentPage.ClientMattersPage
      ].includes(this.currentPage) && isOnSettingsPage === false;

      this.showSearch = [CurrentPage.AgreementsPage].includes(this.currentPage);

      this.select(selectDocumentIsShared, documentIsShared => this.documentIsShared = documentIsShared);
      this.select(selectTableIsShared, tableIsShared => this.tableIsShared = tableIsShared);
    });

    this.select(selectUser, user => {
      if (user) {
        this.fullName = `${user.firstName} ${user.lastName}`;
        this.isClientMatterEnabled = user.isClientMatterEnabled;
      }
    });

    this.router.events.subscribe(_ => {

      this.IsInManagementRoute =
        router.url.includes('clause-manager') ||
        router.url.includes('dashboard');

    });

  }

  ngOnInit() {
    super.ngOnInit();

    this.customerHubEnabled = this.userService.hasFeatureFlag('CustomerHub');

    this.shadow = this.shadow !== undefined && this.shadow !== false;
    this.transparent = this.transparent !== undefined && this.transparent !== false;

  }

  public showPanel(tab: number): void {

    this.eventService.despatch(ExpandUserPanel, { tab });

  }

  onLogoutClicked() {
    this.router.navigateByUrl('/logout');
  }


  public showFeedback() {

    this.eventService.despatch(ShowFeedback);

  }
}
