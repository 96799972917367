import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';

import { groupBy } from 'lodash-es';

import { BaseTenantService } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';

export const CalendarEventChanged = 'CalendarEventChanged';

@Injectable({ providedIn: 'root' })
export class CalendarV2Service extends BaseTenantService {

    private static DATE_FORMAT = 'yyyy-MM-dd';

    private daysOfTheWeek: string[] = ["Mon", "Tues", "Wed", "Thu", "Fri", "Sat", "Sun"];

    constructor(private http: HttpClient, private events: EventService) { super(); }

    public async getCalendarEventsForDocumentIdAsync(documentId: string, includePastEvent: boolean = false, from: any = undefined, to: any = undefined) {

        const baseUrl = this.getUserBaseUrl(true);

        const toParam = to !== undefined ? `&to=${to.toISOString()}` : '';

        const fromParam = from !== undefined ? `&from=${from.toISOString()}` : '';

        const events = await this.http.get<any>(`${baseUrl}manage/contracts/events/${documentId}?includePastEvent=${includePastEvent}${fromParam}${toParam}`).toPromise();

        return events;
    }

    public async createCalendarEvent(eventModel: any, clientId: string, matterId: string, documentId: string, secondReminder: boolean): Promise<any> {

        const payload = this.buildPayload(eventModel, secondReminder);

        const baseUrl = this.getUserBaseUrl(true);

        await this.http.post(`${baseUrl}clients/${clientId}/matters/${matterId}/documents/${documentId}/calendarEvents`, payload).toPromise();

        this.events.despatch(CalendarEventChanged, payload);
    }

    public async updateCalendarEvent(eventModel: any, secondReminder: boolean): Promise<any> {

        const payload = this.buildPayload(eventModel, secondReminder);

        const baseUrl = this.getUserBaseUrl(true);

        const update = await this.http.put(`${baseUrl}CalendarEvents/${eventModel.eventId}`, payload).toPromise();

        this.events.despatch(CalendarEventChanged, payload);

        return update;
    }

    private buildPayload(eventModel: any, secondReminder: boolean) {

        const payload = {

            calendarEventType: eventModel.calendarEventType,
            eventDate: this.buildConsistentDate(eventModel.eventDate),
            description: eventModel.description,
            clauseId: eventModel.clauseId,
            includeInSummaryEmails: eventModel.includeInSummaryEmails,
            isEvergreen: eventModel.isEvergreen,
            reminderDates: [],
            isHiddenFromCalendar: false,
            noticePeriod: eventModel.noticePeriod,
            notifications: []
        }

        if (eventModel.customReminder) {

            if (eventModel.notifications.length) {

                eventModel.notifications.forEach(notification => {

                    const notificationEntry = {
                        notificationEntityType: notification.notificationEntityType,
                        notificationEntityId: notification.notificationEntityId
                    }

                    payload.notifications.push(notificationEntry);

                });

            }

            payload.reminderDates.push(this.buildConsistentDate(eventModel.firstReminder));

            if (secondReminder) {

                payload.reminderDates.push(this.buildConsistentDate(eventModel.secondReminder));

            }

        }

        return payload;

    }

    private buildConsistentDate(date: any) {

        const formattedDate = formatDate(date, CalendarV2Service.DATE_FORMAT, 'en');

        return formattedDate;
    }

    public async deleteCalendarEvent(id: string): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        const update = await this.http.delete(`${baseUrl}CalendarEvents/${id}`).toPromise();

        this.events.despatch(CalendarEventChanged, update);

        return update;
    }

    public async getClausesForDate(documentId: string): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        const document = await this.http.get<any>(`${baseUrl}documents/${documentId}`).toPromise();

        const clauses = await this.http.get<any>(`${baseUrl}documentTypes/${document.documentTypeId}/Clauses`).toPromise();

        return clauses.map(x => ({
            clauseId: x.clauseId || x.ClauseId,
            ruleName: x.clauseName || x.ClauseName,
        }));

    }

    public async downloadEvent(id: string): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        const options = { responseType: 'blob' as 'json' };

        return this.http.get(`${baseUrl}calendarEvents/${id}?format=ics`, options).toPromise();

    }

    public loadCalendarCards(unsortedEvents: any) {

        // Get text day of week and create parties string
        unsortedEvents.forEach((x: any) => {

            const date = new Date(x.eventDateUtc);
            x.eventDateDay = date.getDate();
            const day = date.getDay() - 1;
            const sunday = 6;

            x.eventDateDayStr = day >= 0 ? this.daysOfTheWeek[day] : this.daysOfTheWeek[sunday];
            x.parties = x?.parties?.join(', ');

        });


        // Sort chronologically
        unsortedEvents = unsortedEvents.sort((a, b) => {

            const dateA = new Date(a.eventDateUtc).valueOf();
            const dateB = new Date(b.eventDateUtc).valueOf();

            return dateA - dateB;

        });

        // Group by month/year
        unsortedEvents = groupBy(unsortedEvents,
            (y) => {
                const eventDate = new Date(y.eventDateUtc);
                const month = eventDate.getMonth();
                const year = eventDate.getFullYear();
                return new Date(year, month);
            });

        // Sort groups chronologically
        const sortedEvents = Object
            .entries(unsortedEvents)
            .sort((a, b) => <any>new Date(a[0]) - <any>new Date(b[0]));

        return sortedEvents;

    }

    public async loadTenantEventsForMonth(date: Date) {

        if (!date) {
            return;
        }

        const from = new Date(date.getFullYear(), date.getMonth(), 1);
        const to = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return this.loadEventsForTenant(from, to);

    }

    public async loadEventsForTenant(from: Date, to: Date) {

        const baseUrl = this.getUserBaseUrl(true);
        const fromQuery = formatDate(from, CalendarV2Service.DATE_FORMAT, 'en');
        const toQuery = formatDate(to, CalendarV2Service.DATE_FORMAT, 'en');

        return await this.http.get<any>(`${baseUrl}CalendarEvents?startDate=${fromQuery}&endDate=${toQuery}`).toPromise();

    }

    public async loadEventsInDateRange(clientId: string, matterId: string, from: Date, to: Date) {

        const baseUrl = this.getUserBaseUrl(true);
        const fromQuery = formatDate(from, CalendarV2Service.DATE_FORMAT, 'en');
        const toQuery = formatDate(to, CalendarV2Service.DATE_FORMAT, 'en');

        if (clientId === '' || matterId === '') {
            return;
        }

        return this.http.get<any>(`${baseUrl}clients/${clientId}/Matters/${matterId}/Documents/CalendarEvents?startDate=${fromQuery}&endDate=${toQuery}`).toPromise();

    }

}
