import { Action } from '@ngrx/store';
import { Template } from '../../core';

export enum EDocumentTypeActions {
  SetSelectedDocumentType = '[Document Type] Set selected document type id',
  ResetSelectedDocumentType = '[Document Type] Reset selected document type id',
  SetDocumentTypes = '[Document Type] Load template tabs',
  ResetDocumentTypes = '[Document Type] Reset template tabs',
}

export class SetDocumentTypes implements Action {
  public readonly type = EDocumentTypeActions.SetDocumentTypes;

  constructor(public payload: Template[]) {}
}

export class ResetDocumentTypes implements Action {
  public readonly type = EDocumentTypeActions.ResetDocumentTypes;
}

export class SetSelectedDocumentType implements Action {
  public readonly type = EDocumentTypeActions.SetSelectedDocumentType;

  constructor(public payload: Template) {}
}

export class ResetSelectedDocumentType implements Action {
  public readonly type = EDocumentTypeActions.ResetSelectedDocumentType;
}

export type DocumentTypeActions = SetDocumentTypes | ResetDocumentTypes | SetSelectedDocumentType | ResetSelectedDocumentType;
