import { AgreementsState, initialAgreementsState } from '../state/agreements.state';
import { AgreementsActions, EAgreementsActions } from '../actions/agreements.actions';

export function agreementsReducers(state: AgreementsState = initialAgreementsState, action: AgreementsActions): AgreementsState {
  switch (action.type) {
    case EAgreementsActions.UpdateRowSelection: {
      if (action.enabled && !state.selectedDocumentIds.includes(action.documentId)) {
        return {
          ...state,
          selectedDocumentIds: [...state.selectedDocumentIds, action.documentId],
        };
      }
      if (!action.enabled && state.selectedDocumentIds.includes(action.documentId)) {
        return {
          ...state,
          selectedDocumentIds: state.selectedDocumentIds.filter((sdi) => sdi !== action.documentId),
        };
      }
      return {
        ...state,
      };
    }
    case EAgreementsActions.ClearSelectedRows: {
      return {
        ...state,
        selectedDocumentIds: [],
      };
    }
    case EAgreementsActions.SetTotalAgreements: {
      return {
        ...state,
        totalAgreements: action.payload,
      };
    }
    case EAgreementsActions.LoadAgreements: {
      return {
        ...state,
        agreements: action.payload,
      };
    }
    case EAgreementsActions.LoadPendingAgreements: {
      const pendingAgreementDocIds = action.payload.map((pa) => pa.documentId);
      return {
        ...state,
        pendingAgreements: action.payload,
        uploadingAgreements: state.uploadingAgreements.filter((ua) => !pendingAgreementDocIds.includes(ua.documentId)),
      };
    }
    case EAgreementsActions.UpdatePendingAgreement: {
      const pending = state.pendingAgreements.filter((pa) => pa.documentId !== action.payload.documentId);
      const updateRecord = state.pendingAgreements.find((pa) => pa.documentId === action.payload.documentId);

      if (updateRecord !== undefined) {
        pending.push({
          documentId: updateRecord.documentId,
          id: updateRecord.id,
          tenantId: updateRecord.tenantId,
          documentName: updateRecord.documentName,
          status: action.payload.status,
          error: action.payload.error,
          clientId: action.payload.clientId,
          matterId: action.payload.matterId,
          parties: updateRecord.parties,
          templateId: updateRecord.templateId,
        });

        return {
          ...state,
          pendingAgreements: pending,
        };
      }
      console.warn('Pending file not found');
      return state;
    }
    case EAgreementsActions.RemoveUploadAgreement: {
      return {
        ...state,
        uploadingAgreements: state.uploadingAgreements.filter((p) => p.tempId !== action.tempId),
      };
    }
    case EAgreementsActions.RemovePendingAgreement: {
      return {
        ...state,
        pendingAgreements: state.pendingAgreements.filter((p) => p.documentId !== action.payload.documentId),
      };
    }
    case EAgreementsActions.AddUploadingFile: {
      const uploads = state.uploadingAgreements.slice(0);
      uploads.push(action.payload);
      return {
        ...state,
        uploadingAgreements: uploads,
      };
    }
    case EAgreementsActions.UpdateUploadingFile: {
      if (state.uploadingAgreements.filter((f) => f.file.name === action.payload.file.name && f.file.size === action.payload.file.size).length > 0) {
        const uploads = state.uploadingAgreements.filter((f) => f.file.name !== action.payload.file.name && f.file.size !== action.payload.file.size);
        uploads.push(action.payload);
        return {
          ...state,
          uploadingAgreements: uploads,
        };
      }
      console.warn('Could not find upload file', action);
      return state;
    }
    case EAgreementsActions.ResetNewTab: {
      return {
        ...state,
        newTab: initialAgreementsState.newTab,
      };
    }
    case EAgreementsActions.UpdateNewTab: {
      return {
        ...state,
        newTab: action.payload,
      };
    }
    case EAgreementsActions.LoadTemplateTypes: {
      return {
        ...state,
        newTab: {
          ...state.newTab,
          templateTypes: action.payload,
        },
      };
    }
    case EAgreementsActions.LoadClauseTypes: {
      return {
        ...state,
        clauseTypes: action.payload,
      };
    }
    case EAgreementsActions.ResetAgreements: {
      return {
        ...state,
        agreements: [],
        totalAgreements: 0,
      };
    }
    case EAgreementsActions.SetSearchTerm: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }
    case EAgreementsActions.UpdateFilter: {
      return {
        ...state,
        filter: {
          type: action.filterType,
          value: action.value,
          label: action.label,
        },
      };
    }
    case EAgreementsActions.ResetFilter: {
      return {
        ...state,
        filter: undefined,
        filterEnabled: false,
      };
    }
    case EAgreementsActions.SetFilterEnabled: {
      return {
        ...state,
        filterEnabled: action.enabled,
      };
    }
    case EAgreementsActions.SetFilteredNumberAgreements: {
      return {
        ...state,
        filteredCount: action.total,
      };
    }
    case EAgreementsActions.SetCurrentPage: {
      return {
        ...state,
        currentPage: action.pageNumber,
      };
    }
    case EAgreementsActions.SetPageSize: {
      return {
        ...state,
        pageSize: action.pageSize,
      };
    }
    case EAgreementsActions.SetSelectedTableIsShared: {
      return {
        ...state,
        tableIsShared: action.isShared,
      };
    }
    case EAgreementsActions.SetSelectedTableShareInformation: {
      return {
        ...state,
        tableIsShared: action.shared,
      };
    }

    default:
      return state;
  }
}
