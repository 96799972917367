import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { EventService } from '@summize/shared/framework';
import { EMPTY_GUID } from '@summize/shared/core';

import { UserService } from './user.service';
import {
  LoadClauses,
  AddClauseResult,
  UpdateClauseResultLoadingStatus,
  UpdateClauseResultText
} from '../../store/actions/contract-review.actions';
import { AppState } from '../../store/state/app.state';
import { RefreshClauseSummary } from '../../modules/my-contracts/events';
import { Clause } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RuleService {
  public loading: Observable<boolean>;
  private loadingSubject: BehaviorSubject<boolean>;
  public addingClause: Observable<string>;
  private addingClauseSubject: Subject<string>;
  private loadingState = false;
  isBulletSubmitting = true;
  private version = '1.0';

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private store: Store<AppState>,
    private events: EventService
  ) {
    this.loadingSubject = <BehaviorSubject<any>>(
      new BehaviorSubject(this.loadingState)
    );
    this.loading = this.loadingSubject
      .asObservable()
      .pipe(distinctUntilChanged());

    this.addingClauseSubject = new Subject();
    this.addingClause = this.addingClauseSubject
      .asObservable();
  }

  async addClause(clientId: string, matterId: string, documentUuid: string, clauseIndex: number, clauseName: string, clauseText: string, newPara: string, points: Array<number>,
    pageNumber: number, linkedDocumentId: string, isQuickSummary: boolean = false, clauseId: number = undefined) {

    if (isQuickSummary === true) {

      //Simple path -- If Quick summary, just save and nothing else.

      const baseUrl = this.getBaseUrl();

      const url = `${baseUrl}/documents/${documentUuid}/clauses`;

      const body = {
        clientId,
        matterId,
        clauseIndex: clauseIndex,
        clauseName: clauseName,
        clauseId,
        clauseText,
        newPara,
        points,
        pageNumber,
        linkedDocumentId
      };

      return await this.http
        .post<any>(url, body, { responseType: 'json' }).toPromise()
        .then(() => {
          this.store.dispatch(new AddClauseResult(clauseName, newPara, clauseText, ''));

        })
        .catch(err => {
          console.warn(`Failed to add rule`);
          this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'ERROR'));
          throw err;
        });

    }

    this.addingClauseSubject.next(clauseName);

    this.store.dispatch(new AddClauseResult(clauseName, newPara, clauseText, 'Adding bullet'));

    const baseUrl = this.getBaseUrl();

    const url = `${baseUrl}/documents/${documentUuid}/clauses`;

    const body = {
      clientId,
      matterId,
      clauseIndex: clauseIndex,
      clauseName: clauseName,
      clauseId,
      clauseText,
      newPara,
      points,
      pageNumber,
      linkedDocumentId
    };

    return this.http
      .post<any>(url, body, { responseType: 'json' }).toPromise()
      .then(async () => {

        await this.loadContractRules(documentUuid);

        this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, ''))

      })
      .catch(err => {
        console.warn(`Failed to add rule`);
        this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'ERROR'));
        throw err;
      });
  }

  async editClause(clientId: string, matterId: string, documentUuid: string, clause: Clause, clauseIndex: number, clauseName: string, clauseText: string, newPara: string, clauseId: number, isQuickSummary: boolean = false) {

    if (isQuickSummary === true) {

      //Simple path -- If Quick summary, just save and nothing else.

      const baseUrl = this.getBaseUrl();

      const url = `${baseUrl}/documents/${documentUuid}/clauses`;

      clientId = clientId ?? EMPTY_GUID;
      matterId = matterId ?? EMPTY_GUID;

      const body = {
        clientId,
        matterId,
        clauseIndex: clauseIndex,
        clauseName: clauseName,
        clauseText,
        clauseId,
        newPara
      };

      return await this.http
        .put<any>(url, body, { responseType: 'json' }).toPromise()
        .then(async () => {

          this.store.dispatch(new UpdateClauseResultText(clause, clauseIndex, clauseText));

          this.events.despatch(RefreshClauseSummary);

          return clauseText;
        })
        .catch(err => {
          this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'ERROR'));
          throw err;
        });

    }

    this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'Editing clause'));

    const baseUrl = this.getBaseUrl();

    const url = `${baseUrl}/documents/${documentUuid}/clauses`;

    const body = {
      clientId,
      matterId,
      clauseIndex: clauseIndex,
      clauseName: clauseName,
      clauseText,
      clauseId,
      newPara
    };

    await this.http
      .put<any>(url, body, { responseType: 'json' }).toPromise()
      .then(async () => {
        await this.requestContractRules(documentUuid).then(() => {
          this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, ''));
        })
          .catch(err => {
            this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'ERROR'));
            throw err;
          });
      })
      .catch(err => {
        this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'ERROR'));
        throw err;
      });
  }

  async deleteClause(documentUuid: string, clauseIndex: number, clauseName: string, clauseId: number, isQuickSummary: boolean = false) {

    if (this.loadingState) {
      return;
    }

    if (isQuickSummary === true) {

      //Simple path -- If Quick summary, just save and nothing else.

      this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'Deleting clause'));

      const options = {
        body: [{ clauseIndex: clauseIndex, clauseId: clauseId }]
      };

      const baseUrl = this.getBaseUrl();

      try {

        await this.http.request('DELETE', `${baseUrl}/documents/${documentUuid}/clauses`, options).toPromise();

        this.events.despatch("RefreshQuickSummaryClauses");

      } catch (err) {

        this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'ERROR'));

        throw err;

      }

      return;

    }

    this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'Deleting clause'));

    const options = {
      body: [{ clauseIndex: clauseIndex, clauseId: clauseId }]
    };

    const baseUrl = this.getBaseUrl();
    try {
      await this.http.request('DELETE', `${baseUrl}/documents/${documentUuid}/clauses`, options).toPromise();
      await this.requestContractRules(documentUuid);
    } catch (err) {
      this.store.dispatch(new UpdateClauseResultLoadingStatus(clauseName, clauseIndex, 'ERROR'));
      throw err;
    }
  }

  async deleteNonAiBulletsFromClause(documentUuid: string, clause: Clause, isQuickSummary: boolean = false) {

    const toDelete = [];

    for (let i = 0; i < clause.ruleResult.length; i++) {

      toDelete.push({ clauseIndex: i, clauseId: clause.clauseId });

    }

    const options = {
      body: toDelete
    };

    if (this.loadingState) {
      return;
    }

    const baseUrl = this.getBaseUrl();

    if (isQuickSummary === true) {

      toDelete.forEach(result => {

        this.store.dispatch(new UpdateClauseResultLoadingStatus(clause.ruleName, result.clauseIndex, 'Deleting clause'));

      });

      try {

        await this.http.request('DELETE', `${baseUrl}/documents/${documentUuid}/clauses`, options).toPromise();

        this.events.despatch("RefreshQuickSummaryClauses");

      } catch (err) {

        toDelete.forEach(result => {

          this.store.dispatch(new UpdateClauseResultLoadingStatus(clause.ruleName, result.clauseIndex, 'ERROR'));

        });


        throw err;

      }

      return;

    }

    toDelete.forEach(result => {

      this.store.dispatch(new UpdateClauseResultLoadingStatus(clause.ruleName, result.clauseIndex, 'Deleting clause'));

    });

    try {
      await this.http.request('DELETE', `${baseUrl}/documents/${documentUuid}/clauses`, options).toPromise();
      await this.requestContractRules(documentUuid);
    } catch (err) {
      toDelete.forEach(result => {
        this.store.dispatch(new UpdateClauseResultLoadingStatus(clause.ruleName, result.clauseIndex, 'ERROR'));
      });

      throw err;
    }
  }

  async loadContractRules(documentUuid: string) {

    if (this.loadingState) {
      return;
    }

    this.setLoading(true);

    try {
      const rules = await this.requestContractRules(documentUuid)
      this.setLoading(false);
      return rules;
    } catch (err) {
      this.setLoading(false);
      throw err;
    }
  }

  async getContractRulesWithoutNGRX(documentId): Promise<any> {

    const baseUrl = this.getBaseUrl();

    return await this.http.get<any>(`${baseUrl}/documents/${documentId}`).toPromise();

  }

  async requestContractRules(documentUuid: string): Promise<any> {
    const baseUrl = this.getBaseUrl();

    const result = await this.http
      .get<any>(
        `${baseUrl}/documents/${documentUuid}`,
        {
          responseType: 'json'
        }
      ).toPromise();
    this.store.dispatch(new LoadClauses(result));
    return result;
  }

  public setLoading(state: boolean) {
    this.loadingState = state;
    this.loadingSubject.next(this.loadingState);
  }

  newBulletClicked(event: MouseEvent) {
    event.stopPropagation();

    this.isBulletSubmitting = true;
  }

  private getBaseUrl(): URL {
    const baseUrl = this.userService.getUserBaseUrl();
    const url = new URL(this.version, baseUrl);
    return url;
  }
}



