<div class="toast__container" *ngIf="toasts.length > 0">
  <div class="toast" *ngFor="let toast of toasts" [@showFlyInFlyOut]>
    <div class="icon {{ toast.icon }} toast__icon"></div>
    <h5 class="toast__text">{{ toast.message }}</h5>

    <div class="toast-close">
      <svg class="toast-close__progress" width="32" height="32">
        <circle pathLength="100" class="toast-close__progress-circle" fill="transparent"
          r="13" cx="50%" cy="50%" />
      </svg>
      <i class="icon icon-close toast-close__icon" (click)="onDismissToast(toast)"></i>
    </div>
  </div>
</div>