<div cdkDrag *ngIf="visible" class="terminationDialog">
    <app-icon-button class="terminationDialog__close" (click)="close()" icon="icon-close"></app-icon-button>
    <p class="terminationDialog__title">Add <strong>Termination</strong> to calendar</p>
    <mat-form-field class="terminationDialog__date">
        <input matInput [matDatepicker]="picker" placeholder="End date" (dateInput)="onDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="row terminationDialog__notice">
        <span>Notice period</span>
        
        <mat-form-field class="terminationDialog__input">
            <input type="number" matInput [(ngModel)]="period">
        </mat-form-field>
        
        <span><strong>Days</strong></span>
    </div>
    <div class="button button__flat" button (click)="add()">
        Add
    </div>
</div>