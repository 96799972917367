<ng-template #ngContent>
    <ng-content></ng-content>
</ng-template>

<div *ngIf="isReady !== true">
    <ng-container *ngTemplateOutlet="ngContent"></ng-container>
</div>

<div *ngIf="isReady === true" class="v2-layout" [class.menu-expanded]="menuExpanded">

    <div [@fadeInAnimation] class="container-fluid px-0 no-scroll">

        <div *ngIf="showFrame()" class="fade-block">
            <app-header [user]="user" [logo]="logoUrl">
                <app-private-header right></app-private-header>
            </app-header>
        </div>

        <div class="fade-block view-container">

            <div *ngIf="showSideMenu" class="side-bar fade-block">
                <app-side-menu></app-side-menu>
            </div>

            <div class="router scroll">
                <ng-container *ngTemplateOutlet="ngContent"></ng-container>
            </div>
        </div>

        <app-slide-panel></app-slide-panel>
        <app-contract-insights></app-contract-insights>
        <cc-termination-dialog></cc-termination-dialog>
        <cc-event-dialog></cc-event-dialog>
        <app-toast-container></app-toast-container>
    </div>
</div>