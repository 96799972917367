import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from "@summize/environment";
import { ApplicationInsightsService, BaseTenantService } from '@summize/shared/core';
import { SummizeStorage, UserService } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class RemoteProxyService extends BaseTenantService {

    constructor(private http: HttpClient,
        private userService: UserService,
        private appInsights: ApplicationInsightsService) { super(); }

    public async validate(connectorId: string, connector: any, connectorUserId: string, connectorUsername: string, connectorKey: string) {

        const payload = {
            connectorId,
            connector,
            connectorUserId,
            connectorUsername,
            apiKey: connectorKey
        };

        await this.http.post(`${environment.apiUrl}/api/1.0/connector-auth/auth/validate`, payload).toPromise();

        await this.refreshSession();

    }

    public async refreshSession() {

        await this.userService.refreshSession();

    }

    public async setupEnvironment() {

        if (environment.regions !== undefined && environment.regions.length > 0) {

            const origin = window.location.origin;

            const match = environment.regions.find(x => x.appUrl.toLowerCase() === origin.toLowerCase());

            if (match !== undefined) {

                environment.apiUrl = match.endpoint;

            } else {

                throw new Error(`Unable to find matching region for origin: ${origin}`);

            }

        }

        const remoteEnvironment: any = await this.http.get(`${environment.apiUrl}/api/environment`).toPromise();

        if (environment.name === 'dev') {

            Object.keys(environment).forEach((key) => {

                if (environment[key] !== null) {

                    remoteEnvironment[key] = null;

                }

            });

        }

        Object.keys(remoteEnvironment).forEach((key) => {

            if (remoteEnvironment[key] !== null) {

                if (typeof remoteEnvironment[key] === 'string' && environment[key] !== null) {

                    if (remoteEnvironment[key].trim().length > 0) {

                        environment[key] = remoteEnvironment[key];

                    }

                } else {

                    environment[key] = remoteEnvironment[key];

                }
            }

        });

        SummizeStorage.setSessionItem(
            BaseTenantService.ENV_KEY, JSON.stringify(environment)
        );

        this.appInsights.init(environment.applicationInsights);

    }

}