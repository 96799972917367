import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarCardModule, CorePipesModule, IconModule } from '@summize/shared/components-v2';

import { CalendarComponent } from './components/calendar/calendar.component';
import { ContractCalendarComponent } from './contract-calendar.component';
import { ContractCalendarButtonComponent } from './components/contract-calendar-button/contract-calendar-button.component';
import { DiaryComponent } from './components/diary/diary.component';
import { CalendarEventComponent } from './components/calendarEvent/calendarEvent.component';
import { DateHeaderComponent } from './components/date-header/date-header.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { TerminationDialogComponent } from './components/terminationDialog/termination-dialog.component';
import { EventDialogComponent } from './components/eventDialog/event-dialog.component';
import { ViewDocumentPopupComponent } from './components/view-document-popup/view-document-popup.component';
import { CoreModule } from '../../core/core.module';
import { MaterialModule } from '../../core/material.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    IconModule,
    CalendarCardModule,
    CorePipesModule
  ],
  declarations: [
    ContractCalendarComponent,
    ContractCalendarButtonComponent,
    CalendarComponent,
    DiaryComponent,
    CalendarEventComponent,
    DateHeaderComponent,
    DatePickerComponent,
    TerminationDialogComponent,
    EventDialogComponent,
    ViewDocumentPopupComponent,
  ],
  providers: [],
  exports: [
    ContractCalendarComponent,
    ContractCalendarButtonComponent,
    CalendarComponent,
    DiaryComponent,
    CalendarEventComponent,
    DateHeaderComponent,
    DatePickerComponent,
    TerminationDialogComponent,
    EventDialogComponent,
    ViewDocumentPopupComponent
  ],
})
export class ContractCalendarModule { }
