import { CalendarEvent, SelectedClauseEvent, ClauseEvent } from '../../core/models/contractCalendar.model';

export interface ContractCalendarState {
  isCalendarOpen: boolean;
  showAddEventControls: boolean;
  isTerminationDialogVisible: boolean;
  isEventDialogVisible: boolean;
  calendarEvents: CalendarEvent[];
  selectedDate: Date;
  selectedCalendarClause: SelectedClauseEvent;
  clauseEvents: ClauseEvent[];
}

export const initialContractCalendarState: ContractCalendarState = {
  isCalendarOpen: false,
  showAddEventControls: false,
  isTerminationDialogVisible: false,
  isEventDialogVisible: false,
  selectedDate: new Date(),
  selectedCalendarClause: null,
  calendarEvents: [],
  clauseEvents: [],
};
