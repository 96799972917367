import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { StoreComponent } from '../../../../core/util/store-component';
import { AppState } from '../../../../store/state/app.state';
import { terminationDialogVisible } from '../../../../store/selectors/contract-calendar.selectors';
import { CalendarService } from '../../../../core/services/calendar.service';
import { selectDocumentId, selectClauses } from '../../../../store/selectors/contract-review.selectors';
import { IsTerminationDialogVisible } from '../../../../store/actions/contract-calendar.actions';
import { Clause } from '../../../../core';

@Component({
  selector: 'cc-termination-dialog',
  templateUrl: './termination-dialog.component.html',
  styleUrls: ['./termination-dialog.component.scss']
})
export class TerminationDialogComponent extends StoreComponent implements OnInit {

  visible = false;
  period = 0;
  date: Date;
  documentId: string;
  currentContractClauses: Clause[] = [];
  clientId: string;
  matterId: string;

  constructor(protected store: Store<AppState>,
              private calendarService: CalendarService) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.select(terminationDialogVisible, visible => this.visible = visible);
    this.select(selectDocumentId, documentId => this.documentId = documentId);
    this.select(selectClauses, clauses => this.currentContractClauses = clauses);
  }

  add() {
    if (!this.documentId || !this.period || !this.date) {
      console.error('documentId, period and date are all needed');
      return;
    }

    const clauseId = this.getTerminationClauseId();
    this.calendarService.createTermination(this.documentId, this.clientId, this.matterId, this.period, this.date, clauseId);
    this.close();
  }

  onDateSelected(event) {
    this.date = event.value;
  }

  close() {
    this.reset();
    this.store.dispatch(new IsTerminationDialogVisible(false));
  }

  private getTerminationClauseId(): number {
    if (!this.currentContractClauses) {
      return 0;
    }
    const clause = this.currentContractClauses.find(c => c.ruleName.toLowerCase() === 'termination');
    return clause ? clause.clauseId : 0;
  }

  private reset() {
    this.date = null;
    this.period = 0;
    this.currentContractClauses = [];
  }
}
