import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';

import { PillModule, FeedbackPanelModule, PipesModule } from '@summize/shared/components';
import { AvatarModule, IconModule, UserProfileModule } from '@summize/shared/components-v2';

import { FileDragZoneComponent } from './filedragzone/filedragzone.component';
import { DebugComponent } from './debug/debug.component';
import { DirectionArrowButtonComponent } from './direction-arrow-button/direction-arrow-button.component';
import { ToastComponent } from './toast/toast.component';
import { SummizeLogoComponent } from './summize-logo/summize-logo.component';
import { MaterialModule } from '../material.module';
import { PrivateHeaderComponent } from './private-header/private-header.component';
import { LoadingSpinnerComponent } from '../../modules/my-contracts/components/loading-spinner/loading-spinner.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { DropDownMenuComponent } from './drop-down-menu/drop-down-menu.component';
import { DropDownMenuItemComponent } from './drop-down-item/drop-down-menu-item.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DocumentCounterComponent } from './document-counter/document-counter.component';
import { IfCanViewClientsDirective } from '../directives/if-can-view-clients';
import { IfCanViewMattersDirective } from '../directives/if-can-view-matters';
import { IntroComponent } from './intro/intro.component';
import { StyledButtonComponent } from './styled-button/styled-button.component';
import { FlagComponent } from './flag/flag.component';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { DividerComponent } from './divider/divider.component';
import { HorizontalButtonScrollbarComponent } from './horizontal-button-scrollbar/horizontal-button-scrollbar.component';
import { ClaimsDisableDirective } from '../directives/claims-disable.directive';
import { ClaimsShowDirective } from '../directives/claims-show.directive';
import { ClaimsHideDirective } from '../directives/claims-hide.directive';
import { NoAccessComponent } from './no-access/no-access.component';
import { LoginProblemComponent } from './login-problem/login-problem.component';
import { RegionSelectorModule } from './region-selector/region-selector.module';
import { ContractRedirectComponent } from './contract-redirect/contract-redirect.component';
import { ContractRedirectService } from './contract-redirect/contract-redirect.service';
import { RemoteProxyModule } from './remote-proxy/remote-proxy.module';
import { FileDownloadRedirectModule } from './file-download-redirect/file-download-redirect.module';

@NgModule({
    imports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        PillModule,
        FeedbackPanelModule,
        PipesModule,
        IconModule,
        MatPaginatorModule,
        MatTooltipModule,
        RegionSelectorModule,
        UserProfileModule,
        RemoteProxyModule,
        AvatarModule,
        FileDownloadRedirectModule
    ],
    providers: [
        ContractRedirectService
    ],
    declarations: [
        DirectionArrowButtonComponent,
        ToastComponent,
        SummizeLogoComponent,
        PrivateHeaderComponent,
        LoadingSpinnerComponent,
        IconButtonComponent,
        DebugComponent,
        FileDragZoneComponent,
        DropDownMenuComponent,
        DropDownMenuItemComponent,
        RadioButtonComponent,
        DialogHeaderComponent,
        DocumentCounterComponent,
        IfCanViewClientsDirective,
        IfCanViewMattersDirective,
        IntroComponent,
        StyledButtonComponent,
        FlagComponent,
        ClickOutsideDirective,
        DividerComponent,
        HorizontalButtonScrollbarComponent,
        ClaimsShowDirective,
        ClaimsHideDirective,
        ClaimsDisableDirective,
        NoAccessComponent,
        LoginProblemComponent,
        ContractRedirectComponent
    ],
    exports: [
        DirectionArrowButtonComponent,
        ToastComponent,
        SummizeLogoComponent,
        PrivateHeaderComponent,
        IconButtonComponent,
        LoadingSpinnerComponent,
        DebugComponent,
        FileDragZoneComponent,
        DropDownMenuComponent,
        DropDownMenuItemComponent,
        RadioButtonComponent,
        DialogHeaderComponent,
        DocumentCounterComponent,
        IfCanViewClientsDirective,
        IfCanViewMattersDirective,
        IntroComponent,
        StyledButtonComponent,
        FlagComponent,
        ClickOutsideDirective,
        DividerComponent,
        HorizontalButtonScrollbarComponent,
        ClaimsShowDirective,
        ClaimsHideDirective,
        ClaimsDisableDirective,
        PillModule,
        NoAccessComponent,
        LoginProblemComponent,
        ContractRedirectComponent
    ]
})
export class CoreComponentsModule {
}
