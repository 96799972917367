import { Component, HostListener, OnInit } from '@angular/core';

import { environment } from '@summize/environment';
import { SelectBoxModel } from '@summize/shared/components-v2';
import { LocalStorageService } from '@summize/shared/framework';

import { RegionGuard } from '../../guards/region-guard.service';
import { RegionSelectorService } from './region-selector.service';

enum SelectorState {
    Initial, Searching, Selection, Confirm, NoMatches
}

@Component({
    selector: 'region-selector',
    templateUrl: 'region-selector.html',
    styleUrls: ['./region-selector.scss']
})

export class RegionSelectorComponent implements OnInit {

    @HostListener('document:keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent) {

        if (event.key === 'Enter') {

            switch (this.state) {
                case SelectorState.Initial:
                {
                    if (this.email?.length > 0) {

                        event.preventDefault();

                        this.getRegions();

                    }

                    break;
                }
                case SelectorState.Confirm:
                {

                    event.preventDefault();

                    this.continue();

                    break;

                }
                
            }

        }

    }

    public SelectorState = SelectorState;

    public state: SelectorState = SelectorState.Initial;

    public email: string;

    public options: Array<SelectBoxModel>;

    public availableRegions: Array<any>;

    public info: any = {};

    public isContinue: boolean = false;

    constructor(private service: RegionSelectorService, private storage: LocalStorageService) { }

    public ngOnInit() {

        this.email = this.storage.getItem('smz-oidc-email');

        let regionInfo = this.storage.getItem(RegionGuard.REGION_INFO);

        if (regionInfo !== null && regionInfo !== undefined) {

            this.info = JSON.parse(regionInfo);

            this.state = SelectorState.Confirm;

        }

    }

    public async getRegions() {

        this.state = SelectorState.Searching;

        const regions = environment.regions;

        const results = await this.service.getUserRegions(this.email, regions);

        this.availableRegions = results.filter(r => r.match === true);

        if (this.availableRegions.length === 0) {

            this.state = SelectorState.NoMatches;

            return;

        }

        if (this.availableRegions.length === 1) {

            this.setRegion(this.availableRegions[0].endpoint, true);


            return;

        }

        this.options = this.availableRegions.map(m => ({ key: m.endpoint, value: m.name }))

        this.state = SelectorState.Selection;

    }

    public setRegion(region: string, singleRegion: boolean = false) {

        const selectedRegion = environment.regions.find(r => r.endpoint === region);

        this.info = {
            username: this.email,
            region: selectedRegion,
            singleRegion: singleRegion
        }

        this.storage.setItem(RegionGuard.REGION_INFO, JSON.stringify(this.info));

        this.state = SelectorState.Confirm;

    }

    public clearRegion(): void {

        this.storage.removeItem(RegionGuard.REGION_KEY);

        this.storage.removeItem(RegionGuard.REGION_INFO);

        this.reload();
    }

    public continue(): void {

        try {

            this.isContinue = true;

            this.storage.setItem(RegionGuard.REGION_KEY, this.info.region.endpoint);

            if (this.info.username !== null && this.info.username !== undefined) {

                this.storage.setItem('smz-oidc-email', this.info.username);

            }

            this.reload();

        } 
        catch
        {

            this.isContinue = false;

        }

    }

    private reload(): void {

        document.location.href = "/";

    }

}