import { createAction } from '@ngrx/store';
import { ExportClause, ExportFormat, ExportItem, ExportType } from '../../core';

export const resetExportBuilder = createAction('[Export builder] Reset');

export const updateExportItem = createAction('[Export builder] Update', (exportItem: ExportItem) => ({ exportItem }));

export const clearExportItems = createAction('[Export Builder] Clear export items');

export const loadExportClauses = createAction('[Export Builder] LoadExportClauses', (exportClauses: Array<ExportClause>) => ({ exportClauses }));

export const exportButtonEnabled = createAction('[Export Builder] ExportButtonEnabled', (enabled: boolean) => ({ enabled }));

export const updateActiveExportFormat = createAction('[Export Builder] Export active export type', (format: ExportFormat) => {
  return { format };
});

export const updateExportType = createAction('[Export builder] Update export type', (exportType: ExportType) => ({ exportType }));

export const updateSelectedExportDocuments = createAction('[Export builder] Update selected export contracts', (selectedDocIds: string[]) => ({
  selectedDocIds,
}));

export const setExcelSingleRow = createAction('[Export builder] Export each doc as single row', (excelSingleRow: boolean) => ({ excelSingleRow }));
