import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { StoreComponent } from '../../../../core/util/store-component';
import { selectSelectedDate } from '../../../../store/selectors/contract-calendar.selectors';
import { formatDate } from '@angular/common';
import { SelectedDate } from '../../../../store/actions/contract-calendar.actions';

@Component({
    selector: 'cc-date-header',
    templateUrl: './date-header.component.html',
    styleUrls: ['./date-header.component.scss'],
})
export class DateHeaderComponent extends StoreComponent implements OnInit {

    monthAndYear = '';
    selectedDate: Date;

    constructor(protected store: Store<AppState>) {
        super(store);
    }

    ngOnInit() {
        super.ngOnInit();
        this.select(selectSelectedDate, (date: Date) => {
            this.selectedDate = date;
            this.monthAndYear = formatDate(date, 'MMMM yyyy', 'en');
        });
    }

    leftClick() {
        const date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() - 1, 1);
        this.store.dispatch(new SelectedDate(date));
    }

    rightClick() {
        const date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 1);
        this.store.dispatch(new SelectedDate(date));
    }
}
