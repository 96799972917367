import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { StoreComponent } from '../../../../core/util/store-component';
import { selectDiaryEvents } from '../../../../store/selectors/contract-calendar.selectors';
import { CalendarEvent, DiaryDay } from '../../../../core/models/contractCalendar.model';
import { formatDate } from '@angular/common';

@Component({
    selector: 'cc-diary',
    templateUrl: './diary.component.html',
    styleUrls: ['./diary.component.scss'],
})
export class DiaryComponent extends StoreComponent implements OnInit {
    diaryDates: DiaryDay[];

    constructor(protected store: Store<AppState>,) {
        super(store);
        this.select(selectDiaryEvents, events => this.diaryDates = this.transformEvents(events));
    }

    ngOnInit() {
        super.ngOnInit();
    }

    toDayName(date: Date) {
        return formatDate(date, 'EEE', 'en');
    }

    toDayNumber(date: Date) {
        return formatDate(date, 'dd', 'en');
    }

    private transformEvents(events: CalendarEvent[]): DiaryDay[] {
        const result = {};
        // group ids
        events.forEach(event => {
            const formattedDate = formatDate(event.eventDate, 'dd-MM-yyyy', 'en');
            const diaryDay = result[formattedDate];

            if (!result[formattedDate]) {
                result[formattedDate] = {
                    date: event.eventDate,
                    documents: []
                };
            }
            const index = result[formattedDate].documents.findIndex(d => d.documentId === event.documentId);

            if (index === -1) {
                result[formattedDate].documents.push({
                    clauses: [{
                        id: event.clause.clauseId,
                        name: event.clause.displayName,
                        clauseResult: event.clause.clauseResult,
                    }],
                    id: event.id,
                    eventType: event.eventType,
                    noticePeriod: event.noticePeriod,
                    noticeDate: event.noticeDate,
                    parties: event.parties,
                    documentId: event.documentId,
                    resultId: event.resultId,
                    documentName: event.documentName,
                    tenantTemplateName: event.tenantTemplateName,
                    clientId: event.clientId,
                    matterId: event.matterId
                });
            } else {
                result[formattedDate].documents[index].clauses.push({
                    id: event.clause.clauseId,
                    name: event.clause.displayName,
                    clauseResult: event.clause.clauseResult,
                });
            }
        });

        return Object.values(result);
    }
}
