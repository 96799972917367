import { createSelector } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { ContractCalendarState } from '../state/contract-calendar.state';

const contractCalendar = (state: AppState) => state.contractCalendar;

export const calendarOpen = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.isCalendarOpen;
});

export const selectDiaryEvents = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.calendarEvents;
});

export const selectSelectedDate = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.selectedDate;
});

export const selectSelectedCalendarClause = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.selectedCalendarClause;
});

export const selectShowAddEventControls = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.showAddEventControls;
});

export const terminationDialogVisible = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.isTerminationDialogVisible;
});

export const eventDialogVisible = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.isEventDialogVisible;
});

export const selectClauseEvents = createSelector(contractCalendar, (state: ContractCalendarState) => {
  return state.clauseEvents;
});
