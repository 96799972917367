<div [@fadeInAnimation] class="calendarEvent">

    <div *appFeatureFlag="'DownloadCalendar'" class="calendarEvent__download" (click)="downloadCalendarEvent(event)"
        (mouseenter)="downloadActive = true" (mouseleave)="downloadActive = false">

        <div [@fadeInAnimation] *ngIf="downloadActive === false" class="calendarEvent__download--passive">
            <img src="./assets/icons/download.svg" [ngClass]="{'fade-block': downloadActive === false}" />
        </div>

        <div [@fadeInAnimation] *ngIf="downloadActive === true" class="calendarEvent__download--active">
            <div [ngClass]="{'fade-block': downloadActive === true}">
                <img src="./assets/icons/download-active.svg" />
                Download Calendar Event
            </div>
        </div>

    </div>

    <div class="calendarEvent__details">
        <p (click)="goToDocument()" class="calendarEvent__documentName clickable">{{event.documentName}}</p>
        <p class="calendarEvent__parties">{{event.partyString}}</p>
        <p class="calendarEvent__templateName">{{event.tenantTemplateName}}</p>

        <app-pill *ngIf="event.eventType.id === 1 && event.noticePeriod > 0" [displayOnly]="true"
            label="{{event.noticePeriod}} Days Notice Period Served by {{event.noticeDate | smzdate :'d MMM y'}}"
            [type]="PillType.Error">
        </app-pill>

        <div class="calendarEvent__clauseName" (contextmenu)="onRightClick($event, event.id, event.documentId)"
            *ngFor="let clause of event.clauses">
            <span *ngIf="clause.clauseResult !== clauseResult">{{clause.clauseResult}}</span>
            <span *ngIf="clause.clauseResult == clauseResult">No termination clause found.</span>
            <div *ngIf="canDeleteCalEvent === true" class="remove">
                <div class="icon icon-bin clickable" (click)="removeClauseEvent(event.id, event.documentId)"></div>
            </div>
        </div>

    </div>
</div>
