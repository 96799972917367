import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { EventService, ShowContract } from '@summize/shared/framework';

import { StoreComponent } from '../../../../core/util/store-component';
import { UserService } from './../../../../core/services/user.service';
import { AppState } from '../../../../store/state/app.state';
import { DocumentDay, ClauseEvent } from '../../../../core/models/contractCalendar.model';
import { selectSelectedCalendarClause } from '../../../../store/selectors/contract-calendar.selectors';
import { OpenContextMenu } from '../../../../store/actions/current-page.action';
import { CalendarService } from '../../../../core/services/calendar.service';
import { SelectedCalendarClause } from '../../../../store/actions/contract-calendar.actions';
import { FadeInAnimation } from '../../../../core/animations/fade-in.animation';
import { PillType } from '../../../my-contracts/components/pill/pill.component';

@Component({
  selector: 'cc-calendar-event',
  templateUrl: './calendarEvent.component.html',
  styleUrls: ['./calendarEvent.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class CalendarEventComponent extends StoreComponent implements OnInit {

  public readonly DownloadFeatureFlag: string = 'DownloadCalendar'

  public PillType = PillType;

  @Input()
  public event: DocumentDay & { partyString: string };

  public downloadActive: boolean = false;

  public clauseResult: string = "";

  public canDeleteCalEvent: boolean = false;

  private selectedDocumentDayId: string;

  private matterId: string;

  private clientId: string;

  constructor(protected store: Store<AppState>,
    private calendarService: CalendarService,
    private userService: UserService,
    private events: EventService) {

    super(store);

  }

  public ngOnInit() {

    super.ngOnInit();

    this.select(selectSelectedCalendarClause, event => {
      if (event) {
        this.selectedDocumentDayId = event.documentDayId;
      }
    });

    this.event.partyString = `Between ${this.event.parties?.map(p => p.party).join(' and ')}`;

    this.canDeleteCalEvent = this.userService.hasClaims(['CanCalendarEventsDelete']);

  }

  public onClauseClicked(event: any, clause: ClauseEvent) {

    event.stopPropagation();
    const bounds = event.srcElement.getBoundingClientRect();
    const position = {
      x: bounds.right - 4,
      y: bounds.y - 10
    };
    this.store.dispatch(new SelectedCalendarClause(this.event.id, clause.clauseId, position, clause.clauseResult, this.event.documentId, this.event.resultId));

  }

  public deleteClause(eventId: string, documentId?: string) {

    documentId = documentId ?? this.selectedDocumentDayId;
    if (!documentId) {
      console.warn('Document ID not set');
      return;
    }
    this.calendarService.deleteEvent(this.clientId, this.matterId, documentId, eventId);

  }

  public onRightClick(event: MouseEvent, eventId: string, documentId: string) {

    event.preventDefault();
    event.stopPropagation();

    if (this.userService.hasClaims(['CanCalendarEventsDelete'])) {
      const menuItems = [
        {
          name: 'Delete',
          event: () => this.deleteClause(eventId, documentId),
        },
      ];
      this.store.dispatch(new OpenContextMenu(menuItems, event.clientX, event.clientY));
    }

  }

  public removeClauseEvent(id: string, docId: string): void {

    this.deleteClause(id, docId);

  }

  public async downloadCalendarEvent(event: { id: string }): Promise<void> {

    const data = await this.calendarService.downloadCalendarEvent(event.id);

    const downloadURL = window.URL.createObjectURL(data);

    const link = document.createElement('a');

    link.href = downloadURL;

    link.download = `${event.id}.ics`;

    link.click();

  }

  public goToDocument(): void {

    this.events.despatch(ShowContract, {
      clientId: this.event.clientId,
      matterId: this.event.matterId,
      documentId: this.event.documentId
    });

  }

}
