import { OnInit, OnDestroy, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';


@Injectable()
export abstract class StoreComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(protected store: Store<AppState>) {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
  }

  protected select(selector: any, func): Subscription {
    const sub = this.store.select(selector).subscribe(func);
    this.addSub(sub);
    return sub;
  }

  protected addSub(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }
}
