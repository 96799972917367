import { Action } from '@ngrx/store';
import { User } from '../../core';

export enum ECommonActions {
  Reset = '[Common] Reset',
  ResetUser = '[Common] Reset user',
  TriggerToast = '[Common] Trigger Toast',
  ResetTriggerToast = '[Common] Reset Trigger Toast',
  SetUser = '[Common] Set user',
  SetTenantLogo = '[Common] Set tenant logo',
  SetFeedbackPanel = '[Common] Set feedback'
}

export class Reset implements Action {
  public readonly type = ECommonActions.Reset;

  Reset() { }
}

export class ResetUser implements Action {
  public readonly type = ECommonActions.ResetUser;

  ResetUser() { }
}

export class TriggerToast implements Action {
  public readonly type = ECommonActions.TriggerToast;

  constructor(public message: string, public icon: string) { }
}

export class ResetTriggerToast implements Action {
  public readonly type = ECommonActions.ResetTriggerToast;
}

export class SetUser implements Action {
  public readonly type = ECommonActions.SetUser;

  constructor(public user: User) { }
}

export class SetTenantLogo implements Action {
  public readonly type = ECommonActions.SetTenantLogo;
  constructor(public logoUrl: String) { }
}

export class SetFeedback implements Action {
  public readonly type = ECommonActions.SetFeedbackPanel;
  constructor(public open: boolean) { }
}

export type CommonActions = Reset | ResetUser | TriggerToast | ResetTriggerToast | SetUser | SetTenantLogo | SetFeedback;
