import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';

import { UserService } from '../services/user.service';

@Directive({
    selector: '[appClaimsHide]'
})
export class ClaimsHideDirective {

    claims: string[] = [];
    claimsAction: string;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userService: UserService,
    ) { }

    updateView() {

        if (this.claims) {

            const hasClaims = this.userService.hasClaims(this.claims);

            if (hasClaims === true) {

                this.viewContainer.createEmbeddedView(this.templateRef);

            } else {

                this.viewContainer.clear();

            }
        }
        
    }

    @Input() set appClaimsHide(claims: string[]) {
        this.claims = claims;
        this.updateView();
    }
}