<div class="diary column">
    <div class="row" *ngFor="let date of diaryDates">
        <div class="diary__date">
            <p class="diary__dayName">{{ toDayName(date.date) }}</p>
            <p class="diary__dateNumber">{{toDayNumber(date.date)}}</p>
        </div>
        <div class="diary__details">
            <cc-calendar-event *ngFor="let event of date.documents" [event]="event">
            </cc-calendar-event>
        </div>
    </div>
</div>