import { UserService } from './../../services/user.service';
import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import * as introJs from 'intro.js/intro.js';

import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';

export class IntroStartOptions {
  force?: boolean = false;
  initialDelay?: number = 2000;
  actionDelay?: number = 100;
}

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit, OnDestroy {
  onExit = new EventEmitter();
  onNextStep = new EventEmitter();

  private defaultOptions: IntroStartOptions;
  private introJs = introJs();
  private key: string = null;
  isMandatory: boolean = false;

  constructor(
    private userService: UserService) {

    this.introJs.setOptions({
      overlayOpacity: 0.2,
      nextLabel: 'Next',
      prevLabel: 'Back',
      hidePrev: true,
      showStepNumbers: false,
    });

    this.defaultOptions = new IntroStartOptions();

    this.introJs.onchange((element) => this.change(element));
    this.introJs.oncomplete(() => this.exit());
    this.introJs.onexit(() => this.exit());
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // Don't pass the 'key' so that this isn't saved as 'completed'.
    this.stop();
  }

  private getFQKN(key: string) {
    return `INTRO-${key}`;
  }

  setIntroOptions(options: any) {
    this.introJs.setOptions(options);
  }

  addSteps(steps: any[]) {
    this.introJs.addSteps(steps);
    this.introJs.refresh();
  }

  setSteps(steps: any[]) {
    this.introJs.setOptions({ steps });
    this.introJs.refresh();
  }

  mandatoryValidator() {
    this.isMandatory = this.userService.getIsMandatoryIntro();
    if (this.isMandatory) {
      this.setIntroOptions({ exitOnEsc: false, exitOnOverlayClick: false });

    }

    // We need to adjust the styles in IntroJS as we don't want to edit their lib to hideSkip for 'mandatory' walkthroughs.
    // This _may_ be possbile with basic CSS, but couldn't find out how. So just dynamcally add/remove the style based on
    // the mandatory value.
    const styleId = 'introjs-mandatory-override';
    var styleEl = document.getElementById(styleId);

    if (!styleEl && this.isMandatory) {
      const styleEl = document.createElement('style');
      styleEl.setAttribute('id', styleId);
      styleEl.innerText = ".introjs-skipbutton { visibility: hidden } .introjs-skipbutton.introjs-donebutton { visibility: unset }"
      document.head.appendChild(styleEl);
    } else if (styleEl && !this.isMandatory) {
      styleEl.remove();
    }
  }

  start(key: string, steps: any[], startOptions?: IntroStartOptions) {

    // Steo: Quick and dirty -- not sure on the future of introjs
    return;

    var options: IntroStartOptions = startOptions ? _mergeOptions(this.defaultOptions, startOptions) : this.defaultOptions;

    var isActive = !!this.key;

    if (isActive) {
      this.stop();
    }

    this.setSteps(steps);

    this.key = key;

    this.mandatoryValidator();

    if (options.force || !this.userService.localStorageService.getItem(this.getFQKN(this.key))) {
      setTimeout(() => {
        this.introJs.start();
      }, options.initialDelay);
    }
  }

  exit() {
    if (this.key) {
      this.userService.localStorageService.setItem(this.getFQKN(this.key), 'true');
    }
    this.onExit.emit();
    this.key = null;
  }

  isActive(key?: string) {
    if (key) {
      return key == this.key;
    } else {
      return !!this.key;
    }
  }

  refresh(key?: string) {
    if (key && key != this.key) {
      return;
    }

    setTimeout(() => this.introJs.refresh(), this.defaultOptions.actionDelay);
  }

  nextStep(key?: string) {
    if (key && key != this.key) {
      return;
    }

    setTimeout(() => this.introJs.nextStep(), this.defaultOptions.actionDelay);
  }

  setStep(step: number, key?: string) {
    if (key && key != this.key) {
      return;
    }

    setTimeout(() => this.introJs.goToStep(step), this.defaultOptions.actionDelay);
  }

  stop(key?: string) {
    if (key && key != this.key) {
      return;
    }

    this.introJs.exit();
  }

  change(element: any) {
    this.onNextStep.emit();
  }
}

function _mergeOptions(obj1, obj2) {
  var obj3 = {},
    attrname;
  for (attrname in obj1) { obj3[attrname] = obj1[attrname]; }
  for (attrname in obj2) { obj3[attrname] = obj2[attrname]; }
  return obj3;
}