import { UserService } from './../../core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { AppState } from '../../store/state/app.state';
import { selectCurrentPage } from '../../store/selectors/current-page.selectors';
import { CurrentPage } from '../../store/state/page.state';
import { StoreComponent } from '../../core/util/store-component';
import { selectUser } from '../../store/selectors/common.selectors';
import { selectHasExpired } from '../../store/selectors/account.selectors';

@Component({
  selector: 'app-contract-calendar',
  templateUrl: './contract-calendar.component.html',
})
export class ContractCalendarComponent extends StoreComponent implements OnInit {
  showContractCalendar = true;

  constructor(
    protected store: Store<AppState>,
    private userService: UserService
  ) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
    combineLatest([this.store.select(selectCurrentPage), this.store.select(selectUser), this.store.select(selectHasExpired)]).subscribe(
      ([currentPage, user, hasExpired]) => {
        if (!user ||
          user.hasExpired ||
          hasExpired ||
          currentPage === CurrentPage.ContractReviewConfirmParties ||
          !user.hasCompletedRegistration ||
          !this.userService.hasClaims(['CanCalendarEventsRead'])) {
          this.showContractCalendar = false;
        } else {
          this.showContractCalendar = true;
        }
      }
    );
  }

  catchClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
}
