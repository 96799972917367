import { NgModuleRef } from "@angular/core";

import { EventService } from "@summize/shared/framework";
import { isSummizeDomain } from "@summize/shared/core";
import { PlatformBridgeEvent } from "@summize/shared/components-v2/platform/platform.types";

import { AppModule } from "../app.module";

declare const Office: any;

export class OutlookBridge {

    private events: EventService;

    constructor(private app: NgModuleRef<AppModule>, onCompleteCallback: () => void) {

        this.loadOffice(() => {

            delete history.pushState;

            delete history.replaceState;

            Office.onReady().then(() => {

                (<any>window).isOfficeClient = true;

                onCompleteCallback();

                Office.context.ui.messageParent('ready', { targetOrigin: "*" });

                this.events = this.app.injector.get(EventService);

                Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, (event) => {

                    if (isSummizeDomain(event.origin) === true) {

                        if (event.message !== undefined && event.message.includes !== undefined) {

                            const payload = JSON.parse(event.message);

                            this.events.despatch(PlatformBridgeEvent, payload);

                        }

                    }

                });

            });

        });
    }

    private loadOffice(complete: any) {

        const script = document.createElement('script');

        script.src = 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js';

        script.onload = function () {

            complete();
        };

        document.head.appendChild(script);

    }
}