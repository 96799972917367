import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { RuleService } from '../services/rule.service';

@Injectable()
export class ContractResolver implements Resolve<any> {
  constructor(private ruleService: RuleService, ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const documentId: string = route.paramMap.get('id');
    if (documentId) {
      this.ruleService.requestContractRules(documentId);
    }
  }
}
