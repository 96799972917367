import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, UrlTree } from '@angular/router';

import { FadeInAnimation, FeedbackPanelComponent, SlideUpAnimation } from '@summize/shared/components';
import { SlidePanelService, SlidePanelSettings, UserPanelComponent, CustomerHubPanelComponent, PlatformService } from '@summize/shared/components-v2';
import { ChangeDetectionHelper, GlobalContextService, HEADER_HEIGHT, LayoutHelper, EMPTY_GUID, UserTypeEnum, AppComponent } from '@summize/shared/core';
import { PlatformBridgeEvent } from '@summize/shared/components-v2/platform/platform.types';
import {
    EventService,
    ExpandUserPanel,
    PreviewFeature,
    PreviewFeatureService,
    SetContext,
    ShowContract,
    ShowFeedback,
    ShowGlobalSearchResult,
    SummizeStorage,
    UserService,
    WebsocketEventTypes,
    WebsocketMessage,
    WebsocketPayload,
    WebsocketService
} from '@summize/shared/framework';
import { filter } from 'rxjs/operators';

import { ContractCalendarComponent } from '../../../modules/contract-calendar/contract-calendar.component';
import { ClientMatter } from '../../models/privatePractice.model';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { MessageBrokerService } from '../../services/message-broker.service';
import { CalendarSyncHelper } from '@summize/feature/calendar-v2';

@Component({
    selector: 'app-shell',
    templateUrl: 'app-shell.html',
    styleUrls: ['./app-shell.scss'],
    animations: [
        FadeInAnimation,
        SlideUpAnimation
    ]
})

export class AppShellComponent extends AppComponent implements OnInit, OnDestroy {

    public user: any;

    public showBreadcrumb: boolean;

    public logoUrl: string;

    public get isReady() {

        // eLFD inbound routes already have everything needed
        // so dont wait.
        if (window.location.pathname.includes('remote') || window.location.pathname.includes('quick-summary/external')) {

            return true;

        }

        return this.user !== undefined && this.user !== null;

    }

    public isFullScreen = false;

    private readonly isCustomerHubEnabled: string = 'CustomerHub';

    private readonly isMessageBrokerEnabled: string = 'PendingFileSignalR';

    private readonly documentProcessorGroup: string = 'DocumentProcessor';

    private readonly eSignatureStatusUpdatesGroup: string = 'ESignatureStatusUpdates';

    private activeMatter: ClientMatter;

    private initialized: boolean;

    private initializedUserWebSocketEvents = false;

    private socket: WebsocketService;

    private hasSessionRefreshed = false;

    constructor(
        private featureFlagService: FeatureFlagService,
        private messageBrokerService: MessageBrokerService,
        private globalContext: GlobalContextService,
        private eventService: EventService,
        private router: Router,
        private userService: UserService,
        private slidePanelService: SlidePanelService,
        private context: GlobalContextService,
        private preview: PreviewFeatureService,
        private platform: PlatformService,
        private calSyncHelper: CalendarSyncHelper) {

        super();

    }

    public ngOnInit(): void {

        this.socket = new WebsocketService();

        this.setupEvents();

        this.eventService.when(EventService.GlobalEvents.SetUser).subscribe(async event => {

            if (this.hasSessionRefreshed === false) {

                // Set this immediately, if it fails we let the user know rather than
                // crashing the app
                this.hasSessionRefreshed = true;

                try {

                    event.context = await this.userService.refreshSession(false);


                } catch (error) {

                    console.log(`Summize: Failed to refresh session`);

                }

            }

            this.user = event.context;

            if (this.logoUrl === undefined && this.user !== undefined) {

                this.logoUrl = this.user?.companyLogoUrl;
            }

            if (this.user !== undefined && this.user.userId?.length > 0 && this.initializedUserWebSocketEvents === false) {

                this.initializedUserWebSocketEvents = true;

                await this.handleUserWebSocketEvents(this.user.userId);

            }

        });

        this.context.$change.subscribe(async val => {

            const c = { clientId: val.clientId, matterId: val.matterId } as any;

            await this.init(c);

        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {

                // Always clear client/matter context before navigation
                // GlobalSearch will set the context for Insights + Search
                // Other components will use query or url params

                this.globalContext.clear('clientId');

                this.globalContext.clear('matterId');

                document.title = 'Summize';

                const tree: UrlTree = this.router.parseUrl(event.url);

                this.isFullScreen =
                    tree.queryParams.fullscreen === 'true' ||
                    tree.queryParams.fullscreen === true ||
                    tree.queryParams.wordeditor === 'true' ||
                    tree.queryParams.wordeditor === true ||
                    SummizeStorage.IsHosted()

            });

        this.eventService
            .when(PlatformBridgeEvent)
            .subscribe(event => this.platform.handleEvent(event));
    }

    public ngOnDestroy(): void {

        this.ngOnDestroy();

    }

    public showFrame(): boolean {

        if (this.isFullScreen === true) {

            return false;

        }

        return this.router.url !== '/' &&
            this.router.url !== '/authenticated' &&
            this.router.url !== '/login' &&
            this.router.url !== '/login-oidc' &&
            this.router.url !== '/signin-oidc';

    }


    public get showSideMenu(): boolean {

        if (this.user?.userType === UserTypeEnum.Requestor) {

            return false;

        }

        return this.showFrame() === true;
    }

    private setupEvents(): void {

        this.eventService.when(EventService.GlobalEvents.LaunchCalendarSync).subscribe(() => {

            this.calSyncHelper.launch();

        });

        this.eventService.when(ExpandUserPanel).subscribe(event => {

            const settings: SlidePanelSettings = {
                component: UserPanelComponent,
                arguments: {
                    selectedTab: event.context.tab,
                    user: this.user,
                    calendarComponent: ContractCalendarComponent
                },
                title: undefined,
                closeable: true,
                size: 'lg',
                backdrop: true,
                managed: false
            };

            this.slidePanelService.show(settings);

        });

        this.eventService.when(ShowFeedback).subscribe((event: any) => {

            const helpComponent = this.featureFlagService.get(this.isCustomerHubEnabled) ? CustomerHubPanelComponent : FeedbackPanelComponent;

            const settings: SlidePanelSettings = {
                component: helpComponent,
                arguments: {},
                title: undefined,
                closeable: true,
                size: 'lg',
                backdrop: true,
                managed: false,
                onBackdrop: () => { },
                init: (instance: any) => {

                    if (event.context !== undefined && event.context.selectedType !== undefined) {

                        instance.model.type = event.context.selectedType;

                    }

                }
            };

            this.slidePanelService.show(settings);

        });

        this.eventService.when(ShowGlobalSearchResult).subscribe((event) => {

            const params: any = {
                name: event.context.facetName,
                query: event.context.query
            }

            if (event.context.clientId !== undefined && event.context.clientId !== null) {

                params.clientId = event.context.clientId

                if (event.context.matterId !== undefined && event.context.matterId !== null) {

                    params.matterId = event.context.matterId;

                }
            }


            if (event.context.clientId === undefined || event.context.clientId === null) {

                params.clientId = EMPTY_GUID;

            }

            if (event.context.matterId === undefined || event.context.matterId === null) {

                params.matterId = EMPTY_GUID;

            }


            if (event.context.source !== undefined) {

                params.source = event.context.source;

            }

            const queryParams = event.context.queryParams !== undefined ? event.context.queryParams() : undefined;

            if (this.preview.hasPreviewFeatureEnabled(PreviewFeature.Contracts)
                && event.context.source === 'Manage'
                && (event.context.facetName === 1 || event.context.facetName === 2)) {

                const base = `/contracts/repository?client=${params.clientId}&matter=${params.matterId}&`;
                const queryEncoded = encodeURIComponent(params.query);

                if (event.context.facetName === 1) {

                    return this.router.navigateByUrl(`${base}query=${queryEncoded}`)

                }

                if (event.context.facetName === 2) {

                    return this.router.navigateByUrl(`${base}party=${queryEncoded}`)

                }

            } else {

                this.router.navigate([`/search/${event.context.type}`, ...Object.values(params)], {
                    queryParams: {
                        hash: new Date().getSeconds(),
                        ...queryParams
                    }
                });

            }

        });

        this.eventService.when(ShowContract).subscribe((event) => {

            const { clientId, matterId, documentId } = event.context;

            const route = `/my-contracts/clients/${clientId}/matters/${matterId}/review/${documentId}`;

            this.router.navigate([route]);

        });

        this.eventService.when(SetContext).subscribe((event) => {

            this.globalContext.set(event.context);

        });

    }

    private async init(clientMatter: ClientMatter) {

        this.globalContext.set({ selectedMatter: clientMatter }, true);

        if (clientMatter === undefined || clientMatter === null) {

            return;

        }

        if (this.featureFlagService.get(this.isMessageBrokerEnabled) === false) {

            return;

        }

        if (this.featureFlagService.get('SendForSignature') === true) {

            await this.messageBrokerService.joinGroup(this.eSignatureStatusUpdatesGroup,
                { clientId: '*', matterId: '*' });

        }

        // No context now or previously, do nothing.
        if (clientMatter.matterId === undefined && this.activeMatter === undefined) {

            return;

        }

        this.initialized = await this.initializeIfRequired();

        // App no longer has a matter context, disconnect from group.
        if (clientMatter.matterId === undefined && this.activeMatter !== undefined) {

            await this.messageBrokerService.leaveGroup(this.documentProcessorGroup,
                this.activeMatter);

            this.activeMatter = clientMatter;

        }

        // No previous context so can connect directly to new group
        if (clientMatter.matterId !== undefined && this.activeMatter === undefined) {

            await this.messageBrokerService.joinGroup(this.documentProcessorGroup,
                clientMatter);

            this.activeMatter = clientMatter;

        }

        // Had existing context, leave existing group and join new
        if (clientMatter.matterId !== undefined
            && this.activeMatter !== undefined
            && clientMatter.matterId !== this.activeMatter.matterId) {

            await Promise.all([
                this.messageBrokerService.leaveGroup(this.documentProcessorGroup,
                    this.activeMatter),
                this.messageBrokerService.joinGroup(this.documentProcessorGroup,
                    clientMatter)
            ]);

            this.activeMatter = clientMatter;

        }

    }

    private async initializeIfRequired(): Promise<boolean> {

        if (this.initialized === true) {

            return Promise.resolve(this.initialized);

        }

        await this.waitForConnection();

        this.setupHandlers();

        return Promise.resolve(true);

    }

    private waitForConnection(): Promise<boolean> {

        if (this.messageBrokerService.isConnectionEstablished === true) {

            return Promise.resolve(true);

        }

        this.messageBrokerService.init();

        return new Promise(resolve => {

            const sub = this.messageBrokerService.isConnected.subscribe(connected => {

                if (connected === true) {

                    resolve(true);

                    sub.unsubscribe();

                    return;
                }

            });

        });

    }

    private setupHandlers(): void {

        this.subscribe(this.messageBrokerService.all(), msg =>
            this.eventService.despatch(WebsocketMessage, msg)
        );

    }

    private async handleUserWebSocketEvents(userId: string): Promise<void> {

        const $onMessage = await this.socket.initConnection(true);

        await this.socket.joinGroup(`user:${userId}`, EMPTY_GUID, EMPTY_GUID, 0, () => {

            this.subscribe($onMessage, async (message: WebsocketPayload<any>) => {

                if (message.payload.userId === userId) {

                    if (message.type === WebsocketEventTypes.UserRefreshSession) {

                        await this.userService.refreshSession();

                        window.location.href = window.location.href;

                    } else if (message.type === WebsocketEventTypes.UserLogoutSession) {

                        window.location.href = '/logout';

                    }

                }

            });

        });

    }

}
