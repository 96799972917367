import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';
import { UserCalendarSyncModel } from '@summize/shared/framework';

export interface UserCalendarSyncSettings extends UserCalendarSyncModel {
    webcalUrl: string;
    httpsUrl: string;
}

@Injectable({ providedIn: 'root' })
export class CalendarSyncService extends BaseTenantService {

    constructor(
        private httpClient: HttpClient
        ) { super(); }

    public async getCalendarSyncSettings(): Promise<UserCalendarSyncSettings> {

        return this.httpClient.get<UserCalendarSyncSettings>(`${this.getUserBaseUrl()}1.0/calendarsync`).toPromise();

    }

    public async saveCalendarSyncSettings(userCalendarSyncModel: UserCalendarSyncModel): Promise<any> {

        return this.httpClient.post(`${this.getUserBaseUrl()}1.0/calendarsync`, userCalendarSyncModel).toPromise();

    }

}