<div cdkDrag *ngIf="visible" class="eventDialog column">
    <app-icon-button class="eventDialog__close" (click)="close()" icon="icon-close"></app-icon-button>
    <p class="eventDialog__title">Add an <strong>Event</strong> to calendar</p>
    <mat-form-field class="eventDialog__date">
        <input matInput [matDatepicker]="picker" placeholder="Event date" (dateInput)="onDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-select placeholder="Choose a clause" [(value)]="selectedClauseEvent">
          <mat-option *ngFor="let clauseEvent of clauseEvents" [value]="clauseEvent">
            {{ clauseEvent.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <div class="button button__flat" button (click)="add()">
        Add
    </div>
</div>