import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

import { StoreComponent } from '../../../../core/util/store-component';
import { AppState } from '../../../../store/state/app.state';
import { selectSelectedDate, selectDiaryEvents } from '../../../../store/selectors/contract-calendar.selectors';
import { SelectedDate } from '../../../../store/actions/contract-calendar.actions';
import { nameOfDay } from '../../../../core/util/dateHelper';
import { CalendarEvent } from '../../../../core/models/contractCalendar.model';

@Component({
  selector: 'cc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent extends StoreComponent implements OnInit {

  selectedDate: Date;
  days: any[] = [];

  constructor(protected store: Store<AppState>) {
    super(store);
    this.buildDaysArray();
  }

  ngOnInit() {
    super.ngOnInit();
    const sub = combineLatest(this.store.select(selectSelectedDate), this.store.select(selectDiaryEvents))
      .subscribe(([selectedDate, calendarEvents]) => {
        this.selectedDate = selectedDate;
        this.buildDaysArray();
        this.populateDates();
        this.updateDatesWithEventMarkers(calendarEvents);
      });
    this.addSub(sub);
  }

  private updateDatesWithEventMarkers(calendarEvents: CalendarEvent[]) {
    this.days = this.days.map(day => {
      const hasEvent = (day.date && calendarEvents.filter(event => new Date(event.eventDateUtc).getDate() === day.dateOfMonth).length > 0);
      return { ...day, hasEvent };
    });
  }

  private populateDates() {
    const year = this.selectedDate.getFullYear();
    const month = this.selectedDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let firstDayOfMonth = new Date(year, month, 1).getDay();
    if (firstDayOfMonth === 0) { firstDayOfMonth = 7; }

    for (let i = 0; i < daysInMonth; i++) {
      const day = this.days[i + firstDayOfMonth - 1];

      if (day) {
        day.dateOfMonth = i + 1;
        day.date = new Date(year, month, i + 1);
      }
    }

    const selectedDay = this.selectedDate.getDate() + firstDayOfMonth - 2;
    this.days[selectedDay].isSelected = true;
  }

  private buildDaysArray() {
    this.days = [];
    for (let weekNumber = 0; weekNumber < 6; weekNumber++) {
      for (let dayofWeek = 1; dayofWeek <= 7; dayofWeek++) { // offset to start on Monday
        const day = {
          number: dayofWeek,
          name: '',
          hasEvent: false,
          isSelected: false,
        };

        day.name = nameOfDay(dayofWeek);
        this.days.push(day);
      }
    }
  }
}
