<div *ngIf="!isCalendarOpen" class="ccButton__container" (mouseenter)="expanded = true" (mouseleave)="expanded = false">
  <div class="ccButton__floating" [class.ccButton__floating--background]="showAddEventControls && expanded">
    <div class="ccButton" (click)="openCalendar()">
      <div class="icon icon-calendar"></div>
    </div>
    <div *ngIf="showAddEventControls" class="ccButton__controls" [class.ccButton__controls--visible]="expanded">
      <div class="ccButton__addControls row">
        <div class="ccButton__addButton row" (click)="addTermination()">
          <div class="icon icon-add"></div>
          <span>Add termination to calendar</span>
        </div>
        <div class="ccButton__addButton row" (click)="addEvent()">
          <div class="icon icon-add"></div>
          <span>Add event to calendar</span>
        </div>
      </div>
    </div>
  </div>
</div>
