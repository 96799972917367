import { DocumentTypeState, initialDocumentTypeState } from '../state/document-type.state';
import { DocumentTypeActions, EDocumentTypeActions } from '../actions/document-type.actions';

export function documentTypesReducers(state: DocumentTypeState = initialDocumentTypeState, action: DocumentTypeActions): DocumentTypeState {
  switch (action.type) {
    case EDocumentTypeActions.SetDocumentTypes: {
      return {
        ...state,
        documentTypes: action.payload,
      };
    }
    case EDocumentTypeActions.ResetDocumentTypes: {
      return {
        ...state,
        documentTypes: null,
      };
    }
    case EDocumentTypeActions.SetSelectedDocumentType: {
      return {
        ...state,
        selectedDocumentType: action.payload,
      };
    }
    case EDocumentTypeActions.ResetSelectedDocumentType: {
      return {
        ...state,
        selectedDocumentType: null,
      };
    }

    default:
      return state;
  }
}
