import { Action } from '@ngrx/store';

import { Clause, ClauseResult, Comment, ContractClausesResponse } from '../../core';
import { RelatedPara } from '../../core/models/relatedPara.model';
import { PlaybookPreview } from '../../core/models/playbook-preview.model';
import { DocumentAssigned } from './../../core/models/assignedto.model';

export enum EContractReviewActions {
  ResetAll = '[ContractReview] Reset Contract Review',
  SetRelatedParagraphs = '[ContractReview] Set Related Paragraphs',
  SetRelatedParagraphId = '[ContractReview] Set Related Paragraph ID',
  SetSelectedClause = '[ContractReview] Set Selected Clause',
  SetSelectedClauseName = '[ContractReview] Set Selected Clause Name',
  LoadClauses = '[ContractReview] Load Clauses',
  ResetSelectedClause = '[ContractReview] Reset Selected Clause Name',
  SetSelectedClauseResult = '[ContractReview] Set Selected Clause Result',
  AddClauseResult = '[ContractReview] Add Clause Result',
  UpdateClauseResultLoadingStatus = '[ContractReview] Update clause result loading status',
  UpdateCommentResultLoadingStatus = '[ContractReview] Update comment result loading status',
  LoadComments = '[ContractReview] Load Comments',
  SetSelectedClauseFlag = '[ContractReview] Set a selected clause flag status',
  UpdateClauseResultText = '[ContractReview] Update clause result text',
  UpdateComment = '[ContractReview] Update comment',
  ShowPlaybookSummary = '[ContractReview] Show playbook summary',
  HidePlaybookSummary = '[ContractReview] Hide playbook summary',
  SetSelectedDocumentIsShared = '[ContractReview] Set selected document is shared',
  SetSelectedDocumentId = '[ContractReview] Set selected document id',
  ExpandAllClauses = '[ContractReview] Expand all clauses',
  CollapseAllClauses = '[ContractReview] Collapse all clauses',
  SetDocumentAssignedTo = '[ContractReview] Set Document Assigned To',
  SetTemplateTypeId = '[ContractReview] Set template id'
}

export class LoadClauses implements Action {
  public readonly type = EContractReviewActions.LoadClauses;

  constructor(public payload: ContractClausesResponse) { }
}

export class SetSelectedClause implements Action {
  public readonly type = EContractReviewActions.SetSelectedClause;

  constructor(public clause: Clause) { }
}

export class SetSelectedClauseFlag implements Action {
  public readonly type = EContractReviewActions.SetSelectedClauseFlag;

  constructor(public clause: Clause) { }
}

export class UpdateClauseResultText implements Action {
  public readonly type = EContractReviewActions.UpdateClauseResultText;

  constructor(public clause: Clause, public resultIndex: number, public text: string) { }
}

export class SetSelectedClauseResult implements Action {
  public readonly type = EContractReviewActions.SetSelectedClauseResult;

  constructor(public clauseResult: ClauseResult) { }
}

export class AddClauseResult implements Action {
  public readonly type = EContractReviewActions.AddClauseResult;

  constructor(public clauseName: string, public clauseResultParaId: string, public clauseResultText: string, public loadingStatus: string) { }
}

export class UpdateClauseResultLoadingStatus implements Action {
  public readonly type = EContractReviewActions.UpdateClauseResultLoadingStatus;

  constructor(public clauseName: string, public ruleIndex: number, public loadingStatus: string) { }
}

export class SetRelatedParagraphs implements Action {
  public readonly type = EContractReviewActions.SetRelatedParagraphs;

  constructor(public relatedParas: Array<RelatedPara>) { }
}

export class SetRelatedParagraphId implements Action {
  public readonly type = EContractReviewActions.SetRelatedParagraphId;

  constructor(public relatedParaId: number) { }
}

export class SelectSelectedClauseName implements Action {
  public readonly type = EContractReviewActions.SetSelectedClauseName;

  constructor(public clauseName: string) { }
}

export class ResetSelectedClause implements Action {
  public readonly type = EContractReviewActions.ResetSelectedClause;
}

export class ResetAll implements Action {
  public readonly type = EContractReviewActions.ResetAll;
}

export class UpdateCommentResultLoadingStatus implements Action {
  public readonly type = EContractReviewActions.UpdateCommentResultLoadingStatus;

  constructor(public clauseTypeName: string, public templateId: string, public loadingStatus: string) { }
}

export class LoadComments implements Action {
  public readonly type = EContractReviewActions.LoadComments;

  constructor(public payload: Comment) { }
}

export class UpdateComment implements Action {
  public readonly type = EContractReviewActions.UpdateComment;

  constructor(public clauseId: number, public comments: Comment[]) { }
}

export class ShowPlaybookSummary implements Action {
  public readonly type = EContractReviewActions.ShowPlaybookSummary;

  constructor(public playbookPreview: PlaybookPreview) { }
}

export class HidePlaybookSummary implements Action {
  public readonly type = EContractReviewActions.HidePlaybookSummary;
}

export class SetSelectedDocumentIsShared implements Action {
  public readonly type = EContractReviewActions.SetSelectedDocumentIsShared;

  constructor(public isShared: boolean) { }
}

export class SetSelectedDocumentId implements Action {
  public readonly type = EContractReviewActions.SetSelectedDocumentId;

  constructor(public documentId: string) { }
}

export class ExpandAllClauses implements Action {

  public readonly type = EContractReviewActions.ExpandAllClauses;

  constructor() { }

}

export class CollapseAllClauses implements Action {

  public readonly type = EContractReviewActions.CollapseAllClauses;

  constructor() { }

}

export class SetDocumentAssignedTo implements Action {

  public readonly type = EContractReviewActions.SetDocumentAssignedTo;

  constructor(public documentAssigned: DocumentAssigned) { }

}

export class SetTemplateTypeId implements Action {

  public readonly type = EContractReviewActions.SetTemplateTypeId;

  constructor(public templateId: string) { }

}

export type ContractReviewActions =
  | ShowPlaybookSummary
  | HidePlaybookSummary
  | LoadClauses
  | SetSelectedClause
  | SetRelatedParagraphs
  | SetRelatedParagraphId
  | SelectSelectedClauseName
  | ResetSelectedClause
  | ResetAll
  | SetSelectedClauseResult
  | AddClauseResult
  | UpdateClauseResultLoadingStatus
  | UpdateCommentResultLoadingStatus
  | SetSelectedClauseFlag
  | UpdateClauseResultText
  | SetSelectedDocumentIsShared
  | SetSelectedDocumentId
  | UpdateComment
  | ExpandAllClauses
  | CollapseAllClauses
  | SetDocumentAssignedTo
  | SetTemplateTypeId;
