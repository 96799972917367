<ng-container>
    <button 
      class="button" 
      [class.button--primary]="type === 'primary'"
      [class.button--secondary]="type === 'secondary'"
      [class.button--tertiary]="type === 'tertiary'"
      [class.button--none]="type === 'none'"
      [class.button--small]="size=== 'small'"
      [class.button--medium]="size === 'medium'"
      [class.button--large]="size === 'large'"
      [class.button--disabled]="isDisabled"
      [class.button--primaryPurple]="type === 'primaryPurple'"
      [class.button--secondaryPurple]="type === 'secondaryPurple'"
      [class.button--tertiaryPurple]="type === 'tertiaryPurple'"
      [class.button--expand]="expand">
    <ng-content></ng-content>
  </button>
</ng-container>
  