import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NoAccessReason } from '@summize/shared/framework';

@Component({
    templateUrl: 'no-access.html',
    styleUrls: ['./no-access.scss']
})

export class NoAccessComponent {

    public noAccessReasonEnum = NoAccessReason;

    public reason: NoAccessReason;

    constructor(private router: Router,
        private route: ActivatedRoute) { }

    public ngOnInit() {

        this.route.queryParams.subscribe(params => {
            
            this.reason = params.reason;

        });

    }

    public signout(): void {

        this.router.navigateByUrl('/logout');

    }
}