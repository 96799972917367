import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ClmRouteGuard implements CanActivate {

    constructor(
        private router: Router) { }

    public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        // Check the old path and redirect to the new path

        const originalUrl = state.url.substring(1, state.url.length);

        const urlSegments = originalUrl.split('/');

        if (originalUrl.startsWith('create/contract/')) {

            await this.router.navigate(['contract', urlSegments[2]], { queryParams: { panelId: 'info' } });

        }

        if(originalUrl.startsWith('my-contracts/quick-summary/')){

            await this.router.navigate(['contract', urlSegments[2]], { queryParams: { panelId: 'info' } });

        }

        return Promise.resolve(true);

    }

}
