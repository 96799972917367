<div class="filedropzone" [class.active]="isActive">
    <div class="filedropzone__nopointerevents">
        <div class="dialog">
            <div class="filedropzone__dialog dialog">
                <img class="filedropzone__upload-image" src="/assets/images/upload-document.svg" alt="Upload contract">

                <div class="filedropzone__drop-box">
                    <p class="filedropzone__text">Drag and Drop</p>
                    <p class="filedropzone__text"><strong>{{ description }}</strong> here</p>
                    <p *ngIf="!isTrial" class="filedropzone__note">Note: Only PDF and DOCX files will be uploaded</p>
                    <p *ngIf="isTrial" class="filedropzone__note">Free trial users can only upload Word Doc (.docx) files</p>
                </div>
            </div>
        </div>
    </div>
</div>