import { ECommonActions, CommonActions } from '../actions/common.actions';
import { initialCommonState, CommonState } from '../state/common.state';

export function commonReducers(state: CommonState = initialCommonState, action: CommonActions): CommonState {
  switch (action.type) {
    case ECommonActions.Reset: {
      return initialCommonState;
    }
    case ECommonActions.TriggerToast: {
      return {
        ...state,
        toast: {
          icon: action.icon,
          message: action.message,
        },
      };
    }
    case ECommonActions.ResetTriggerToast: {
      return {
        ...state,
        toast: undefined,
      };
    }
    case ECommonActions.SetUser: {
      return {
        ...state,
        user: action.user,
      };
    }
    case ECommonActions.ResetUser: {
      return {
        ...state,
        user: undefined,
      };
    }
    case ECommonActions.SetTenantLogo: {
      return {
        ...state,
        tenantLogo: action.logoUrl,
      };
    }
    case ECommonActions.SetFeedbackPanel: {
      return {
        ...state,
        isFeedbackOpen: action.open,
      };
    }
    default:
      return state;
  }
}
