<button class="directional-button" 
    (click)="onIconClick($event)"
    [class.directional-button--disabled]="disabled"
    [class.directional-button--vertical]="up || down"
    [class.directional-button--horizontal]="left || right"
    [class.directional-button--square]="square"
    [class.directional-button--dark]="dark"
    [class.directional-button--small]="small">

  <div class="directional-button__icon" 
    [class.icon-up]="up"
    [class.icon-down]="down"
    [class.icon-next]="right"
    [class.icon-prev]="left">
  </div>
</button>