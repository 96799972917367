import { createSelector } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { ContractReviewState } from '../state/contract-review.state';

const contractReview = (state: AppState) => state.contractReview;

export const selectClauses = createSelector(contractReview, (state: ContractReviewState) => (state ? state.clauses : null));
export const selectDocumentId = createSelector(contractReview, (state: ContractReviewState) => (state ? state.documentId : null));
export const selectDocumentStatusId = createSelector(contractReview, (state: ContractReviewState) => state.documentStatusId);
export const selectDocumentStatusName = createSelector(contractReview, (state: ContractReviewState) => state.documentStatusId);
export const selectDocumentName = createSelector(contractReview, (state: ContractReviewState) => (state ? state.documentName : null));
export const selectTemplateId = createSelector(contractReview, (state: ContractReviewState) => (state ? state.templateId : null));
export const selectTenantTemplateId = createSelector(contractReview, (state: ContractReviewState) => (state ? state.tenantTemplateId : null));
export const selectSelectedClauseName = createSelector(contractReview, (state: ContractReviewState) => state.selectedClauseName);
export const selectRelatedParagraphs = createSelector(contractReview, (state: ContractReviewState) => state.relatedParagraphs);
export const selectedSelectedRelatedParagraphId = createSelector(contractReview, (state: ContractReviewState) => state.relatedParagraphId);
export const selectClauseResults = createSelector(contractReview, (state: ContractReviewState) => (state ? state.selectedClauseResult : null));
export const activePlaybookPreview = createSelector(contractReview, (state: ContractReviewState) => state.activePlaybookPreview);
export const selectDocumentIsShared = createSelector(contractReview, (state: ContractReviewState) => state.documentIsShared);
export const selectDocumentAssignedTo = createSelector(contractReview, (state: ContractReviewState) => {
  return {
    assignedTo: state.assignedTo,
    assignedToName: state.assignedToName,
    assignedBy: state.assignedBy,
    assignedByName: state.assignedByName,
    assignedAt: state.assignedAt
  }
});
export const CollapseState = createSelector(contractReview, (state: ContractReviewState) => state.expandAllClauses);


