import { Action } from '@ngrx/store';
import { CalendarEvent, DocumentDay, ClauseEvent } from '../../core/models/contractCalendar.model';

export enum EContractCalendarActions {
  ResetContractCalendar = '[ContractCalendar] Reset',
  OpenCalendar = '[ContractCalendar] Open Calendar',
  SelectedDate = '[ContractCalendar] Select Date',
  LoadCalendarEvents = '[ContractCalendar] Load Diary Events',
  SelectedCalendarClause = '[ContractCalendar] Selected Calendar Clause',
  ResetSelectedCalendarClause = '[ContractCalendar] Reset selected calendar clause',
  SetAddEventControlVisibility = '[ContractCalendar] Set add event control visibility',
  IsTerminationDialogVisible = '[ContractCalendar] Is termination dialog visible',
  IsEventDialogVisible = '[ContractCalendar] Is event dialog visible',
  SetClauseEvents = '[ContractCalendar] Set clause events',
}

export class ResetContractCalendar implements Action {
  public readonly type = EContractCalendarActions.ResetContractCalendar;
}

export class OpenCalendar implements Action {
  public readonly type = EContractCalendarActions.OpenCalendar;

  constructor(public isOpen: boolean) {}
}

export class SelectedDate implements Action {
  public readonly type = EContractCalendarActions.SelectedDate;

  constructor(public date: Date) {}
}

export class LoadCalendarEvents implements Action {
  public readonly type = EContractCalendarActions.LoadCalendarEvents;

  constructor(public events: CalendarEvent[]) {}
}

export class SelectedCalendarClause implements Action {
  public readonly type = EContractCalendarActions.SelectedCalendarClause;

  constructor(
    public documentDayId: string,
    public clauseId: number,
    public position: any,
    public fullClauseResult: string,
    public documentId: string,
    public resultId: number
  ) {}
}

export class ResetSelectedCalendarClause implements Action {
  public readonly type = EContractCalendarActions.ResetSelectedCalendarClause;
}

export class SetAddEventControlVisibility implements Action {
  public readonly type = EContractCalendarActions.SetAddEventControlVisibility;

  constructor(public visible: boolean) {}
}

export class IsTerminationDialogVisible implements Action {
  public readonly type = EContractCalendarActions.IsTerminationDialogVisible;

  constructor(public visible: boolean) {}
}

export class IsEventDialogVisible implements Action {
  public readonly type = EContractCalendarActions.IsEventDialogVisible;

  constructor(public visible: boolean) {}
}

export class SetClauseEvents implements Action {
  public readonly type = EContractCalendarActions.SetClauseEvents;

  constructor(public clauseEvents: ClauseEvent[]) {}
}

export type ContractCalendarActions =
  | IsTerminationDialogVisible
  | IsEventDialogVisible
  | ResetContractCalendar
  | OpenCalendar
  | SelectedDate
  | LoadCalendarEvents
  | SelectedCalendarClause
  | SetAddEventControlVisibility
  | SetClauseEvents
  | ResetSelectedCalendarClause;
