import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-direction-arrow-button',
  templateUrl: './direction-arrow-button.component.html',
  styleUrls: ['./direction-arrow-button.component.scss']
})
export class DirectionArrowButtonComponent implements OnInit {
  @Input('up') up: boolean;
  @Input('down') down: boolean;
  @Input('left') left: boolean;
  @Input('right') right: boolean;
  @Input('dark') dark: boolean;
  @Input('square') square: boolean;
  @Input('disabled') disabled: boolean;
  @Input('small') small: boolean;
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.up = this.up !== undefined && this.up !== false;
    this.down = this.down !== undefined && this.down !== false;
    this.left = this.left !== undefined && this.left !== false;
    this.right = this.right !== undefined && this.right !== false;
    this.square = this.square !== undefined && this.square !== false;
    this.dark = this.dark !== undefined && this.dark !== false;

    this.disabled = this.disabled !== undefined && this.disabled !== false;
    this.small = this.small !== undefined && this.small !== false;

    if (!this.up && !this.right && !this.left && !this.down) {
      throw new Error('Either up, down, left, or right must be selected in directional arrow button');
    }
  }

  onIconClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.click.emit(event);
  }

}
