import { Toast, User } from '../../core';

export interface CommonState {
  toast: Toast;
  user: User;
  tenantLogo: String;
  isFeedbackOpen: boolean;
}

export const initialCommonState: CommonState = {
  toast: undefined,
  user: undefined,
  tenantLogo: undefined,
  isFeedbackOpen: false
};
