<div *ngIf="$any(reason) !== 'ContractAccess'" class="no-access">
    <div class="primary">No Access</div>
    <div [ngSwitch]="reason">
        <div *ngSwitchCase="noAccessReasonEnum.ForbiddenResource" class="secondary">You do not have permission to
            access this resource.
        </div>
        <div *ngSwitchDefault class="secondary">Your account level does not allow you to access the Summize application.
        </div>
    </div>
    <div>
        <button (click)="signout()" class="button button__regular">Sign Out</button>
    </div>
</div>


<div *ngIf="$any(reason) === 'ContractAccess'" class="no-access">
    <div class="primary">You do not have permission to access this page</div>
    <div [ngSwitch]="reason">
        <div class="secondary">Please contact your administrator to request access
        </div>
    </div>