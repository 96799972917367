import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { v4 as uuidv4 } from 'uuid';

import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { ChatMessage } from '../../models/chatmessage.model';
import { MessageBrokerService } from '../../services/message-broker.service';
import { AppState } from "../../../store/state/app.state";
import { PendingAgreement, UploadFile, UploadStatus } from "../../models";
import {
  AddUploadingFile,
  LoadPendingAgreements,
  UpdateUploadingFile
} from "../../../store/actions/agreements.actions";
import { syncStoreGet } from "../../util/reduxUtils";
import { ToastService } from './../../services/toast.service';


@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent implements OnInit, OnDestroy {
  chatMessageSub: Subscription;
  chatConnectedSub: Subscription;
  isConnected = false;
  chatMessages: ChatMessage[] = [];
  debugEnabled: boolean = false;

  constructor(
    private featureFlagService: FeatureFlagService,
    private messageBrokerService: MessageBrokerService,
    private toastService: ToastService,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    this.debugEnabled = this.featureFlagService.get('DebugComponent');

    if (this.debugEnabled) {
      this.chatConnectedSub = this.messageBrokerService.isConnected.subscribe(isConnected => {
        this.isConnected = isConnected;

        if (this.isConnected) {
          this.messageBrokerService.joinGroup('documentProcessing', { clientId: '', matterId: '' });
        }
      });
      this.chatMessageSub = this.messageBrokerService.messageReceived.subscribe(chatMessage => {
        this.chatMessages.push(chatMessage);
      });
    }
  }

  ngOnDestroy() {
    if (this.chatConnectedSub) {
      this.chatConnectedSub.unsubscribe();
      this.chatConnectedSub = null;
    }

    if (this.chatMessageSub) {
      this.chatMessageSub.unsubscribe();
      this.chatMessageSub = null;
    }
  }

  addToast() {
    this.toastService.show('Toast: ' + new Date().toLocaleString(), 'icon-tick');
  }

  async runDownloadSimulation() {
    const clientId = ''
    const matterId = ''
    const templateId = syncStoreGet(this.store, state => state.documentTypes.selectedDocumentType.id);
    const tenantId = syncStoreGet(this.store, state => state.common.user.tenantName);
    const generatedDocumentId = uuidv4();
    if (!clientId || !matterId || !templateId) {
      console.error('clientId, matterId and templateId required', clientId, matterId, templateId);
      return
    }
    const uploadFile: UploadFile = {
      file: {
        lastModified: Date.now(),
        name: `SimulatedFileName-${uuidv4()}`,
        size: 1500000
      } as File,
      status: UploadStatus.PENDING,
      documentTypeId: templateId,
      progress: 0,
      documentId: undefined,
      clientId: clientId,
      matterId: matterId,
    };
    this.store.dispatch(new AddUploadingFile(uploadFile));

    const simulateUpload = (p: number) => {
      setTimeout(() => {
        this.store.dispatch(new UpdateUploadingFile({ ...uploadFile, status: UploadStatus.UPLOADING, progress: p, documentId: generatedDocumentId }));
        if (p < 100) simulateUpload(p + 5);
        else completeUpload()
      }, 500)
    };

    const completeUpload = () => {
      const simulatedPendingAgreement: PendingAgreement = {
        id: Math.round(Math.random() * 100),
        documentId: generatedDocumentId,
        tenantId: tenantId,
        templateId: templateId,
        documentName: uploadFile.file.name,
        status: 'awaiting ocr',
        error: 'OK',
        parties: ['Alice', 'Bob'],
        clientId: clientId,
        matterId: matterId
      };
      const pa = syncStoreGet(this.store, state => state.agreements.pendingAgreements);
      setTimeout(() => this.store.dispatch(new LoadPendingAgreements([...pa, simulatedPendingAgreement])), 1000);
      setTimeout(() => this.store.dispatch(new LoadPendingAgreements([...pa, { ...simulatedPendingAgreement, status: 'Summizing' }])), 2000)
      setTimeout(() => this.store.dispatch(new LoadPendingAgreements([...pa, { ...simulatedPendingAgreement, status: 'Summizing' }])), 2000)
    };

    simulateUpload(10);
  }
}
