import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {
  @Input() title = '';
  @Input() strong = false;

  ngOnInit(): void {
    this.strong = this.strong !== undefined && this.strong !== false;
  }
}
