import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AuthService, SummizeStorage } from '@summize/shared/framework';
import { MessageBrokerService } from '../services/message-broker.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  authenticated: boolean;

  constructor(
    private authService: AuthService,
    private messageBrokerService: MessageBrokerService) { }

  async canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (SummizeStorage.isIsolated() === true) {

      return true;

    }

    await this.authService.init();

    this.authenticated = await this.authService.isAuthenticated();

    if (this.authenticated) {

      this.messageBrokerService.init();

      return true;

    }

    this.messageBrokerService.deinit();

    this.authService.login({ redirectUrl: state.url });

    return false;

  }
}
