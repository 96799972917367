import { ResetTriggerToast } from './../../../store/actions/common.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { StoreComponent } from '../../util/store-component';
import { AppState } from '../../../store/state/app.state';
import { selectToastState } from '../../../store/selectors/common.selectors';
import { ToastService } from '../../../core/services/toast.service';
import { showFlyInFlyOut } from '../../animations';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [showFlyInFlyOut]
})
export class ToastComponent extends StoreComponent implements OnInit {
  toasts: TimedToast[] = [];
  duration = 10 * 1000;

  constructor(
    protected store: Store<AppState>, 
    protected toastService: ToastService) {
    super(store);
  }

  ngOnInit() {
    this.select(selectToastState, state => {
      if (state) {
        var toast = {id: uuidv4(), message: state.message, icon: state.icon};
        this.toasts = [toast, ...this.toasts];

        setTimeout(() => this.onDismissToast(toast), this.duration);
        this.store.dispatch(new ResetTriggerToast());
      }
    });
  }

  onDismissToast(toast) {
    this.toasts = this.toasts.filter(t => t.id != toast.id);
  }
}

class TimedToast {
  id: string;
  message: string;
  icon: string;
}
