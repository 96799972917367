export enum ExportItemTypes {
    CompanyLogo = 'CompanyLogo',
    FlaggedItem = 'FlaggedItem',
    ExportTitle = 'ExportTitle',
    ExportDocumentTitle = 'ExportDocumentTitle',
    CommentsItem = 'CommentsItem',
    ClausesItem = 'ClausesItem',
    KeyContractDates = 'KeyContractDates',
    ConversationHistory = 'ConversationHistory',
    RepositoryInformation = 'RepositoryInformation',
    ReviewInformation = 'ReviewInformation',
    // Excel
    HighlightFlaggedClauses = 'HighlightFlaggedClauses',
    AddExcelClauseNotes = 'AddExcelClauseNotes',
    ContractDatesAsAdditionalTabs = 'ContractDatesAsAdditionalTabs',
    SingleRowPerDocument = 'SingleRowPerDocument'
}

export enum ExportFormat {
    Word = 1,
    Excel = 3,
}

export enum ExportType {
    Unknown = 1,
    Table = 2,
    ContractReview = 3,
}

export enum ExportTypeOrder {
    CompanyLogo = 1,
    Title,
    DocumentTitle,
    FlaggedItems,
    Clauses,
    Comments,
    KeyDates,
    RepositoryInformation,
    ReviewInformation,
    Conversations,
    SingleRow,
}

export abstract class ExportItem {
    public type: ExportItemTypes;
    public order: number;

    protected constructor(type: ExportItemTypes, order: number = 0) {
        this.type = type;
        this.order = order;
    }
}

export class CompanyLogo extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.CompanyLogo;
    public image: string;
    public enabled: boolean;

    constructor(image: string, enabled: boolean) {
        super(ExportItemTypes.CompanyLogo, ExportTypeOrder.CompanyLogo);
        this.image = image;
        this.enabled = enabled;
    }
}

export class FlaggedItem extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.FlaggedItem;
    public enabled: boolean;

    constructor(enabled: boolean) {
        super(ExportItemTypes.FlaggedItem, ExportTypeOrder.FlaggedItems);
        this.enabled = enabled;
    }
}

export class ExportTitle extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.ExportTitle;
    public enabled: boolean;
    public title: string;
    public subtitle: string;
    public todaysDate: Date;

    constructor(enabled: boolean, title: string, subtitle: string, todaysDate: Date) {
        super(ExportItemTypes.ExportTitle, ExportTypeOrder.Title);
        this.enabled = enabled;
        this.title = title;
        this.subtitle = subtitle;
        this.todaysDate = todaysDate;
    }
}

export class ExportDocumentTitle extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.ExportDocumentTitle;
    public enabled: boolean;
    public includeTitle: boolean;
    public includeSubtitle: boolean;
    public subtitle: string;
    public todaysDate: Date;

    constructor(enabled: boolean, includeTitle: boolean, includeSubtitle: boolean, subtitle: string, todaysDate: Date) {
        super(ExportItemTypes.ExportDocumentTitle, ExportTypeOrder.DocumentTitle);
        this.enabled = enabled;
        this.includeTitle = includeTitle;
        this.subtitle = subtitle;
        this.includeSubtitle = includeSubtitle;
        this.todaysDate = todaysDate;
    }
}

export class CommentsItem extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.CommentsItem;
    public enabled: boolean;

    constructor(enabled: boolean) {
        super(ExportItemTypes.FlaggedItem, ExportTypeOrder.Comments);
        this.enabled = enabled;
    }
}

export class ClausesItem extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.ClausesItem;
    public clauses: number[];
    public enabled: boolean;

    constructor(selectedClauses: number[], enabled: boolean) {
        super(ExportItemTypes.ClausesItem, ExportTypeOrder.Clauses);
        this.clauses = selectedClauses;
        this.enabled = enabled;
    }
}

export class KeyContractDates extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.KeyContractDates;
    public enabled: boolean;

    constructor(enabled: boolean) {
        super(ExportItemTypes.KeyContractDates, ExportTypeOrder.KeyDates);
        this.enabled = enabled;
    }
}

export class ExportConversationHistory extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.ConversationHistory;
    public enabled: boolean;

    constructor(enabled: boolean) {
        super(ExportItemTypes.ConversationHistory, ExportTypeOrder.Conversations);
        this.enabled = enabled;
    }
}

export class ExportRepositoryInformation extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.RepositoryInformation;
    public enabled: boolean;

    constructor(enabled: boolean) {
        super(ExportItemTypes.RepositoryInformation, ExportTypeOrder.RepositoryInformation);
        this.enabled = enabled;
    }
}

export class ExportReviewInformation extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.ReviewInformation;
    public enabled: boolean;

    constructor(enabled: boolean) {
        super(ExportItemTypes.ReviewInformation, ExportTypeOrder.ReviewInformation);
        this.enabled = enabled;
    }
}

// Excel
export class HighlightFlaggedClauses extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.HighlightFlaggedClauses;
    public enabled: boolean;
    constructor(enabled: boolean) {
        super(ExportItemTypes.HighlightFlaggedClauses, ExportTypeOrder.FlaggedItems);
        this.enabled = enabled;
    }
}

export class AddExcelClauseNotes extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.AddExcelClauseNotes;
    public enabled: boolean;
    constructor(enabled: boolean) {
        super(ExportItemTypes.AddExcelClauseNotes, ExportTypeOrder.Clauses);
        this.enabled = enabled;
    }
}

export class ContractDatesAsAdditionalTabs extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.ContractDatesAsAdditionalTabs;
    public enabled: boolean;
    constructor(enabled: boolean) {
        super(ExportItemTypes.ContractDatesAsAdditionalTabs, ExportTypeOrder.KeyDates);
        this.enabled = enabled;
    }
}

export class SingleRowPerDocument extends ExportItem {
    public type: ExportItemTypes = ExportItemTypes.SingleRowPerDocument;
    public enabled: boolean;
    constructor(enabled: boolean) {
        super(ExportItemTypes.SingleRowPerDocument, ExportTypeOrder.SingleRow);
        this.enabled = enabled;
    }
}
