import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@summize/shared/core';

import { combineLatest } from 'rxjs';

import { ContractRedirectService } from './contract-redirect.service';

@Component({
    template: ``
})
export class ContractRedirectComponent extends AppComponent implements OnInit, OnDestroy {

    private contractId: string;

    constructor(private service: ContractRedirectService,
        private route: ActivatedRoute,
        private router: Router) { super(); }

    public ngOnInit() {

        const params = combineLatest([this.route.params, this.route.queryParams]);

        this.subscribe(params, async (args) => {

            const [params, queryParams] = args;

            this.contractId = params.id;

            try {

                const url = await this.service.get(this.contractId);

                this.router.navigate([url], { queryParams: queryParams });

            } catch (error) {

                this.router.navigateByUrl('no-access?reason=ContractAccess', {
                    skipLocationChange: true
                });

            }

        });
    }

}