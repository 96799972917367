import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class RegionSelectorService {

    constructor(private http: HttpClient) { }

    public getUserRegions(user: string, regions: Array<{ name: string, endpoint: string }>): Promise<Array<any>> {

        const results = regions.map(r => {

            return new Promise(async (resolve) => {

                let result: any;

                try {

                    result = await this.http.get(`${r.endpoint}/api/identity/region?email=${encodeURIComponent(user)}`).toPromise()

                } catch (error) {

                    result = { found: false };

                }

                return resolve({ match: result.found, ...r });

            });

        });

        return Promise.all(results);

    }

}