<div class="contract-calendar__container" [class.contract-calendar__container--open]="isCalendarOpen">
  <div class="contract-calendar__backdrop" (click)="close()" [class.contract-calendar__backdrop--open]="isCalendarOpen">
  </div>

  <div class="contract-calendar" [class.contract-calendar--open]="isCalendarOpen">

    <div class="contract-calendar__header">
      <cc-date-header></cc-date-header>
    </div>
    <div>
      <div>
        <div class="contract-calendar__calendar">
          <cc-date-picker></cc-date-picker>
        </div>
      </div>


      <div *ngIf="loading === true">
        <div class="spinner">
          <app-loading-spinner visible="true"></app-loading-spinner>
        </div>
      </div>

      <div *ngIf="loading === false">

        <div *ngIf="calendarDatesSorted?.length === 0" class="card-subheading">
          No calendar events
        </div>

        <ng-container *ngFor="let calendarEventGroup of calendarDatesSorted">

          <div class="card-subheading">
            {{ calendarEventGroup[0] | date: 'LLLL yyyy' }}
          </div>

          <div class="card-holder" *ngFor="let date of calendarEventGroup[1]">
            <calendar-card (cardEvent)="onCardEvent($event)" [date]="date"></calendar-card>
          </div>

        </ng-container>

      </div>

    </div>
  </div>
</div>
