<div *ngIf="display === 'error'" class="problem">
    <div class="primary">Oops! something has gone wrong!</div>
    <div class="secondary">Something has gone wrong. Please contact <a href="mailto:support@summize.com">Summize</a> or
        try again later.</div>
    <div>
        <button (click)="signout()" class="button button__regular">Sign Out</button>
    </div>
</div>
<div *ngIf="display === 'error-retry'" class="problem">
    <div class="primary">Oops! something has gone wrong!</div>
    <div class="secondary">Something has gone wrong, please <a href="/">try again</a>. If the problem persists, please
        contact <a href="mailto:support@summize.com">Summize</a> or
        try again later.</div>
    <div>
        <button (click)="signout()" class="button button__regular">Sign Out</button>
    </div>
</div>
<div *ngIf="display === 'permissions'" class="problem">
    <div class="primary">Oops! You don't have access to that contract!</div>
    <div class="secondary">If you think this is wrong, please contact your Summize administrator.</div>
    <div>
        <button (click)="signout()" class="button button__regular">Sign Out</button>
    </div>
</div>
<div *ngIf="display === 'access'" class="problem">
    <div class="primary">You dont have access to this feature</div>
    <div class="secondary">If you think this is wrong, please contact your Summize administrator.</div>
</div>
<div *ngIf="display === 'elfd-error'" class="problem">
    <div class="primary">Oops! something went wrong logging you in!</div>
    <div class="secondary">Please contact your Summize administrator.</div>
</div>

<div *ngIf="display === 'feature-flag-missing'" class="problem">
    <div class="primary">This feature isnt available for your tenant just yet</div>
    <div class="secondary">Please contact Summize to get it enabled.</div>
</div>