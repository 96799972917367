import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-smz-button',
    templateUrl: './styled-button.component.html',
    styleUrls: ['./styled-button.component.scss'],
})
export class StyledButtonComponent implements OnInit {
    @Input() type: 'primary' | 'secondary' | 'tertiary' | 'primaryPurple' | 'secondaryPurple' | 'tertiaryPurple' | 'none' = 'none';
    @Input() size: 'small' | 'medium' | 'large' | 'none' = 'none';
    @Input('disabled') isDisabled: boolean;
    @Input('expand') expand: boolean;
    @HostBinding('style.width') width = 'unset';

    ngOnInit(): void {
        this.isDisabled = this.isDisabled !== undefined && this.isDisabled !== false;
        this.expand = this.expand !== undefined && this.expand !== false;

        if (this.expand) {
            this.width = '100%';
        }
    }
}
