import { Injectable, EventEmitter } from '@angular/core';

import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ChatMessage, ChatMessageBodyType, ChatMessageFromType } from '../models/chatmessage.model';
import { TokenService } from './token.service';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MessageBrokerService {

    private connectionEstablished = new BehaviorSubject<boolean>(false);

    public isConnected = this.connectionEstablished.asObservable();

    public isStartingConnection = false;

    public messageReceived = new EventEmitter<ChatMessage>();

    public isConnectionEstablished = false;

    private hubConnection: HubConnection;

    private isDebugging = false;

    constructor(
        private userService: UserService,
        private tokenService: TokenService) {
    }

    public init() {
        // Because we don't want the chat service to start on construction (i.e. probably pre-auth),
        // we've added this manual method. Hopefully it doesn't cause confusion.
        this.startConnection();
    }

    public deinit() {
        this.stopConnection();
    }

    public sendUserChatMessage(user: string, chatMessage: ChatMessage): Promise<void> {

        if (!this.isConnectionEstablished) {

            this.trace('Unable to send chat message, not connected.');

            this.startConnection();

            return;
        }

        return this.hubConnection
            .invoke('SendUserMessage', user, chatMessage)
            .catch(err => {
                console.error(err);
            });
    }

    public sendGroupChatMessage(group: string, body: string, bodyType: ChatMessageBodyType): Promise<void> {

        if (!this.isConnectionEstablished) {

            this.trace('Unable to send chat message, not connected.');

            this.startConnection();

            return;

        }

        const userId = this.userService.getUserId();

        const chatMessage: ChatMessage = {
            From: {
                FromType: ChatMessageFromType.User,
                Value: userId
            },
            Body: {
                BodyType: bodyType,
                Value: body
            }
        };

        this.trace(`SendGroupMessage: ${group} ${JSON.stringify(chatMessage)}`);

        return this.hubConnection
            .invoke('SendGroupMessage', group, chatMessage)
            .catch(err => {
                console.error(err);
            });
    }

    public joinGroup(group: string, matter: { clientId: string, matterId: string }, attempt: number = 0): Promise<void> {

        if (!this.isConnectionEstablished) {

            this.trace('Unable to send chat message, not connected.');

            this.startConnection();

            return;
        }

        this.trace(`Joined Group: group: ${group}, matter: ${matter.matterId}, client: ${matter.clientId}`);

        return this.hubConnection
            .invoke('JoinGroup', group, matter.matterId, matter.clientId)
            .catch(err => {

                if (attempt <= 3) {
                    // Wait
                    this.trace('Failed to join group, retrying connection');

                    this.startConnection();

                    attempt++;

                    this.joinGroup(group, matter, attempt);

                } else {

                    console.error(err);

                    return;

                }

            });
    }

    public leaveGroup(group: string, matter: { clientId: string, matterId: string }): Promise<void> {

        if (!this.isConnectionEstablished) {

            this.trace('Unable to send chat message, not connected.');

            this.startConnection();

            return;
        }

        this.trace(`Leave Group: group:${group}, matter: ${matter}, client: ${matter.matterId}`);

        return this.hubConnection
            .invoke('LeaveGroup', group, matter.matterId, matter.clientId)
            .catch(err => {
                console.error(err);
            });
    }

    public on(func: Function): Observable<any> {

        return this.messageReceived.pipe(
            map(msg => JSON.parse(msg.Body.Value)),
            filter(msg => func(msg))
        );

    }

    public all(): Observable<any> {

        return this.messageReceived.pipe(
            map(msg => JSON.parse(msg.Body.Value)),
        );

    }

    private initConnection(): void {

        const chatApi = `${environment.apiUrl}/api/chathub`;

        if (this.isDebugging) {

            this.hubConnection = new HubConnectionBuilder()
                .withUrl(chatApi, { accessTokenFactory: () => this.tokenService.getToken() })
                .configureLogging(LogLevel.Trace)
                .withAutomaticReconnect()
                .build();

        } else {

            this.hubConnection = new HubConnectionBuilder()
                .withUrl(chatApi, { accessTokenFactory: () => this.tokenService.getToken() })
                .withAutomaticReconnect()
                .build();

        }

        this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 3;

        this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 6;

        this.hubConnection.onclose(async () => {
            setTimeout(() => this.startConnection(), +environment.signalRTimeout);
        });

        this.hubConnection.on('echo', (chatMessage: ChatMessage) => {

            this.trace(`MessageReceived ${JSON.stringify(chatMessage)}`);

            this.messageReceived.emit(chatMessage);

        });

        this.hubConnection.keepAliveIntervalInMilliseconds = +environment.signalRKeepAlive;
    }

    private startConnection() {

        if (this.isConnectionEstablished || this.isStartingConnection) {
            return;
        }

        this.isStartingConnection = true;

        if (!this.hubConnection) {
            this.initConnection();
        }

        this.trace('Starting Connection...');

        return this.hubConnection
            .start()
            .then(() => {
                this.isConnectionEstablished = true;
                this.isStartingConnection = false;
                this.trace('Hub Connected');
                this.connectionEstablished.next(true);
            })
            .catch(err => {
                this.isConnectionEstablished = false;
                this.isStartingConnection = false;
                this.trace('Error Hub Connecting. Retrying...');
                this.connectionEstablished.next(false);
                setTimeout(this.startConnection, +environment.signalRTimeout);
            });
    }

    private stopConnection() {
        // Block any new start requests
        this.isStartingConnection = true;

        this.isConnectionEstablished = false;
        this.connectionEstablished.next(this.isConnectionEstablished);

        // Don't wait for 'then', just assume it is orphaned.
        if (this.hubConnection) {
            this.hubConnection.stop();
        }
        this.hubConnection = null;

        this.isStartingConnection = false;
    }

    private trace(str: string): void {

        if (this.isDebugging === true) {

            console.log(str);

        }

    }
}
