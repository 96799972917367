import { Component, ElementRef, HostListener, ViewChild, OnInit } from '@angular/core';
import { StoreComponent } from '../../../../core/util/store-component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { selectSelectedCalendarClause } from '../../../../store/selectors/contract-calendar.selectors';
import { SelectedClauseEvent } from '../../../../core/models/contractCalendar.model';
import { OpenCalendar, ResetSelectedCalendarClause } from '../../../../store/actions/contract-calendar.actions';

import { Router } from '@angular/router';

@Component({
  selector: 'cc-view-document-popup',
  templateUrl: './view-document-popup.component.html',
  styleUrls: ['./view-document-popup.component.scss']
})
export class ViewDocumentPopupComponent extends StoreComponent implements OnInit {

  @ViewChild('viewDocumentButton') viewDocButton: ElementRef;
  @ViewChild('viewDocumentContainer') viewContainer: ElementRef;

  selectedCalendarEvent: SelectedClauseEvent;
  matterId = '';
  clientId = '';

  constructor(
    protected store: Store<AppState>,
    protected elem: ElementRef,
    private router: Router) {
    super(store);
    this.select(selectSelectedCalendarClause, selectedCalendarEvent => this.selectedCalendarEvent = selectedCalendarEvent);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  matchesThisView(target: EventTarget) {
    return this.viewDocButton && this.viewContainer && (target === this.viewDocButton.nativeElement || target === this.viewContainer.nativeElement);
  }

  @HostListener('window:click', ['$event'])
  globalClick(event: MouseEvent) {
    if (this.selectedCalendarEvent && !this.matchesThisView(event.target)) {
      event.preventDefault();
      event.stopPropagation();

      this.router.navigateByUrl(`/my-contracts/clients/${this.clientId}/matters/${this.matterId}/review/${this.selectedCalendarEvent.documentUuid}`);
      this.selectedCalendarEvent = null;
      this.store.dispatch(new OpenCalendar(false));
      this.store.dispatch(new ResetSelectedCalendarClause());
    }
  }

  toPx = (val: number) => val + 'px';
}
