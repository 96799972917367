import { CurrentPageState, initialCurrentPageState } from '../state/page.state';
import { CurrentPageActions, ECurrentPageActions } from '../actions/current-page.action';

export function currentPageReducers(state: CurrentPageState = initialCurrentPageState, action: CurrentPageActions): CurrentPageState {
  switch (action.type) {
    case ECurrentPageActions.SetSelectedPage: {
      return {
        ...state,
        currentSelectedPage: action.payload,
      };
    }
    case ECurrentPageActions.OpenContextMenu: {
      return {
        ...state,
        contextMenuOpen: true,
        contextMenuItems: action.menuItems,
        contextMenuPosition: {
          x: action.x,
          y: action.y,
        },
      };
    }
    case ECurrentPageActions.CloseContextMenu: {
      return {
        ...state,
        contextMenuItems: [],
        contextMenuOpen: false,
        contextMenuPosition: {
          x: 0,
          y: 0,
        },
      };
    }

    default:
      return state;
  }
}
