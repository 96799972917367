import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { AuthService, LocalStorageService, SummizeStorage } from '@summize/shared/framework';
import { environment } from "@summize/environment";
import { ApplicationInsightsService, BaseTenantService } from "@summize/shared/core";

@Injectable({
  providedIn: 'root'
})
export class RegionGuard implements CanActivate {

  public static REGION_KEY = 'smz-region';

  public static REGION_INFO = 'region-data';

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private storageService: LocalStorageService,
    private appInsights: ApplicationInsightsService) { }

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (SummizeStorage.isIsolated() === true) {

      return true;

    }

    const smzRegion = next.queryParams['smz-region'];

    if (smzRegion !== null && smzRegion !== undefined) {

      if (smzRegion.length === 0) {

        this.storageService.removeItem(RegionGuard.REGION_KEY);

        this.storageService.removeItem(RegionGuard.REGION_INFO);

      } else {

        this.storageService.setItem(RegionGuard.REGION_KEY, smzRegion);

      }

    }

    const region = this.storageService.getItem(RegionGuard.REGION_KEY);

    if (region === undefined || region === null) {

      if (environment.regions.length === 1) {

        const def = environment.regions[0].endpoint;

        this.storageService.setItem(RegionGuard.REGION_KEY, def);

        await this.handleRegionSelectionComplete(def);

        return Promise.resolve(true);

      }

      return this.router.navigateByUrl('/region');

    }

    await this.handleRegionSelectionComplete(region);

    return Promise.resolve(true);

  }

  private async handleRegionSelectionComplete(region: string): Promise<void> {

    environment.apiUrl = region;

    const remoteEnvironment: any = await this.http.get(`${environment.apiUrl}/api/environment`).toPromise();

    if (remoteEnvironment.summizeAppUrl !== window.location.origin) {

      window.location.href = remoteEnvironment.summizeAppUrl + '?smz-region=';

    }

    if (environment.name === 'dev') {

      Object.keys(environment).forEach((key) => {

        if (environment[key] !== null) {

          remoteEnvironment[key] = null;

        }

      });

    }

    Object.keys(remoteEnvironment).forEach((key) => {

      if (remoteEnvironment[key] !== null) {

        if (typeof remoteEnvironment[key] === 'string' && environment[key] !== null) {

          if (remoteEnvironment[key].trim().length > 0) {

            environment[key] = remoteEnvironment[key];

          }

        } else {

          environment[key] = remoteEnvironment[key];

        }
      }

    });

    SummizeStorage.setSessionItem(
      BaseTenantService.ENV_KEY, JSON.stringify(environment)
    );

    this.appInsights.init(environment.applicationInsights);

    await this.auth.init();

  }
}
