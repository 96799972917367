import { LocalStorageService } from '../../local-storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CorrelationIdService {

  constructor(private localStorageService: LocalStorageService) { }

  getCorrelationId(): string {
    return this.localStorageService['correlationId'];
  }

  saveCorrelationId(token: string) {
    this.localStorageService['correlationId'] = token;
  }

  destroyCorrelationId() {
    this.localStorageService.removeItem('correlationId');
  }
}
