import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { intersection } from 'lodash-es';

import { AccessHelper, ApplicationInsightsService, AppLoginMetric } from '@summize/shared/core';
import { AssetLogo, EventService, SummizeStorage } from '@summize/shared/framework';

import { TokenService } from './token.service';
import { LocalStorageService } from '../../local-storage.service';
import { User } from '../models';
import { environment } from '../../../environments/environment';
import { AppState } from '../../store/state/app.state';
import { ResetUser, SetTenantLogo, SetUser } from '../../store/actions/common.actions';
import { emptyGuid } from '../util/consts';
import { setDocumentLimit, setDocumentsUsed, setIsTrial, setHasExpired } from '../../store/actions/account.actions';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public userRequest;
    public mandatoryIntro = false;

    constructor(
        public localStorageService: LocalStorageService,
        public httpClient: HttpClient,
        public tokenService: TokenService,
        public applicationInsightsService: ApplicationInsightsService,
        public store: Store<AppState>,
        private router: Router,
        private events: EventService) {

    }

    loadLocalUser() {

        const cachedUser = JSON.parse(this.localStorageService.getItem('user'));

        if (cachedUser) {

            this.events.despatch(EventService.GlobalEvents.SetUser, cachedUser);

        }
    }

    getUserValue(): User {

        const u = this.localStorageService.getItem('user');

        if (u !== undefined) {

            return JSON.parse(this.localStorageService.getItem('user'));

        }

        return;

    }

    public purgeAuth() {
        this.tokenService.destroyToken();
        this.localStorageService.removeItem('user');
        this.localStorageService.removeItem('correlationId');
        this.localStorageService.removeItem('okta-pkce-storage');
        this.localStorageService.removeItem('okta-cache-storage');
        this.localStorageService.removeItem('okta-token-storage');
        this.userRequest = undefined;
        this.store.dispatch(new ResetUser());
        this.applicationInsightsService.clearUserId();
    }

    async loadUser(): Promise<User> {
        const user = await this.getUser();
        if (!user) {
            this.purgeAuth();
            return;
        }
        this.localStorageService.setItem('user', JSON.stringify(user));
        return user;
    }

    async getUser(): Promise<User> {
        // return user as promise if set
        if (this.getUserValue()) {
            return Promise.resolve(this.getUserValue());
        }

        // return request promise if request in flight
        if (this.userRequest) {
            return this.userRequest;
        }

        // make request if no request in flight or no user fetched
        try {
            const user: User = await this.refreshSession();
            this.userRequest = undefined;
            return user;
        } catch (err) {
            this.userRequest = undefined;
            throw err;
        }
    }

    async updateHasCompletedRegistration(value: boolean): Promise<void> {
        try {
            const user = this.getUserValue();
            await this.httpClient.put(`${user.apiBaseUrl}1.0/tenant`, { hasCompletedRegistration: value }).toPromise();
            const updatedUser = {
                ...user,
                hasCompletedRegistration: value,
            };
            this.store.dispatch(new SetUser(updatedUser));
            this.localStorageService.setItem('user', JSON.stringify(updatedUser));
        } catch (err) {
            console.error('Unable to update has completed registration');
            throw err;
        }
    }

    async requestUpgrade(): Promise<void> {
        try {
            const user = this.getUserValue();
            await this.httpClient.post(`${user.apiBaseUrl}1.0/tenant/upgrade`, null).toPromise();
        } catch (err) {
            console.error('Unable to upgrade registration');
            throw err;
        }
    }

    async refreshSession(): Promise<User> {
        try {

            const sessionResponse = await this.httpClient.get<any>(`${environment.apiUrl}/common/api/1.0/session`).toPromise();

            SummizeStorage.setLocalItem('pdfKey', sessionResponse.tenant.pdfKey);

            const apiUrl = environment.apiUrl.startsWith('https') ?
                sessionResponse.tenant.apiBaseUrl.replace('http://', 'https://') : sessionResponse.tenant.apiBaseUrl;

            const user: User = {
                apiBaseUrl: apiUrl,
                claims: sessionResponse.user.claims,
                clientId: sessionResponse.user.clientId,
                companyLogoUrl: sessionResponse.tenant.logoUrl,
                departmentId: sessionResponse.user.departmentId,
                departmentName: sessionResponse.user.departmentName,
                email: sessionResponse.user.email,
                featureFlags: sessionResponse.user.featureFlags,
                firstName: sessionResponse.user.firstName,
                hasCompletedRegistration: sessionResponse.tenant.hasCompletedRegistration,
                hasExpired: sessionResponse.tenant.hasExpired,
                isClientMatterEnabled: sessionResponse.tenant.isClientMatterEnabled,
                showGenericClientMatterLabels: sessionResponse.tenant.showGenericClientMatterLabels,
                isAdmin: sessionResponse.user.isAdmin,
                lastName: sessionResponse.user.lastName,
                matterId: sessionResponse.user.matterId,
                tenantId: sessionResponse.tenant.tenantId,
                tenantName: sessionResponse.tenant.tenantName,
                userId: sessionResponse.user.userId,
                userType: sessionResponse.user.userType,
                isFullTextSearchEnabled: sessionResponse.tenant.isFullTextSearchEnabled,
                isSummizeAuthentication: sessionResponse.user.isSummizeAuthentication,
                isAIEnabled: sessionResponse.tenant.isAIEnabled,
                isPremiumPlaybookEnabled: sessionResponse.tenant.isPremiumPlaybookEnabled,
                isPremiumPlaybookAnalyticsEnabled: sessionResponse.tenant.isPremiumPlaybookAnalyticsEnabled,
                isMultitenancySearchEnabled: sessionResponse.tenant.isMultitenancySearchEnabled,
                timeZoneId: sessionResponse.tenant.timeZoneId,
                tenantMode: sessionResponse.tenant.tenantMode
            };

            if (user.companyLogoUrl === undefined || user.companyLogoUrl === null || user.companyLogoUrl.length === 0) {
                const logo = await this.httpClient.get<AssetLogo>(`${user.apiBaseUrl}1.0/assets/logo`).toPromise();
                user.companyLogoUrl = logo.logoUrl;
            }

            const isRestricted = AccessHelper.CanAccessApplication(user.featureFlags, user.claims) === false;

            if (isRestricted === true && this.router.url !== 'no-access') {

                this.router.navigateByUrl('no-access');

                return;

            }

            this.mandatoryIntro = !user.hasCompletedRegistration;

            this.store.dispatch(new SetTenantLogo(user.companyLogoUrl));
            this.store.dispatch(setDocumentLimit(sessionResponse.tenant.documentLimit));
            this.store.dispatch(setDocumentsUsed(sessionResponse.tenant.documentsUsed));
            this.store.dispatch(setIsTrial(sessionResponse.tenant.isTrial));
            this.store.dispatch(setHasExpired(user.hasExpired));
            this.store.dispatch(new SetUser(user));

            this.localStorageService.setItem('user', JSON.stringify(user));

            this.applicationInsightsService.setUserId(user.userId);

            this.applicationInsightsService.logMetric(AppLoginMetric, 1, {
                tenantId: user.tenantId,
                userId: user.userId
            });

            return user;

        } catch (err) {

            this.router.navigateByUrl('/problem');

            throw err;

        }
    }

    getTenantId(): string {
        const user = this.getUserValue();
        return user?.tenantId;
    }

    getUserId(): string {
        const user = this.getUserValue();
        return user?.userId;
    }

    getClientId(): string | null {
        const user = this.getUserValue();
        if (!user || !user.clientId || user.clientId === emptyGuid) {
            return null;
        }
        return user?.clientId;
    }

    getMatterId(): string | null {
        const user = this.getUserValue();
        if (!this.getClientId() || !user.matterId || user.matterId === emptyGuid) {
            return null;
        }
        return user?.matterId;
    }

    getIsClientMatterEnabled(): boolean {
        const user = this.getUserValue();
        return user?.isClientMatterEnabled;
    }

    getShowGenericClientMatterLabels(): boolean {
        const user = this.getUserValue();
        return user?.showGenericClientMatterLabels;
    }

    getRootPage(): string {

        if (!this.getIsClientMatterEnabled()) {

            return `/my-contracts/clients/${this.getTenantId()}/matters/${this.getTenantId()}`;

        }

        return '/contracts';

    }

    getIsMandatoryIntro(): boolean {
        return this.mandatoryIntro;
    }

    getUserBaseUrl(): string {
        const user = this.getUserValue();
        return user?.apiBaseUrl;
    }

    getFeatureFlags(): Array<string> {
        const user = this.getUserValue();
        if (!user) { return []; }

        return user?.featureFlags ?? [];
    }

    hasFeatureFlag(flag: string) {

        const envFeatures = environment.featureFlags;

        return this.getFeatureFlags().find(f => f === flag) !== undefined || envFeatures.find(f => f === flag) !== undefined;

    }

    getClaims(): string[] {
        const user = this.getUserValue();
        return user?.claims ?? [];
    }

    public hasClaim(claim: string): boolean {

        return this.hasClaims([claim]);

    }

    hasClaims(claims: string[], all: boolean = true): boolean {

        const userClaims = this.getClaims();

        if (all) {
            const intersect = intersection(claims, userClaims);
            return (intersect.length === claims.length);
        } else {
            const intersect = intersection(claims, userClaims);
            return (intersect.length > 0);
        }
    }

    getIsAdmin(): boolean {
        const user = this.getUserValue();
        return user?.isAdmin ?? false;
    }

    getCanViewClients() {
        const clientId = this.getClientId();
        return !clientId;
    }

    getCanViewMatters() {
        const matterId = this.getMatterId();
        return !matterId;
    }
}
