import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';

import { UserService } from '../services/user.service';

@Directive({
    selector: '[appClaimsShow]'
})
export class ClaimsShowDirective {

    claims: string[] = [];
    claimsAction: string;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userService: UserService,
    ) { }

    updateView() {
        if (this.claims) {
            if (this.userService.hasClaims(this.claims)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        }
    }

    @Input() set appClaimsShow(claims: string[]) {
        this.claims = claims;
        this.updateView();
    }
}