<ng-container *ngIf="loading === true">
    <div class="spinner">
        <app-loading-spinner visible="true"></app-loading-spinner>
    </div>
</ng-container>

<ng-container *ngIf="loading === false && selectedPanel === 'CalendarCards'">

    <div class="pt-3 pb-3 title-text">
        Calendar Events
    </div>

    <div class="calendar-event-panel">

        <div class="info-text">
            This contract has {{calendarEventsCount}} event(s).
        </div>

        <ng-container *ngFor="let calendarEventGroup of calendarEventsSorted">

            <div class="card-subheading">
                {{ calendarEventGroup[0] | date: 'LLLL yyyy' }}
            </div>

            <div class="card-holder" *ngFor="let date of calendarEventGroup[1]">
                <calendar-card (cardEvent)="onCardEvent($event)" [date]="date"></calendar-card>
            </div>

        </ng-container>

    </div>

</ng-container>

<ng-container *ngIf="loading === false && selectedPanel !== 'CalendarCards'">

    <div class="calendar-form-panel">

        <div class="panel-content">
            <div class="calendar-title">
                {{titleText}}
            </div>

            <div *ngIf="selectedPanel === 'StandardEvent'" class="calendar-intro">
                Add an event to the Summize calendar to keep a record and be reminded of key dates.
            </div>

            <div *ngIf="selectedPanel === 'TerminationEvent'" class="calendar-intro">
                Add a termination event to the Summize calendar to keep a record and be reminded of key termination
                dates.
            </div>

            <div class="calendar-form">

            <div class="pt-2">
                <label class="body-small heavy required">Event date</label>
            </div>
            <div class="date-field pt-1">
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker" [(ngModel)]="eventModel.eventDate"
                        (dateChange)="eventDateUpdated()" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
                <span class="input-helper-text">
                    Dates will be based on the time zone set for your organisation which is {{timeZoneId}}.
                </span>
            </div>

                <div class="pull-out mt-3">
                    <div matTooltip="Evergreen events are only supported for event dates in the future."
                        [matTooltipDisabled]="!disableEvergreen" matTooltipClass="smz-tooltip">
                        <mat-checkbox [(ngModel)]="eventModel.isEvergreen" [checked]="eventModel.isEvergreen"
                            [disabled]="disableEvergreen">
                            <span class="weight-heavy">Evergreen event</span>
                        </mat-checkbox>
                    </div>

                    <span class="pull-out-description mt-2">
                        Evergreen events are automatically replicated 12 months in advance once the event date has
                        passed.
                        <a href="https://customers.summize.com/hub" target="_blank" class="learn-more-link">Learn
                            more</a>
                    </span>
                </div>

                <ng-container *ngIf="selectedPanel === 'TerminationEvent' || selectedPanel === 'TerminationEdit'">

                    <div class="pt-4">
                        <label class="body-small heavy">Notice period (days)</label>
                    </div>
                    <div class="pt-1">
                        <mat-form-field appearance="fill">
                            <input matInput type="number" onkeydown="if(event.key==='.'){event.preventDefault();}"
                                [(ngModel)]="eventModel.noticePeriod">
                        </mat-form-field>
                    </div>

                </ng-container>

                <div class="pt-4">
                    <label class="body-small heavy">Description</label>
                </div>
                <div class="pt-1">
                    <mat-form-field appearance="fill">
                        <textarea matInput [(ngModel)]="eventModel.description" class="description-input"></textarea>
                    </mat-form-field>
                </div>

                <div class="pt-3">
                    <label class="body-small heavy">Associated clause</label>
                </div>
                <div class="pt-1 pb-4">
                    <mat-form-field appearance="fill">
                        <mat-select matNativeControl [(ngModel)]="eventModel.clauseId" placeholder="None"
                            disableOptionCentering>
                            <mat-option *ngFor="let clause of clauses" [value]="clause.clauseId">
                                {{ clause.ruleName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="body-large heavy pt-3 pb-3">
                    Reminder settings
                </div>

                <div class="body-small">
                    <mat-checkbox [(ngModel)]="eventModel.includeInSummaryEmails"
                        [checked]="eventModel.includeInSummaryEmails">
                        <span class="weight-heavy">Default reminder emails</span> (sent monthly and every 7 days)
                    </mat-checkbox>
                </div>

                <div class="body-small pt-3">
                    <mat-checkbox [(ngModel)]="eventModel.customReminder" [checked]="eventModel.customReminder"
                        (change)="toggleCustomReminder()">
                        <span class="weight-heavy">Send custom reminder</span>
                    </mat-checkbox>
                </div>

                <ng-container *ngIf="eventModel.customReminder">

                    <div class="date-field pt-4">
                        <label class="body-small heavy required">Send on</label>
                        <mat-form-field appearance="fill">
                            <input matInput [min]="eventDateMin" [max]="eventModel.eventDate" [matDatepicker]="picker2"
                                [(ngModel)]="eventModel.firstReminder" disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div *ngIf="!secondReminder" class="body-small add-reminder pt-4" (click)="toggleSecondReminder()">
                        + Add second reminder
                    </div>

                    <div *ngIf="secondReminder" class="date-field pt-4">
                        <label class="body-small heavy required">Send on</label>
                        <mat-form-field appearance="fill">
                            <input matInput [min]="eventDateMin" [max]="eventModel.eventDate" [matDatepicker]="picker3"
                                [(ngModel)]="eventModel.secondReminder" disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div *ngIf="secondReminder" class="body-small remove-reminder pt-4"
                        (click)="toggleSecondReminder()">
                        - Remove second reminder
                    </div>

                    <div class="pt-4">
                        <label class="body-small heavy required">Send to</label>
                        <app-calendar-event-notification-targets [value]="eventModel.notifications ?? []" (valueChange)="updatedNotificationTargets($event)"/>
                    </div>

                </ng-container>

            </div>
        </div>

        <div class="panel-commands">
            <app-block-button *ngIf="selectedPanel === 'StandardEvent'" (click)="saveEvent()" display="Add Event"
                width="100" type="block" [disabled]="isCustomEventValid === false"
                data-cy="confirm-add-event"></app-block-button>
            <app-block-button *ngIf="selectedPanel === 'TerminationEvent'" (click)="saveEvent()"
                display="Add Termination Event" width="175" type="block"
                [disabled]="isCustomEventValid === false"></app-block-button>
            <app-block-button *ngIf="selectedPanel === 'StandardEdit' || selectedPanel === 'TerminationEdit'"
                (click)="updateEvent()" display="Save Changes" width="125" type="block"
                [disabled]="isCustomEventValid === false"></app-block-button>
            <app-block-button (click)="cancel()" display="Cancel" width="100" type="ghost"></app-block-button>
        </div>

    </div>

</ng-container>
