import { Action } from '@ngrx/store';
import { CurrentPage } from '../state/page.state';

export enum ECurrentPageActions {
  SetSelectedPage = '[Current Page] Set selected current page',
  CloseContextMenu = '[Current Page] Reset context menu',
  OpenContextMenu = '[Current Page] Open context menu',
}

export class SetSelectedPage implements Action {
  public readonly type = ECurrentPageActions.SetSelectedPage;

  constructor(public payload: CurrentPage) {}
}

export class OpenContextMenu implements Action {
  public readonly type = ECurrentPageActions.OpenContextMenu;

  constructor(public menuItems: any[], public x: number, public y: number) {}
}

export class CloseContextMenu implements Action {
  public readonly type = ECurrentPageActions.CloseContextMenu;
}

export type CurrentPageActions = SetSelectedPage | CloseContextMenu | OpenContextMenu;
