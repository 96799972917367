import { Action } from '@ngrx/store';
import { ClauseType, NewTab, PendingAgreement, SearchQuery, TemplateType, UploadFile } from '../../core';

export enum EAgreementsActions {
  LoadPendingAgreements = '[Agreements] Load Pending Agreements',
  AddUploadingFile = '[Uploading] Add Upload File',
  RemovePendingAgreement = '[Uploading] Remove Pending Agreement',
  UpdateUploadingFile = '[Uploading] Update Upload File',
  UpdateNewTab = '[NewTab] Update',
  ResetNewTab = '[NewTab] Reset',
  LoadTemplateTypes = '[NewTab] Load template types',
  LoadClauseTypes = '[NewTab] Load clause types',
  LoadAgreements = '[Agreements] Load Agreements',
  ResetAgreements = '[Agreements] ResetAgreements',
  SetTotalAgreements = '[Agreements] Set total agreements',
  SetFilteredNumberAgreements = '[Agreements] Set filtered number agreements',
  SetSearchTerm = '[Agreements] Set search term',
  UpdateRowSelection = '[Agreements] Update selected row',
  ClearSelectedRows = '[Agreements] Clear selected rows',
  UpdateFilter = '[Agreements] Update filter',
  ResetFilter = '[Agreements] Reset filter',
  SetFilterEnabled = '[Agreements] Set filter enabled',
  SetCurrentPage = '[Agreements] Set current page',
  SetPageSize = '[Agreements] Set page size',
  UpdatePendingAgreement = '[Agreements] Update Pending Agreement',
  RemoveUploadAgreement = '[Agreements] Remove Pending Upload Agreement',
  SetSelectedTableIsShared = '[Agreements] Set Selected Table Is Shared',
  SetSelectedTableShareInformation = '[Agreements] Set Selected Table Share Information',
}

export class LoadPendingAgreements implements Action {
  public readonly type = EAgreementsActions.LoadPendingAgreements;

  constructor(public payload: Array<PendingAgreement>) {}
}

export class UpdatePendingAgreement implements Action {
  public readonly type = EAgreementsActions.UpdatePendingAgreement;

  constructor(public payload: PendingAgreement) {}
}

export class AddUploadingFile implements Action {
  public readonly type = EAgreementsActions.AddUploadingFile;

  constructor(public payload: UploadFile) {}
}

export class RemovePendingAgreement implements Action {
  public readonly type = EAgreementsActions.RemovePendingAgreement;

  constructor(public payload: PendingAgreement) {}
}

export class UpdateUploadingFile implements Action {
  public readonly type = EAgreementsActions.UpdateUploadingFile;

  constructor(public payload: UploadFile) {}
}

export class UpdateNewTab implements Action {
  public readonly type = EAgreementsActions.UpdateNewTab;

  constructor(public payload: NewTab) {}
}

export class ResetNewTab implements Action {
  public readonly type = EAgreementsActions.ResetNewTab;

  constructor(public payload: NewTab) {}
}

export class LoadTemplateTypes implements Action {
  public readonly type = EAgreementsActions.LoadTemplateTypes;

  constructor(public payload: Array<TemplateType>) {}
}

export class LoadClauseTypes implements Action {
  public readonly type = EAgreementsActions.LoadClauseTypes;

  constructor(public payload: Array<ClauseType>) {}
}

export class LoadAgreements implements Action {
  public readonly type = EAgreementsActions.LoadAgreements;

  constructor(public payload: any[]) {}
}

export class SetTotalAgreements implements Action {
  public readonly type = EAgreementsActions.SetTotalAgreements;

  constructor(public payload: number) {}
}

export class ResetAgreements implements Action {
  public readonly type = EAgreementsActions.ResetAgreements;
}

export class SetSearchTerm implements Action {
  public readonly type = EAgreementsActions.SetSearchTerm;

  constructor(public payload: SearchQuery) {}
}

export class ClearSelectedRows implements Action {
  public readonly type = EAgreementsActions.ClearSelectedRows;
}

export class UpdateRowSelection implements Action {
  public readonly type = EAgreementsActions.UpdateRowSelection;

  constructor(public documentId: string, public enabled: boolean) {}
}

export class UpdateFilter implements Action {
  public readonly type = EAgreementsActions.UpdateFilter;

  constructor(public filterType: any, public value: any, public label: string) {}
}

export class ResetFilter implements Action {
  public readonly type = EAgreementsActions.ResetFilter;
}

export class SetFilterEnabled implements Action {
  public readonly type = EAgreementsActions.SetFilterEnabled;

  constructor(public enabled: boolean) {}
}

export class SetFilteredCount implements Action {
  public readonly type = EAgreementsActions.SetFilteredNumberAgreements;

  constructor(public total: number) {}
}

export class SetCurrentPage implements Action {
  public readonly type = EAgreementsActions.SetCurrentPage;

  constructor(public pageNumber: number) {}
}

export class SetPageSize implements Action {
  public readonly type = EAgreementsActions.SetPageSize;

  constructor(public pageSize: number) { }
}

export class RemoveUploadAgreement implements Action {
  public readonly type = EAgreementsActions.RemoveUploadAgreement;

  constructor(public tempId: string) {}
}

export class SetSelectedTableIsShared implements Action {
  public readonly type = EAgreementsActions.SetSelectedTableIsShared;

  constructor(public isShared: boolean) {}
}

export class SetSelectedTableShareInformation implements Action {
  public readonly type = EAgreementsActions.SetSelectedTableShareInformation;

  constructor(public shared: any) {}
}

export type AgreementsActions =
  | SetSearchTerm
  | UpdateRowSelection
  | ClearSelectedRows
  | SetTotalAgreements
  | ResetAgreements
  | LoadAgreements
  | LoadPendingAgreements
  | UpdatePendingAgreement
  | AddUploadingFile
  | UpdateUploadingFile
  | UpdateNewTab
  | ResetNewTab
  | LoadTemplateTypes
  | LoadClauseTypes
  | RemovePendingAgreement
  | UpdateFilter
  | ResetFilter
  | SetFilteredCount
  | SetFilterEnabled
  | SetCurrentPage
  | SetPageSize
  | RemoveUploadAgreement
  | SetSelectedTableIsShared
  | SetSelectedTableShareInformation;
