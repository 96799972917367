import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BlockButtonModule, LoadingSpinnerModule, SelectBoxModule } from '@summize/shared/components-v2';

import { RegionSelectorComponent } from './region-selector.component';
import { RegionSelectorService } from './region-selector.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LoadingSpinnerModule,
        BlockButtonModule,
        SelectBoxModule
    ],
    declarations: [RegionSelectorComponent],
    providers: [RegionSelectorService],
})
export class RegionSelectorModule { }
