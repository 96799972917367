import { NgModule } from '@angular/core';

import { LoadingSpinnerModule } from '@summize/shared/components-v2';
import { FileDownloadRedirectComponent } from './file-download-redirect.component';


@NgModule({
    imports: [
        LoadingSpinnerModule
    ],
    declarations: [FileDownloadRedirectComponent],
})
export class FileDownloadRedirectModule { }
