export interface AccountState {
  documentsUsed: number;
  documentLimit: number;
  isTrial: boolean;
  hasExpired: boolean;
}

export const initialAccountState: AccountState = {
  documentsUsed: 0,
  documentLimit: 0,
  isTrial: false,
  hasExpired: false,
};
