import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() id;
  @Input() name = '';
  @Input() label = '';
  @Input() checked = false;
  @Input() styles: string = '';
  @Output() onSelected = new EventEmitter<string>();

  onClick($event: MouseEvent) {
    if ($event.target['tagName'] === 'SPAN') {
      this.checked = true;
      this.onSelected.emit(this.id);
    }
  }
}
