export enum CurrentPage {
  HomePage,
  ContractReview,
  ContractReviewConfirmParties,
  TableView,
  HowToPage,
  FaqPage,
  AgreementsPage,
  ClientsPage,
  ClientMattersPage,
}

export interface CurrentPageState {
  currentSelectedPage: CurrentPage;
  contextMenuOpen: boolean;
  contextMenuItems: {
    name: string;
    event: Function;
  }[];
  contextMenuPosition: {
    x: number;
    y: number;
  };
}

export const initialCurrentPageState: CurrentPageState = {
  currentSelectedPage: undefined,
  contextMenuOpen: false,
  contextMenuItems: [],
  contextMenuPosition: {
    x: 0,
    y: 0,
  },
};
