import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { FeatureFlagService } from '../services/feature-flag/feature-flag.service';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {

    constructor(private userService: UserService,
        private featureFlagService: FeatureFlagService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        this.userService.loadLocalUser();

        const requiredFeatureFlag = route.data.requiredFeatureFlag as string;

        const requiresTenantAdmin = route.data.requireIsTenantAdmin as boolean;

        const requiredClaims = route.data.requiredClaims as Array<string>;

        if (requiredFeatureFlag !== undefined &&
            this.featureFlagService.get(requiredFeatureFlag) !== true) {

            return false;

        }

        if (requiresTenantAdmin === true &&
            this.userService.getIsAdmin() !== true) {

            return false;

        }

        if (requiredClaims !== undefined && requiredClaims.length > 0 &&
            this.userService.hasClaims(requiredClaims) !== true) {

            return false;

        }

        return true;

    }

}
