import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent, DocusignService } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';


@Component({
    template: ``
})
export class DocusignRedirectComponent extends AppComponent implements OnInit, OnDestroy {

    private authCode: string;

    private state: any;

    private documentId: string;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private events: EventService,
        private service: DocusignService) {
        super();
    }

    public ngOnInit() {

        this.subscribe(this.route.queryParams, async (args) => {

            this.authCode = args['code'];

            this.state = args['state'];

            try {

                const docusignDetails = await this.service.getDocusignInitialInformation(this.authCode);

                const jsonState = JSON.parse(this.state);

                this.documentId = jsonState.documentId;

                if (docusignDetails.docusignUserAccounts.length > 0) {

                    const displayName = docusignDetails.docusignUserAccounts[0].email;

                    // Save personal
                    await this.service.saveDocusignPersonalAccount(displayName, docusignDetails.docusignUserAccounts, docusignDetails.docusignAccessToken);

                    const queryParams = {

                        action: 'sign',
                        signatureMethod: encodeURIComponent(`DocuSign: ${displayName}`)

                    }

                    this.router.navigate(['contract', jsonState.documentId], { queryParams: queryParams });

                } else {

                    this.router.navigate(['contract', jsonState.documentId]);
                }

            } catch (err) {

                console.error(err);

                if (err.status === 409) {

                    this.events.actionFailed(err.error);

                    this.router.navigate(['contract', this.documentId]);

                }
            }

        });
    }

}