import { initialContractReviewState, ContractReviewState } from '../state/contract-review.state';
import { ContractReviewActions, EContractReviewActions } from '../actions/contract-review.actions';
import { Clause } from '../../core/models';

export function contractReviewReducers(state: ContractReviewState = initialContractReviewState, action: ContractReviewActions): ContractReviewState {
  switch (action.type) {
    case EContractReviewActions.LoadClauses: {

      if (action.payload === null) {

        return state;

      }

      return {
        ...state,
        clauses: Object.values(action.payload.results),
        documentId: action.payload.documentId,
        documentName: action.payload.documentName,
        parties: action.payload.parties,
        templateId: action.payload.templateId,
        tenantTemplateId: action.payload.tenantTemplateId,
        documentStatusId: action.payload.documentStatusId,
        documentStatusName: action.payload.documentStatusName,
        documentIsShared: action.payload.isShared,
        assignedTo: action.payload.assignedTo,
        assignedToName: action.payload.assignedToName,
        assignedBy: action.payload.assignedBy,
        assignedByName: action.payload.assignedByName,
        assignedAt: action.payload.assignedAt
      };
    }
    case EContractReviewActions.SetTemplateTypeId: {
      return {
        ...state,
        tenantTemplateId: action.templateId
      };
    }
    case EContractReviewActions.SetSelectedClause: {
      return {
        ...state,
        selectedClause: action.clause,
        selectedClauseName: action.clause?.ruleName,
      };
    }
    case EContractReviewActions.SetRelatedParagraphs: {
      return {
        ...state,
        relatedParagraphs: action.relatedParas,
      };
    }
    case EContractReviewActions.SetRelatedParagraphId: {
      return {
        ...state,
        relatedParagraphId: action.relatedParaId,
      };
    }
    case EContractReviewActions.SetSelectedClauseName: {
      return {
        ...state,
        selectedClauseName: action.clauseName,
        selectedClause: null,
      };
    }
    case EContractReviewActions.ResetSelectedClause: {
      return {
        ...state,
        selectedClauseName: null,
        selectedClause: null,
      };
    }
    case EContractReviewActions.SetSelectedClauseResult: {
      return {
        ...state,
        selectedClauseResult: action.clauseResult,
      };
    }
    case EContractReviewActions.AddClauseResult: {
      const clause = state.clauses.find((c) => c.ruleName === action.clauseName);
      if (!clause) {
        throw new Error(`Could not find clause "${action.clauseName}" to add rule`);
      }
      const clauseIndex = state.clauses.indexOf(clause);

      const newRuleResult = {
        paragraphNumber: action.clauseResultParaId,
        resultText: action.clauseResultText,
        loadingStatus: action.loadingStatus,
      };

      const updatedClause = {
        ...clause,
        ruleResult: [...clause.ruleResult, newRuleResult],
      };
      const updatedClauses = [...state.clauses.slice(0, clauseIndex), updatedClause, ...state.clauses.slice(clauseIndex + 1)];
      return {
        ...state,
        clauses: updatedClauses,
      };
    }
    case EContractReviewActions.UpdateClauseResultLoadingStatus: {
      const clause = state.clauses.find((c) => c.ruleName === action.clauseName);
      if (!clause || !clause.ruleResult[action.ruleIndex]) {
        throw new Error('Could not find rule result to update status');
      }
      const clauseIndex = state.clauses.indexOf(clause);

      const ruleResultIndex = clause.ruleResult.indexOf(clause.ruleResult[action.ruleIndex]);
      const rule = {
        ...clause.ruleResult[action.ruleIndex],
        loadingStatus: action.loadingStatus,
      };

      let updatedClause;
      if (clause.ruleResult.length > 1) {
        updatedClause = {
          ...clause,
          ruleResult: [...clause.ruleResult.slice(0, ruleResultIndex), rule, ...clause.ruleResult.slice(ruleResultIndex + 1)],
        };
      } else {
        updatedClause = {
          ...clause,
          ruleResult: [rule],
        };
      }

      const updatedClauses = [...state.clauses.slice(0, clauseIndex), updatedClause, ...state.clauses.slice(clauseIndex + 1)];

      return {
        ...state,
        clauses: updatedClauses,
      };
    }
    case EContractReviewActions.ResetAll: {
      return initialContractReviewState;
    }
    case EContractReviewActions.SetSelectedClauseFlag: {
      const clauseToEdit: Clause = state.clauses.find((c) => c.ruleName === action.clause.ruleName);
      if (!clauseToEdit) {
        throw new Error('Could not find clause to update flag');
      }
      const updatedClause = {
        ...clauseToEdit,
        isFlagged: action.clause.isFlagged,
      };
      const index = state.clauses.indexOf(clauseToEdit);
      const updatedClauses = [...state.clauses.slice(0, index), updatedClause, ...state.clauses.slice(index + 1)];
      return {
        ...state,
        clauses: updatedClauses,
      };
    }
    case EContractReviewActions.UpdateClauseResultText: {
      const clauseToEdit: Clause = state.clauses.find((c) => c.ruleName === action.clause.ruleName);
      if (!clauseToEdit) {
        throw new Error('Could not find clause to update result text');
      }
      let updatedClause = {
        ...clauseToEdit,
      };

      updatedClause.ruleResult = [...clauseToEdit.ruleResult];

      updatedClause.ruleResult[action.resultIndex] = { ...updatedClause.ruleResult[action.resultIndex], resultText: action.text };

      const index = state.clauses.indexOf(clauseToEdit);
      const updatedClauses = [...state.clauses.slice(0, index), updatedClause, ...state.clauses.slice(index + 1)];
      return {
        ...state,
        clauses: updatedClauses,
      };
    }
    case EContractReviewActions.UpdateComment: {
      const clauseToUpdate: Clause = state.clauses.find((c) => c.clauseId === action.clauseId);
      if (!clauseToUpdate) {
        throw new Error('Could not find clause to update');
      }

      const updatedClause: Clause = {
        ...clauseToUpdate,
        clauseComments: action.comments,
      };
      const index = state.clauses.indexOf(clauseToUpdate);
      const updatedClauses = [...state.clauses.slice(0, index), updatedClause, ...state.clauses.slice(index + 1)];

      return {
        ...state,
        clauses: updatedClauses,
      };
    }
    case EContractReviewActions.ShowPlaybookSummary: {
      return {
        ...state,
        activePlaybookPreview: action.playbookPreview,
      };
    }
    case EContractReviewActions.HidePlaybookSummary: {
      return {
        ...state,
        activePlaybookPreview: null,
      };
    }
    case EContractReviewActions.SetSelectedDocumentId: {
      return {
        ...state,
        documentId: action.documentId,
      };
    }
    case EContractReviewActions.SetSelectedDocumentIsShared: {
      return {
        ...state,
        documentIsShared: action.isShared,
      };
    }
    case EContractReviewActions.ExpandAllClauses: {
      return {
        ...state,
        expandAllClauses: {
          value: true
        },
      };
    }
    case EContractReviewActions.CollapseAllClauses: {
      return {
        ...state,
        expandAllClauses: {
          value: false
        },
      };
    }
    case EContractReviewActions.SetDocumentAssignedTo: {
      return {
        ...state,
        assignedTo: action.documentAssigned.assignedTo,
        assignedToName: action.documentAssigned.assignedToName,
        assignedBy: action.documentAssigned.assignedBy,
        assignedByName: action.documentAssigned.assignedByName,
        assignedAt: action.documentAssigned.assignedAt
      };
    }

    default:
      return state;
  }
}
