import { createReducer, on } from '@ngrx/store';
import { initialExportBuilderState, ExportBuilderState } from '../state/export-builder.state';
import { updateArray } from '../../core/util/reduxUtils';
import { ExportFormat, ExportItem } from '../../core';
import {
  resetExportBuilder,
  loadExportClauses,
  exportButtonEnabled,
  updateActiveExportFormat,
  updateExportItem,
  updateExportType,
  updateSelectedExportDocuments,
  clearExportItems,
  setExcelSingleRow,
} from '../actions/export-builder.actions';

export const exportBuilderReducers = createReducer(
  initialExportBuilderState,
  on(resetExportBuilder, () => initialExportBuilderState),
  on(clearExportItems, (state: ExportBuilderState) => ({
    ...state,
    exportItems: [],
  })),
  on(updateExportItem, (state: ExportBuilderState, { exportItem }) => {
    const updatedArray: ExportItem[] = updateArray(state.exportItems, (item) => item.type === exportItem.type, exportItem);
    return {
      ...state,
      exportItems: updatedArray,
    };
  }),
  on(loadExportClauses, (state: ExportBuilderState, { exportClauses }) => ({ ...state, exportClauses })),
  on(exportButtonEnabled, (state: ExportBuilderState, { enabled }) => ({ ...state, exportButtonEnabled: enabled })),
  on(updateActiveExportFormat, (state: ExportBuilderState, { format }) => ({
    ...state,
    activeExportMode: format,
    exportButtonEnabled: format !== undefined && format !== null,
  })),
  on(updateExportType, (state: ExportBuilderState, { exportType }) => ({ ...state, exportType })),
  on(updateSelectedExportDocuments, (state: ExportBuilderState, { selectedDocIds }) => ({ ...state, exportDocumentIds: selectedDocIds })),
  on(setExcelSingleRow, (state: ExportBuilderState, { excelSingleRow }) => ({ ...state, excelSingleRow }))
);
