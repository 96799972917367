import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state/app.state';
import { StoreComponent } from '../../util/store-component';
import { MatDialog } from '@angular/material/dialog';

import { selectDocumentLimit, selectDocumentsUsed, selectHasExpired } from '../../../store/selectors/account.selectors';

@Component({
  selector: 'app-document-counter',
  templateUrl: './document-counter.component.html',
  styleUrls: ['./document-counter.component.scss'],
})
export class DocumentCounterComponent extends StoreComponent implements OnInit {
  hasExpired = false;
  documentsLeft: number;
  trialTitle: string = 'Trial version';
  private limit: number = 1; // Default to a number that has 'docs left'
  private documentsUsed: number = 0;

  constructor(
    protected store: Store<AppState>,
    private dialog: MatDialog
  ) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.select(selectHasExpired, hasExpired => {
      this.hasExpired = hasExpired;
      this.trialTitle = this.hasExpired ? 'Trial expired' : 'Trial version';
    });
    this.select(selectDocumentLimit, limit => {
      this.limit = limit;
      this.calculateDocumentsLeft();
    });
    this.select(selectDocumentsUsed, documentsUsed => {
      this.documentsUsed = documentsUsed;
      this.calculateDocumentsLeft();
    });
  }

  calculateDocumentsLeft() {
    this.documentsLeft = Math.max(0, this.limit - this.documentsUsed);
  }

  upgradeClicked() {
 
  }
}
