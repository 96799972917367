export enum ChatMessageFromType {
    User = 1,
    Group = 2
}

export interface ChatMessageFrom {
    FromType: ChatMessageFromType;
    Value: string;
}

export enum ChatMessageBodyType {
    String = 1,
    Json = 2
}

export interface ChatMessageBody {
    BodyType: ChatMessageBodyType;
    Value: string;
}

export interface ChatMessage {
    From: ChatMessageFrom;
    Body: ChatMessageBody;
}
