import { Template } from '../../core';

export interface DocumentTypeState {
  documentTypes: Template[];
  selectedDocumentType: Template;
}

export const initialDocumentTypeState: DocumentTypeState = {
  documentTypes: [], // templates
  selectedDocumentType: undefined, // selected template tab
};
