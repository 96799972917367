import { createSelector } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { CommonState } from '../state/common.state';

const common = (state: AppState) => state.common;

export const selectToastState = createSelector(common, (state: CommonState) => state.toast);

export const selectUsersName = createSelector(common, (state: CommonState) => {
  return state.user ? state.user.firstName : '';
});

export const selectUser = createSelector(common, (state: CommonState) => state.user);

export const selectUserDepartment = createSelector(common, (state: CommonState) => state.user.departmentName);

export const selectTenantLogo = createSelector(common, (state: CommonState) => state.tenantLogo);

export const selectFeedback = createSelector(common, (state: CommonState) => state.isFeedbackOpen);
