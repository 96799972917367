import { Component, EventEmitter, Output, Input, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'app-filedragzone',
  templateUrl: './filedragzone.component.html',
  styleUrls: ['./filedragzone.component.scss']
})
export class FileDragZoneComponent {
  @Input() description = 'contracts';
  @Input() isTrial = false;
  @Output('fileDrop') fileDrop = new EventEmitter<Array<File>>();
  isActive = false;

  // Disable dropping on the body of the document.
  // This prevents the browser from loading the dropped files
  // using it's default behaviour if the user misses the drop zone.
  // Set this input to false if you want the browser default behaviour.
  @Input() preventBodyDrop = true;

  show() {
    this.isActive = true;
  }

  hide() {
    this.isActive = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();

    const { dataTransfer } = event;

    let files = [];

    if (dataTransfer.items) {
      for (let i = 0; i < dataTransfer.items.length; i++) {
        if (dataTransfer.items[i].kind === 'file') {
          const file = dataTransfer.items[i].getAsFile();
          const fileType = file?.type?.toLowerCase();

          if ((fileType === 'application/pdf' && !this.isTrial) || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            files.push(file);
          }
        }
      }
      dataTransfer.items.clear();
    } else {
      files = Array.from(dataTransfer.files);
      dataTransfer.clearData();
    }

    this.fileDrop.emit(files);
    this.hide();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.fileDrop.emit([]);
  }

  @HostListener('body:dragleave', ['$event'])
  onBodyDragLeave(event: DragEvent) {

    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('body:dragover', ['$event'])
  onBodyDragOver(event: DragEvent) {

    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('body:drop', ['$event'])
  onBodyDrop(event: DragEvent) {

    if (this.preventBodyDrop) {
      event.preventDefault();
    }
  }
}
