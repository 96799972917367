/**
 * Rather than include moment.js for date-fns, just map numbers to names.
 */

const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const nameOfDay = (day) => {
    return days[day];
};
