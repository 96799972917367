import { UserService } from './../../../../core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../../../../store/state/app.state';
import { StoreComponent } from '../../../../core/util/store-component';
import { OpenCalendar, IsEventDialogVisible, IsTerminationDialogVisible } from '../../../../store/actions/contract-calendar.actions';
import { calendarOpen, selectShowAddEventControls } from '../../../../store/selectors/contract-calendar.selectors';

@Component({
  selector: 'cc-global-button',
  templateUrl: './contract-calendar-button.component.html',
  styleUrls: ['./contract-calendar-button.component.scss'],
})
export class ContractCalendarButtonComponent extends StoreComponent implements OnInit {
  public expanded = false;
  public isCalendarOpen = false;
  public showAddEventControls = false;

  constructor(
    protected store: Store<AppState>,
    private router: Router,
    private userService: UserService) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.select(calendarOpen, isOpen => this.isCalendarOpen = isOpen);
    this.select(selectShowAddEventControls, visible => {
      if (visible && this.userService.hasClaims(['CanCalendarEventsCreate'])) {
        this.showAddEventControls = true;
      } else {
        this.showAddEventControls = false;
      }
    });
  }

  openCalendar() {
    this.store.dispatch(new OpenCalendar(true));
  }

  addTermination() {
    this.store.dispatch(new IsTerminationDialogVisible(true));
  }

  addEvent() {
    this.store.dispatch(new IsEventDialogVisible(true));
  }

}
