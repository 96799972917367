import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-horizontal-button-scrollbar',
    templateUrl: './horizontal-button-scrollbar.component.html',
    styleUrls: ['./horizontal-button-scrollbar.component.scss']
})
export class HorizontalButtonScrollbarComponent implements OnInit, AfterViewInit {
    @ViewChild('tabContainer') tabContainer: ElementRef;
    @ViewChild('leftBtn') leftBtn: ElementRef;
    @ViewChild('rightBtn') rightBtn: ElementRef;

    showLeftBtn: boolean;
    showRightBtn: boolean;
    position = 0;

    constructor() {
    }

    ngOnInit(): void {
        this.showRightBtn = true;
        this.showLeftBtn = false;
    }

    ngAfterViewInit() {
        this.tabContainer.nativeElement.style.left = 0;
    }

    onRightClick() {
        this.position = this.position -= 400;
        this.updateScrollButtons();
    }

    onLeftClick() {
        this.position = this.position += 400;
        this.updateScrollButtons();
    }

    private updateScrollButtons() {
        const scrollableWidth = this.getScrollableWidth();

        this.position = (this.position > scrollableWidth ? this.position : scrollableWidth);
        this.position = (this.position > 0 ? 0 : this.position);

        this.showRightBtn = this.position > scrollableWidth;
        this.showLeftBtn = this.position < 0;

        this.tabContainer.nativeElement.style.left = `${this.position}px`;
    }

    private getScrollableWidth() {
        const parentWidth = this.tabContainer.nativeElement.parentNode.clientWidth;
        const tabContainerWidth = this.tabContainer.nativeElement.clientWidth;
        return parentWidth - tabContainerWidth;
    }
}
