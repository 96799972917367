import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { DirectivesModule, IconModule } from '@summize/shared/components-v2';

import { CalendarSyncComponent } from './calendar-sync.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
        IconModule,
        DirectivesModule
    ],
    exports: [
        CalendarSyncComponent
    ],
    declarations: [
        CalendarSyncComponent
    ]
})
export class CalendarSyncModule { }

export * from './calendar-sync.component';

export * from './calendar-sync.helper';

export * from './calendar-sync.service';
