import { Component, Inject } from '@angular/core';

import { Environment } from '@summize/shared/framework';
import { NavigationService } from '@summize/shared/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  constructor(
    @Inject(Environment) environment, 
     
    // NavigationService is injected here to ensure it is initialized 
    // early in the application lifecycle, allowing it to capture initial
    // router events that might otherwise be missed.
    private navigationService: NavigationService) {

    // SteO - Sorta of a work around to make sure env is always included
    // in the injector score here, so injected and just read to make sure its 
    // not shaked from the build
    console.log(`Summize: ${environment.publicSiteUrl}`);

  }

}
