import { Clause, ClauseResult } from '../../core';
import { RelatedPara } from '../../core/models/relatedPara.model';
import { PlaybookPreview } from '../../core/models/playbook-preview.model';

export interface ContractReviewState {
  clauses: Clause[];
  documentId: string;
  documentName: string;
  documentStatusId: number;
  documentStatusName: string;
  templateId: string;
  tenantTemplateId: string;
  parties: Array<string>;
  relatedParagraphs: Array<RelatedPara>;
  selectedClauseName: string;
  relatedParagraphId: number;
  selectedClause: Clause;
  selectedClauseResult: ClauseResult;
  activePlaybookPreview: PlaybookPreview;
  documentIsShared: boolean;
  assignedTo: string;
  assignedToName: string;
  assignedBy: string;
  assignedByName: string;
  assignedAt: string;
  expandAllClauses: { value: boolean };
}

export const initialContractReviewState: ContractReviewState = {
  clauses: [],
  documentId: null,
  documentName: null,
  documentStatusId: 1,
  documentStatusName: 'No status',
  parties: [],
  templateId: null,
  tenantTemplateId: null,
  relatedParagraphs: [],
  selectedClauseName: null,
  relatedParagraphId: null,
  selectedClause: null,
  selectedClauseResult: null,
  activePlaybookPreview: null,
  documentIsShared: false,
  assignedTo: null,
  assignedToName: null,
  assignedBy: null,
  assignedByName: null,
  assignedAt: null,
  expandAllClauses: { value: true }
};
