import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, share, tap } from 'rxjs';


import { AuthService } from '@summize/shared/framework';
import { environment } from '../../../environments/environment';
import { CorrelationIdService } from '../services/correlation-id.service';
import { UserService } from '../services/user.service';
import { TokenService } from '../services/token.service';

export const InterceptorSkipAuthValidation = 'X-Skip-Auth-Validation';
export const InterceptorSkipAuthBearer = 'X-Skip-Auth-Bearer';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private bearerTokenService: TokenService,
    private correlationIdService: CorrelationIdService,
    private userService: UserService,
    private authService: AuthService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headers = req.headers;
    let skipAuthValidation = false;
    let skipAuthBearer = false;

    if (req.headers.has(InterceptorSkipAuthValidation)) {
      headers = headers.delete(InterceptorSkipAuthValidation);
      skipAuthValidation = true;
    }

    if (req.headers.has(InterceptorSkipAuthBearer)) {
      headers = headers.delete(InterceptorSkipAuthBearer);
      skipAuthBearer = true;
    }

    const isExternalRequest =
      skipAuthBearer === true ||
      req.url.includes(environment.storageLocation) === true ||
      req.url.includes(environment.originalLocation) === true ||
      req.url.includes(environment.logoUploadLocation) === true;

    if (isExternalRequest === false) {

      // Required to ensure cookies are sent with requests.
      req = req.clone({
        withCredentials: true
      });

      const bearerToken = this.bearerTokenService.getToken();
      if (bearerToken && bearerToken !== '') {
        headers = headers.set('Authorization', `Bearer ${bearerToken}`);
        if (this.correlationIdService.getCorrelationId()) {
          headers = headers.set('x-correlation-id', this.correlationIdService.getCorrelationId());
        }
      }

    }

    const request = req.clone({ headers });

    const httpEvent = next.handle(request).pipe(share(), tap(() => {
      (event) => {
        if (event instanceof HttpResponse) {
          const corrId = event.headers.get('x-correlation-id');
          if (corrId) {
            this.correlationIdService.saveCorrelationId(corrId);
          }
        }
      }
    }));

    httpEvent.toPromise().catch(err => {
      if (err.status === 401) {
        if (skipAuthValidation) {
          return;
        }

        this.userService.purgeAuth();
        this.authService.login({ autoLogin: false });
      }

      if (err.status === 402) {
        // ToDo: Need to trigger dialog for upgrade
        console.log("Payment needed");
        return;
      }
    });

    return httpEvent;
  }
}
