import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { contractReviewReducers } from './contract-review.reducers';
import { agreementsReducers } from './agreements.reducers';
import { documentTypesReducers } from './document-type.reducers';
import { currentPageReducers } from './current-page.reducer';
import { contractCalendarReducers } from './contract-calendar.reducers';
import { commonReducers } from './common.reducers';
import { exportBuilderReducers } from './export-builder.reducers';
import { accountReducers } from './account.reducers';

export const appReducers: ActionReducerMap<AppState, any> = {
  currentPage: currentPageReducers,
  contractReview: contractReviewReducers,
  agreements: agreementsReducers,
  documentTypes: documentTypesReducers,
  contractCalendar: contractCalendarReducers,
  common: commonReducers,
  exportBuilder: exportBuilderReducers,
  account: accountReducers,
};
