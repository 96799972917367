import { NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { SummizeStorage } from '@summize/shared/framework';

import { ContractResolver } from './resolvers/contract.resolver';
import { CoreComponentsModule } from './components/components.module';
import { GroupByPipe } from './pipes/group-by.pipe';
import { HttpTokenInterceptor } from './interceptors';
import { UserResolver } from './resolvers/user-resolver';

export function storageFactory(): OAuthStorage {

    return SummizeStorage.localStorage;

}

@NgModule({
    declarations: [
        GroupByPipe,
    ],
    exports: [
        CoreComponentsModule,
        FormsModule,
        GroupByPipe,
        ReactiveFormsModule,
        RouterModule
    ],
    imports: [
        CoreComponentsModule,
        FormsModule,
        OAuthModule.forRoot(),
        ReactiveFormsModule,
        RouterModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        { provide: OAuthStorage, useFactory: storageFactory },
        ContractResolver,
        UserResolver,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class CoreModule { }
