import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent, FileDownloadService } from '@summize/shared/core';

@Component({
    templateUrl: 'file-download-redirect.html',
    styleUrls: ['./file-download-redirect.scss']
})

export class FileDownloadRedirectComponent extends AppComponent implements OnInit, OnDestroy {

    constructor( private router: Router,
        private route: ActivatedRoute,
        private service: FileDownloadService){
        super();
    }

    public ngOnInit(): void {

        this.subscribe(this.route.queryParams, async (obj) => {

            try {

                if(obj !== undefined ){

                    await this.service.downloadFile(obj.request);

                    this.router.navigate([`contracts`]);

                } else {
                    
                    this.router.navigateByUrl('/problem?display=error-retry');
                }

            } catch (err) {

                console.error(err);

                this.router.navigateByUrl('/problem?display=error-retry');

            }
        });
    }
}