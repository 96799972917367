
export interface UploadFile {
  file: File;
  status: UploadStatus;
  documentTypeId: string;
  progress: number;
  documentId: string;
  clientId: string;
  matterId: string;
  tempId?: string;
}

export interface AzureBlobUploadDetails {
  uploadedFileData: Array<FileData>;
  cloudLocation: string;
  fileAccessTokens: Array<AzureBlobFileAccessToken>;
}

export interface Usage {
  documentLimit: number;
  documentsUsed: number;
}

export interface AzureBlobFileAccessToken {
  documentName: string;
  token: string;
  documentId: string;
}

export interface FileData {
  tenantId: string;
  templateId: string;
  documentId: string;
  correlationId: string;
  documentName: string;
  userId: string;
  type: number;
  path: string;
  departmentId: string;
  clientId: string;
  matterId: string;
}

export interface UploadCompleteMessage {
  documentId: string;
  tenantId: string;
}

export enum UploadStatus {
  UPLOADING = 'UPLOADING',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export class LinkedFileData implements FileData {
  public parentId: string;

  constructor(fileDatum: FileData, parentDocumentId: string) {
    this.correlationId = fileDatum.correlationId;
    this.documentId = fileDatum.documentId;
    this.documentName = fileDatum.documentName;
    this.path = fileDatum.path;
    this.templateId = fileDatum.templateId;
    this.tenantId = fileDatum.tenantId;
    this.userId = fileDatum.userId;
    this.parentId = parentDocumentId;
  }


  public correlationId: string;
  public documentId: string;
  public documentName: string;
  public path: string;
  public templateId: string;
  public tenantId: string;
  public type: number;
  public userId: string;
  public departmentId: string;
  public clientId: string;
  public matterId: string;
}
