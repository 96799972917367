import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';

import { UserResolver } from './core/resolvers/user-resolver';
import { AuthGuard } from './core/guards/auth-guard.service';
import { FeatureFlagGuard } from './core/guards/feature-flag-guard.service';
import { RegionGuard } from './core/guards/region-guard.service';
import { ClmRouteGuard } from './core/guards/clm-route.guard';
import { NoAccessComponent } from './core/components/no-access/no-access.component';
import { LoginProblemComponent } from './core/components/login-problem/login-problem.component';
import { RegionSelectorComponent } from './core/components/region-selector/region-selector.component';
import { ContractRedirectComponent } from './core/components/contract-redirect/contract-redirect.component';
import { RemoteProxyComponent } from './core/components/remote-proxy/remote-proxy.component';
import { DocusignRedirectComponent } from './core/components/docusign-redirect/docusign-redirect.component';
import { AdobeSignRedirectComponent } from './core/components/adobesign-redirect/adobesign-redirect.component';
import { FileDownloadRedirectComponent } from './core/components/file-download-redirect/file-download-redirect.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./modules/my-contracts/my-contracts.module').then(mod => mod.MyContractsModule),
    canActivate: [RegionGuard],
    resolve: {
      user: UserResolver,
    },
  },
  {
    path: 'my-contracts',
    loadChildren: () => import('./modules/my-contracts/my-contracts.module').then(mod => mod.MyContractsModule),
    canActivate: [RegionGuard, ClmRouteGuard],
    resolve: {
      user: UserResolver,
    },
  },
  {
    path: 'settings',
    canActivate: [RegionGuard, AuthGuard],
    loadChildren: () => import('./modules/settings-shell/settings-shell.module').then(mod => mod.SettingsShellModule),
    resolve: {
      user: UserResolver,
    },
  },
  {
    path: '',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/public-site/home.module').then(mod => mod.HomeModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'admin',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/admin-shell/admin-shell.module').then(m => m.AdminShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'clause-manager',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/clause-manager-shell/clause-manager-shell.module').then(m => m.ClauseManagerShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'inbox',
    loadChildren: () => import('./modules/inbox-shell/inbox-shell.module').then(m => m.InboxShellModule),
    canActivate: [
      RegionGuard,
      AuthGuard,
      FeatureFlagGuard
    ],
    data: {
      requiredFeatureFlag: 'Inbox'
    },
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'search',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/global-search-shell/global-search-shell.module').then(m => m.GlobalSearchShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'playbook-manager',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/playbook-manager-shell/playbook-manager-shell.module').then(m => m.PlaybookManagerShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'playbooks',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/playbooks-shell/playbooks-shell.module').then(m => m.PlaybooksShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'quick-summary',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/quick-summary-shell/quick-summary-shell.module').then(m => m.QuickSummaryShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'create',
    canActivate: [RegionGuard, ClmRouteGuard],
    loadChildren: () => import('./modules/create-shell/create-shell.module').then(m => m.CreateShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'request-template',
    canActivate: [RegionGuard, ClmRouteGuard],
    loadChildren: () => import('./modules/request-template-shell/request-template-shell.module').then(m => m.RequestTemplateShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'contracts',
    loadChildren: () => import('./modules/manage-shell/manage-shell.module').then(m => m.ManageShellModule),
    canActivate: [RegionGuard],
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'custom-fields',
    loadChildren: () => import('@summize/feature/custom-fields').then(m => m.CustomFieldsModule),
    canActivate: [
      RegionGuard,
      AuthGuard,
      FeatureFlagGuard
    ],
    data: {
      requiredFeatureFlag: 'CustomFields'
    },
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'diagnostics',
    canActivate: [RegionGuard],
    loadChildren: () => import('./modules/diagnostics-shell/diagnostics-shell.module').then(m => m.DiagnosticsShellModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'export',
    canActivate: [RegionGuard],
    loadChildren: () => import('@summize/feature/export').then(m => m.ExportBuilderModule),
    resolve: {
      user: UserResolver,
    }
  },
  {
    path: 'region',
    component: RegionSelectorComponent
  },
  {
    path: 'no-access',
    component: NoAccessComponent
  },
  {
    path: 'problem',
    component: LoginProblemComponent
  },
  {
    path: 'remote',
    component: RemoteProxyComponent
  },
  {
    path: 'contract/:id',
    component: ContractRedirectComponent,
    resolve: {
      user: UserResolver,
    },
    canActivate: [
      RegionGuard,
      AuthGuard
    ]
  },
  {
    path: 'docusign/:id',
    component: DocusignRedirectComponent,
    resolve: {
      user: UserResolver,
    },
    canActivate: [
      RegionGuard,
      AuthGuard
    ]
  },
  {
    path: 'adobesign',
    component: AdobeSignRedirectComponent,
    resolve: {
      user: UserResolver,
    },
    canActivate: [
      RegionGuard,
      AuthGuard
    ]
  },
  {
    path: 'file-download',
    component: FileDownloadRedirectComponent,
    resolve: {
      user: UserResolver,
    },
    canActivate: [
      RegionGuard,
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: NoPreloading,
        scrollPositionRestoration: 'enabled'
      },
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
