import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { CalendarV2Component, CalendarV2Service, SelectedPanelVisbilityEnum } from '@summize/feature/calendar-v2';
import { CardType, SlidePanelService, SlidePanelSettings } from '@summize/shared/components-v2';
import { EventService, ShowToastMessage } from '@summize/shared/framework';

import { StoreComponent } from '../../../../core/util/store-component';
import { AppState } from '../../../../store/state/app.state';
import { selectDiaryEvents, selectSelectedDate } from '../../../../store/selectors/contract-calendar.selectors';
import { OpenCalendar } from '../../../../store/actions/contract-calendar.actions';
import { CalendarService } from 'apps/web-app/src/app/core/services/calendar.service';

@Component({
    selector: 'cc-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends StoreComponent implements OnInit {

    isCalendarOpen = false;
    selectedDate: Date;
    matterId: string;
    clientId: string;

    public loading: boolean = true;

    public calendarDatesSorted: any;
    private calendarEvents: any;

    constructor(
        protected store: Store<AppState>,
        private calendarService: CalendarService,
        private calendarV2Service: CalendarV2Service,
        private eventService: EventService,
        private panelService: SlidePanelService
    ) {
        super(store);
    }

    public async ngOnInit() {

        super.ngOnInit();

        this.select(selectSelectedDate, async (date: Date) => {
            this.selectedDate = date;
            await this.loadEvents();
        });

        this.select(selectDiaryEvents, () => this.loading = false);

        this.calendarOpenStateChanged(true);

    }

    close() {
        this.store.dispatch(new OpenCalendar(false));
    }

    private calendarOpenStateChanged(isOpen: boolean) {
        this.isCalendarOpen = isOpen;

        if (isOpen) {
            document.getElementsByTagName('body')[0].classList.add('modal--open');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal--open');
        }
    }

    private async loadEvents() {

        if (!this.selectedDate) {

            return;

        }

        this.loading = true;

        this.calendarEvents = await this.calendarV2Service.loadTenantEventsForMonth(this.selectedDate);

        this.calendarDatesSorted = this.calendarV2Service.loadCalendarCards(this.calendarEvents);

        // TODO - Remove as part of phase 2.
        this.calendarService.updateCalendarEventStoreForDatePicker(this.calendarEvents);

        this.loading = false;

    }

    public async onCardEvent($event: any) {

        const selectedCalendarEvent = this.calendarEvents.find(x => x.id === $event?.date.id);

        const selectedPanel = selectedCalendarEvent?.eventType.id === CardType.Standard ?
            SelectedPanelVisbilityEnum.StandardEdit : SelectedPanelVisbilityEnum.TerminationEdit;

        switch ($event.name) {
            case 'edit':
                this.openCalendarEventsPanel(selectedCalendarEvent, selectedPanel);
                break;
            case 'download':
                await this.downloadEvent($event.date.id);
                break;
            case 'delete':
                await this.deleteCalendarEvent($event.date.id);
                break;
        }

    }

    public async deleteCalendarEvent(id: string) {
        try {

            await this.calendarV2Service.deleteCalendarEvent(id);

            this.eventService.despatch(ShowToastMessage, {
                text: `Calendar event deleted.`,
            });

            await this.loadEvents();

        } catch (err) {

            this.eventService.despatch(ShowToastMessage, {
                text: `Error deleting calendar event.`,
                type: 'error',
                icon: 'alert-circle'
            });
        }


    }

    public async downloadEvent(id: string): Promise<void> {

        const data: any = await this.calendarV2Service.downloadEvent(id);

        const downloadURL = window.URL.createObjectURL(data);

        const link = document.createElement('a');

        link.href = downloadURL;

        link.download = `${id}.ics`;

        link.click();

    }

    private openCalendarEventsPanel(selectedCalendarEvent, selectedPanel) {

        const settings: SlidePanelSettings = {
            component: CalendarV2Component,
            arguments: {
                selectedPanel: selectedPanel,
                calendarEvents: this.calendarEvents,
                selectedCalendarEvent: selectedCalendarEvent,
            },
            title: '',
            closeable: true,
            size: 'lg',
            backdrop: true
        };

        this.panelService.show(settings);

    }
}
