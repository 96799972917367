import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AutocompleteChipGroupModel, AutocompleteGroupModel, CardType, NotificationItem, SlidePanelComponent, SlidePanelContent } from '@summize/shared/components-v2';
import { CalendarEventType, EventService, ShowToastMessage } from '@summize/shared/framework';

import { CalendarV2Service } from './calendar-v2.service';

export interface CalendarEvent {
    id: string;
    documentId: string;
    eventDate: Date;
    eventDateDayStr: string;
    eventDateDay: number;
    calendarEventType: number;
    clauseId: number;
    description: string;
    noticePeriodDays: number;
    reminderDates: any;
    clauseName: string;
    parties: any;
    notifications: Array<EventNotification>;
    isEvergreen: boolean;
}

export interface EventNotification {
    id: string;
    notificationEntityType: number;
    notificationEntityId: string;
    displayName?: string;
}

export enum SelectedPanelVisbilityEnum {
    StandardEvent = 'StandardEvent',
    TerminationEvent = 'TerminationEvent',
    CalendarCards = 'CalendarCards',
    StandardEdit = 'StandardEdit',
    TerminationEdit = 'TerminationEdit'
}


@Component({
    selector: 'calendar-v2',
    templateUrl: './calendar-v2.component.html',
    styleUrls: ['./calendar-v2.component.scss'],
})
export class CalendarV2Component implements OnInit, SlidePanelContent {

    public calendarComponent: any;

    public selectedPanel: SelectedPanelVisbilityEnum = SelectedPanelVisbilityEnum.CalendarCards;

    public selectedCalendarEvent: CalendarEvent | undefined;

    public secondReminder = false;

    public isCustomEventValid = false;

    public eventModel: any;

    public notifications: any;

    public loading = true;

    public notificationTargetGroups: AutocompleteGroupModel[] = [];

    public notificationTargets: AutocompleteChipGroupModel[] = [];

    public selectedNotificationTargets: AutocompleteChipGroupModel[] = [];

    public refreshSelectedNotificationTargets: Subject<AutocompleteChipGroupModel[]> = new Subject();

    public clientId: string = '';

    public matterId: string = '';

    public documentId: string = '';

    public clauses: any;

    public partyA: string = '';

    public partyB: string = '';

    public calendarEventsCount: number = 0;

    public calendarEventsSorted: any;

    public calendarEvents: any;

    public titleText: string = '';

    public loadClauses: boolean = false;

    public disableEvergreen: boolean = true;

    public timeZoneId: string;

    private host: SlidePanelComponent;

    constructor(
        public eventService: EventService,
        private calendarV2Service: CalendarV2Service,
    ) { }

    public async ngOnInit() {

        if (this.loadClauses === true) {

            this.clauses = await this.calendarV2Service.getClausesForDate(this.documentId);

        }

        if (!this.clauses) {

            this.partyA = this.selectedCalendarEvent?.parties[0] || 'Party 1';
            this.partyB = this.selectedCalendarEvent?.parties[1] || 'Party 2';
            this.documentId = this.selectedCalendarEvent?.documentId || '';

            this.clauses = await this.calendarV2Service.getClausesForDate(this.documentId);

            this.createEditEventModel(this.selectedCalendarEvent);

        } else {

            const defaultDate = new Date();
            defaultDate.setDate(defaultDate.getDate() + 1);

            this.eventModel = {
                calendarEventType: this.selectedPanel === SelectedPanelVisbilityEnum.StandardEdit ||
                    this.selectedPanel === SelectedPanelVisbilityEnum.StandardEvent ? CalendarEventType.Standard : CalendarEventType.Termination,
                eventDate: defaultDate,
                firstReminder: defaultDate,
                secondReminder: defaultDate,
                description: '',
                clauseId: '0',
                includeInSummaryEmails: true,
                notifications: [],
                noticePeriod: '0',
                customReminder: false,
                isEvergreen: false
            };

            await this.load();

        }

        // Sort clauses alphabetically (default order is the order the clauses appear in the contract)
        this.clauses.sort((a, b) => a.ruleName.localeCompare(b.ruleName));

        const defaultOption = {
            clauseId: 0,
            ruleName: 'None'
        }

        this.clauses.unshift(defaultOption);

        this.getTitle();

        this.timeZoneId = this.calendarV2Service.getUser().timeZoneId;
        this.validateCustomReminderNotifications();

    }

    public init(parent: SlidePanelComponent) {

        this.host = parent;

    }

    private async load(): Promise<void> {

        this.loading = true;

        if (this.selectedPanel === SelectedPanelVisbilityEnum.CalendarCards) {

            await this.getCalendarCards();

        }

        this.disableEvergreen = this.isEventDateInPast();

        this.loading = false;

    }

    public updatedNotificationTargets(value: NotificationItem[]) {

        this.eventModel.notifications = value;

        this.validateCustomReminderNotifications();

    }

    public async getCalendarCards() {

        this.calendarEvents = await this.calendarV2Service.getCalendarEventsForDocumentIdAsync(this.documentId);

        this.calendarEventsCount = this.calendarEvents.length;

        this.calendarEventsSorted = await this.calendarV2Service.loadCalendarCards(this.calendarEvents);

    }

    public async onCardEvent($event: any) {

        this.selectedCalendarEvent = this.calendarEvents.find(x => x.id === $event?.date.id || x.id === $event?.id);

        switch ($event.name) {
            case 'edit':
                if ($event.date.eventType.id === CardType.Standard) {

                    this.selectedPanel = SelectedPanelVisbilityEnum.StandardEdit;

                    this.createEditEventModel($event.date);

                    this.getTitle();

                } else {

                    this.selectedPanel = SelectedPanelVisbilityEnum.TerminationEdit;

                    this.createEditEventModel($event.date);

                    this.getTitle();

                }
                break;
            case 'download':
                await this.downloadEvent($event.date.id);
                break;
            case 'delete':
                await this.deleteCalendarEvent($event.date.id);
                break;
        }

    }

    public async createEditEventModel(event: any) {

        this.eventModel = {
            calendarEventType: event.eventType.id,
            eventDate: new Date(event.eventDate),
            firstReminder: new Date(),
            secondReminder: new Date(),
            description: event.description,
            clauseId: event?.clause?.id || '0',
            includeInSummaryEmails: event.includeInSummaryEmails,
            notifications: [],
            noticePeriod: event.noticePeriod.toString(),
            eventId: event.id,
            reminderCount: 0,
            customReminder: false,
            isEvergreen: event.isEvergreen
        };

        if (event.notifications.length > 0) {

            this.eventModel.customReminder = true;

            event.notifications.forEach(notification => {

                const notificationEntry = {
                    id: notification.id,
                    notificationEntityId: notification.notificationEntityId,
                    notificationEntityType: notification.notificationEntityType
                }

                this.eventModel.notifications.push(notificationEntry);

            });


        }

        if (event.reminderDates.length) {

            this.eventModel.firstReminder = new Date(event.reminderDates[0]);

            if (event.reminderDates.length === 2) {

                this.eventModel.secondReminder = new Date(event.reminderDates[1]);

                this.secondReminder = true;

            };

        }

        await this.load();

    }

    public async deleteCalendarEvent(id: string) {

        try {

            await this.calendarV2Service.deleteCalendarEvent(id);

            this.eventService.despatch(ShowToastMessage, {
                text: `Calendar event deleted.`,
            });

            await this.load();

        } catch (err) {

            this.eventService.despatch(ShowToastMessage, {
                text: `Error deleting calendar event.`,
                type: 'error',
                icon: 'alert-circle'
            });
        }

    }

    public async saveEvent(): Promise<void> {

        try {

            await this.calendarV2Service.createCalendarEvent(this.eventModel, this.clientId, this.matterId, this.documentId, this.secondReminder);

            this.eventService.despatch(ShowToastMessage, {
                text: `Calendar event created.`,
            });

            this.eventService.despatch('ReloadContractMeta');

        } catch (err) {

            this.eventService.despatch(ShowToastMessage, {
                text: `Error creating calendar event.`,
                type: 'error',
                icon: 'alert-circle'
            });
        }

        this.host.destroy();

    }

    public async updateEvent(): Promise<void> {

        try {

            await this.calendarV2Service.updateCalendarEvent(this.eventModel, this.secondReminder);

            this.eventService.despatch(ShowToastMessage, {
                text: `Calendar event updated.`,
            });

        } catch (err) {

            this.eventService.despatch(ShowToastMessage, {
                text: `Error updating calendar event.`,
                type: 'error',
                icon: 'alert-circle'
            });
        }

        this.host.destroy();

    }

    public async cancel(): Promise<void> {

        this.host.destroy();

    }

    public async downloadEvent(id: string): Promise<void> {

        const data: any = await this.calendarV2Service.downloadEvent(id);

        const downloadURL = window.URL.createObjectURL(data);

        const link = document.createElement('a');

        link.href = downloadURL;

        link.download = `${id}.ics`;

        link.click();

    }

    public toggleSecondReminder() {

        this.secondReminder = !this.secondReminder;

    }

    public toggleCustomReminder() {

        this.validateCustomReminderNotifications();

    }

    public eventDateUpdated() {

        if (this.eventModel.eventDate < this.eventModel.firstReminder) {

            this.eventModel.firstReminder = this.eventModel.eventDate;

        }

        if (this.eventModel.eventDate < this.eventModel.secondReminder) {

            this.eventModel.secondReminder = this.eventModel.eventDate;

        }

        this.disableEvergreen = this.isEventDateInPast();

        if (this.eventModel.isEvergreen && this.disableEvergreen) {

            this.eventModel.isEvergreen = false;

        }

    }

    private getTitle() {

        switch (this.selectedPanel) {
            case SelectedPanelVisbilityEnum.StandardEvent:
                this.titleText = 'Add an event';
                break;
            case SelectedPanelVisbilityEnum.StandardEdit:
                this.titleText = 'Edit event';
                break;
            case SelectedPanelVisbilityEnum.TerminationEvent:
                this.titleText = 'Add a termination event';
                break;
            case SelectedPanelVisbilityEnum.TerminationEdit:
                this.titleText = 'Edit termination event';
                break;
        }

    }

    private isEventDateInPast(): boolean {

        return this.eventModel.eventDate < new Date();

    }

    private validateCustomReminderNotifications(): void {
    
        if (this.eventModel.notifications.length > 0 || !this.eventModel.customReminder) {

            this.isCustomEventValid = true;

        } else {

            this.isCustomEventValid = false;

        }
    
    }

}
