import { NgModuleRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { SummizeStorage } from '@summize/shared/framework'
import { IsValidString } from '@summize/shared/core';
import { PlatformService } from '@summize/shared/components-v2';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { OutlookBridge } from './app/bridges/outlook-bridge';
import { GmailBridge } from './app/bridges/gmail-bridge';

if (environment.production) {

  enableProdMode();

}

const queryString = window.location.search;

const params = new URLSearchParams(queryString);

const host = params.get('host');

const isOutlook = IsValidString(host) && host === SummizeStorage.OutlookHostname;

const isGmail = IsValidString(host) && host === SummizeStorage.GmailHostName;

const isSalesforce = IsValidString(host) && host === SummizeStorage.SalesforceHost;

const isJira = IsValidString(host) && host === SummizeStorage.JiraHost;

const isHubspot = IsValidString(host) && host === SummizeStorage.HubspotHost;

const isLegalFrontdoor
  = window.location.pathname.includes('remote') === true;

if (isLegalFrontdoor === true) {

  SummizeStorage.isolate();

}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
  .then((app: NgModuleRef<AppModule>) => {

    if (isLegalFrontdoor === true) {

      SummizeStorage.setHost(host);

      document.body.classList.add(host);

      const onComplete = () => {

        const platform = app.injector.get(PlatformService);

        if (platform !== undefined) {

          platform.setupPlatform();

        }

      };

      if (isOutlook === true) {

        new OutlookBridge(app, onComplete);

      }

      if (isGmail === true) {

        new GmailBridge(app, onComplete);

      }

      if (isSalesforce === true || isJira === true || isHubspot === true) {

        onComplete();

      }

    }

  });