import { createReducer, on } from '@ngrx/store';
import { AccountState, initialAccountState } from '../state/account.state';
import { setDocumentLimit, setDocumentsUsed, setIsTrial, setHasExpired } from '../actions/account.actions';

export const accountReducers = createReducer(
  initialAccountState,
  on(setDocumentLimit, (state: AccountState, { limit }) => ({ ...state, documentLimit: limit })),
  on(setDocumentsUsed, (state: AccountState, { documentsUsed }) => ({ ...state, documentsUsed })),
  on(setIsTrial, (state: AccountState, { isTrial }) => ({ ...state, isTrial })),
  on(setHasExpired, (state: AccountState, { hasExpired }) => ({ ...state, hasExpired }))
);
