import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  @Input('icon') icon: string;
  @Input('label') label: string;
  @Input('small') small: boolean;
  @Input('big') big: boolean;
  @Input('circle') circle: boolean;
  @Input('square') square: boolean;
  @Input('disabled') disabled: boolean;
  @Input('blue') blue: boolean;
  @Input('primary') primary: boolean;
  @Input('iconSide') iconSide: IconSide = IconSide.Left;
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();

  IconSide = IconSide;

  constructor() { }

  ngOnInit() {
    if (!this.icon) {
      throw new Error('Icon is required');
    }

    this.small = this.small !== undefined && this.small !== false;
    this.big = this.big !== undefined && this.big !== false;
    this.circle = this.circle !== undefined && this.circle !== false;
    this.square = this.square !== undefined && this.square !== false;
    this.disabled = this.disabled !== undefined && this.disabled !== false;
    this.blue = this.blue !== undefined && this.blue !== false;
    this.primary = this.primary !== undefined && this.primary !== false;
  }

  onClick(e: MouseEvent) {
    e.stopPropagation();
    if (this.disabled) { return; }
    this.click.emit(e);
  }
}

export enum IconSide {
  Left, Right
}
