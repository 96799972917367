import { ExportItem, ExportClause, ExportFormat, ExportType } from '../../core';

export interface ExportBuilderState {
  exportItems: ExportItem[];
  exportClauses: ExportClause[];
  exportButtonEnabled: boolean;
  activeExportMode: ExportFormat;
  exportType: ExportType;
  exportDocumentIds: string[];
  excelSingleRow: boolean;
}

export const initialExportBuilderState: ExportBuilderState = {
  exportItems: [],
  exportClauses: [],
  exportButtonEnabled: true,
  activeExportMode: ExportFormat.Word,
  exportType: ExportType.Unknown,
  exportDocumentIds: [],
  excelSingleRow: false,
};
