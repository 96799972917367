<div class="datePicker">
    <div class="datePicker__title">
        <div class="datePicker__day">
            <span>M</span>
        </div>
        <div class="datePicker__day">
            <span>T</span>
        </div>
        <div class="datePicker__day">
            <span>W</span>
        </div>
        <div class="datePicker__day">
            <span>T</span>
        </div>
        <div class="datePicker__day">
            <span>F</span>
        </div>
        <div class="datePicker__day">
            <span>S</span>
        </div>
        <div class="datePicker__day">
            <span>S</span>
        </div>
    </div>
    <div class="datePicker__days">
        <div *ngFor="let day of days" class="datePicker__day" [class.datePicker__day--valid]="!!day.date" [class.datePicker__day--selected]="day.isSelected">
            <span>{{day.dateOfMonth || ''}}</span>
            <div *ngIf="day.hasEvent" class="datePicker__dot" [class.datePicker__dot--selected]="day.isSelected"></div>
        </div>
    </div>
</div>