import { createSelector } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { AccountState } from '../state/account.state';

const account = (state: AppState) => state.account;

export const selectDocumentLimit = createSelector(account, (state: AccountState) => state.documentLimit);
export const selectDocumentsUsed = createSelector(account, (state: AccountState) => state.documentsUsed);
export const selectIsTrial = createSelector(account, (state: AccountState) => state.isTrial);
export const selectHasExpired = createSelector(account, (state: AccountState) => state.hasExpired);
