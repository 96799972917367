import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import { TriggerToast } from '../../store/actions/common.actions';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private store: Store<AppState>) {
  }

  show(message: string, icon: string) {
    this.store.dispatch(new TriggerToast(message, icon));
  }
}
